import { Edit, maxLength, SimpleForm, TextInput } from 'react-admin';
import { validator } from 'utils/validator';

const StudyPlansTaskEdit = () => {
  return (
    <Edit title="Edit task" redirect="show">
      <SimpleForm>
        <TextInput
          validate={[validator.textRequired(), maxLength(500), validator.text]}
          source="name"
          fullWidth
          multiline
        />
      </SimpleForm>
    </Edit>
  );
};

export default StudyPlansTaskEdit;
