import { useAppNotify, useOnFetchError } from './index';
import api from 'api';
import { ErrorResponse } from 'types';

const useUploadQuestions = (courseId: string) => {
  const { onError } = useOnFetchError();
  const { successNotify } = useAppNotify();

  const createTest = async (file: File) => {
    try {
      const { data } =
        await api.AdminPanelCourses.adminCoursesControllerUploadCsvWithQuestions(
          courseId || '',
          file,
        );

      successNotify(data?.message || 'Success!');
    } catch (error) {
      onError(error as ErrorResponse);
    }
  };

  return {
    createTest,
  };
};

export default useUploadQuestions;
