import { CouponResponseDtoSubscriptionDurationTypeEnum } from 'api/generated';
import {
  AutocompleteArrayInput,
  DateInput,
  Edit,
  FormDataConsumer,
  NumberInput,
  RadioButtonGroupInput,
  RecordContext,
  ReferenceArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  maxLength,
  minLength,
  minValue,
  required,
} from 'react-admin';
import { validator } from 'utils/validator';
import { Resources } from 'types';
import { Grid } from '@mui/material';
import {
  allowedPeriodsOptions,
  allowedSubscriptionPlansOptions,
  discountTypeOptions,
  limitUsesOptions,
  expireDateOptions,
  subscriptionDurationTypeOptions,
} from '../CouponsCreate/constants';
import { useContext } from 'react';
import { NestedResourcesContext } from 'context/NestedResources';
import { setStringArray } from 'utils/setStringArray';
import { FieldValues } from 'react-hook-form';

const CouponsEdit = (props: any) => {
  const { urlVariables } = useContext(NestedResourcesContext);
  const { career: directionId } = urlVariables || {};

  const transform = (data: FieldValues) => {
    const transformedData = (value: string | string[]) => {
      return typeof value === 'object' ? value : [value];
    };

    return {
      name: data.name,
      allowedSubscriptionPlans: transformedData(data.allowedSubscriptionPlans),
      allowedPeriods: transformedData(data.allowedPeriods),
      allowedCoursesIds: data.allowedCourses,
    };
  };

  const getParsedData = (value: any) => {
    return {
      ...value,
      allowedCourses: value.allowedCourses.map((item: any) => item.id),
    };
  };

  return (
    <Edit
      {...props}
      redirect="show"
      transform={transform}
      queryOptions={{
        meta: {
          join: ['allowedCourses'],
        },
      }}
    >
      <RecordContext.Consumer>
        {(value) => {
          const parsedValue = getParsedData(value);

          return (
            <SimpleForm record={parsedValue}>
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  const hasLimit = Boolean(formData.maxRedemptions);
                  const hasMaxRedemptions =
                    formData.maxRedemptionsOptions === 'limit';
                  const hasDate = Boolean(formData.redeemBy);

                  return (
                    <>
                      <TextInput
                        validate={[
                          maxLength(150),
                          minLength(2),
                          validator.textRequired(),
                        ]}
                        source="name"
                        fullWidth
                        multiline
                        isRequired
                        {...rest}
                      />
                      <TextInput
                        validate={[
                          maxLength(150),
                          minLength(2),
                          validator.textRequired(),
                          validator.capitalText,
                        ]}
                        source="promoCode"
                        fullWidth
                        disabled
                      />
                      <ReferenceArrayInput
                        source="allowedCourses"
                        reference={Resources.COURSES}
                        filter={{ directionId }}
                      >
                        <AutocompleteArrayInput
                          label="Select course"
                          optionText="name"
                          optionValue="id"
                          format={setStringArray}
                          filterToQuery={(value: string) => ({ name: value })}
                          filterSelectedOptions={false}
                          validate={required()}
                          setFilter={(value: string) => value}
                          fullWidth
                          {...rest}
                        />
                      </ReferenceArrayInput>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} lg={3}>
                          <SelectInput
                            source="allowedSubscriptionPlans"
                            choices={allowedSubscriptionPlansOptions}
                            validate={required()}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                          <SelectInput
                            source="allowedPeriods"
                            choices={allowedPeriodsOptions}
                            validate={required()}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} lg={3}>
                          <SelectInput
                            source="discountType"
                            choices={discountTypeOptions}
                            validate={required()}
                            fullWidth
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                          <NumberInput
                            source="discountAmount"
                            isRequired
                            fullWidth
                            disabled
                          />
                        </Grid>
                      </Grid>
                      <RadioButtonGroupInput
                        label="Limit uses"
                        source="maxRedemptionsOptions"
                        choices={limitUsesOptions}
                        defaultValue={
                          hasLimit
                            ? limitUsesOptions[1].id
                            : limitUsesOptions[0].id
                        }
                        {...rest}
                        disabled
                      />
                      {hasMaxRedemptions && (
                        <NumberInput
                          source="maxRedemptions"
                          {...rest}
                          disabled
                          label="Limit to"
                        />
                      )}
                      <RadioButtonGroupInput
                        source="expireDateOptions"
                        choices={expireDateOptions}
                        defaultValue={
                          hasDate
                            ? expireDateOptions[3].id
                            : expireDateOptions[0].id
                        }
                        {...rest}
                        disabled
                      />
                      {hasDate && (
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                          <Grid item xs={12} lg={3}>
                            <DateInput
                              source="redeemBy"
                              validate={minValue(new Date())}
                              label="To"
                              fullWidth
                              {...rest}
                              disabled
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} lg={3}>
                          <SelectInput
                            source="subscriptionDurationType"
                            choices={subscriptionDurationTypeOptions}
                            fullWidth
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                          <FormDataConsumer>
                            {({ formData, ...rest }) =>
                              formData.subscriptionDurationType ===
                                CouponResponseDtoSubscriptionDurationTypeEnum.Repeating && (
                                <NumberInput
                                  source="subscriptionDuration"
                                  {...rest}
                                  disabled
                                />
                              )
                            }
                          </FormDataConsumer>
                        </Grid>
                      </Grid>
                    </>
                  );
                }}
              </FormDataConsumer>
            </SimpleForm>
          );
        }}
      </RecordContext.Consumer>
    </Edit>
  );
};

export default CouponsEdit;
