import { NestedResourcesContext } from 'context/NestedResources';
import { useContext } from 'react';
import {
  Datagrid,
  DateField,
  List,
  ListProps,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin';

const Filters = [<TextInput key="name" source="name" alwaysOn />];

const StudyPlansTasksList = (props: ListProps) => {
  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};

  return (
    <List
      disableSyncWithLocation
      filter={{
        courseId,
      }}
      filters={Filters}
      title="Study plans tasks"
      bulkActionButtons={false}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
      {...props}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="name" />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default StudyPlansTasksList;
