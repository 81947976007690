import EditorJS, {
  API,
  EditorConfig,
  OutputData,
  ToolConstructable,
  ToolSettings,
} from '@editorjs/editorjs';
import { AdminSignedUrlDto } from 'api/generated';

import { FC, MutableRefObject, useEffect, useState } from 'react';
import { FileCrudService } from 'services';
import styled, { css } from 'styled-components';
import { FontSizeTool } from './tools/fontSize';
import List from './tools/listPlugin';
import { getLastChildOfTree } from 'utils/getLastChildOfTree';
import { useAppNotify } from 'hooks';

const AlignmentTuneTool = require('editorjs-text-alignment-blocktune');
const Paragraph = require('@editorjs/paragraph');
const ColorPlugin = require('editorjs-text-color-plugin');
const Underline = require('@editorjs/underline');
const ImageTool = require('@editorjs/image');
const FontFamily = require('editorjs-inline-font-family-tool');

export interface EditorProps {
  defaultData?: OutputData;
  onSave?: (values: OutputData) => void | Promise<void>;
  uploadImage: (body: {
    filename: string;
  }) => Promise<{ data: AdminSignedUrlDto }>;
  isLoading?: boolean;
  editorTools?: EditorConfig['tools'];
  readOnly?: boolean;
  className?: string;
  label?: string;
  holder: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editorRef: MutableRefObject<any>;
  isRtl?: boolean;
}

const fontFamilyList = [
  'Arial',
  'Arial Black',
  'Arial Narrow',
  'Arial Rounded MT Bold',
  'Avant Garde',
  'Baskerville',
  'Bodoni MT',
  'Book Antiqua',
  'Big Caslon',
  'Calibri',
  'Calisto MT',
  'Cambria',
  'Candara',
  'Century Gothic',
  'Charcoal',
  'Copperplate',
  'Comic Sans MS',
  'Courier New',
  'Didot',
  'Franklin Gothic Medium',
  'Futura',
  'Geneva',
  'Gill Sans',
  'Garamond',
  'Georgia',
  'Goudy Old Style',
  'Hoefler Text',
  'Helvetica',
  'Helvetica Neue',
  'Impact',
  'Lucida Sans Unicode',
  'Lato',
  'Lucida Grande',
  'Lucida Bright',
  'Merriweather',
  'Monaco',
  'Open Sans',
  'Optima',
  'Papyrus',
  'PT Mono',
  'Palatino',
  'Perpetua',
  'Rockwell',
  'Roboto',
  'Rockwell Extra Bold',
  'Segoe UI',
  'Tahoma',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana',
];

const Editor: FC<EditorProps> = ({
  onSave,
  uploadImage,
  defaultData,
  editorTools = {},
  readOnly = false,
  className,
  editorRef,
  holder,
  label,
}) => {
  const { errorNotify } = useAppNotify();
  const [editorData, setEditorData] = useState<OutputData | undefined>(
    defaultData,
  );

  const setFontSize = () => {
    //setting font-size for number of list element
    if (editorRef.current) {
      const list = document.querySelectorAll<HTMLElement>('.cdx-list__item');
      list.forEach((li) => {
        const lastChildOfTree = getLastChildOfTree(li);
        li.style.fontSize = lastChildOfTree.style.fontSize;
      });
    }
  };

  useEffect(() => {
    initEditor();
    return destroyEditor;
  }, []);

  const onChange = async (api: API) => {
    const outputData = await api.saver.save();

    setEditorData(outputData);
    await onSave?.(outputData);
  };

  const uploadFile = async (file: File) => {
    if (file.name) {
      const response = await uploadImage({
        filename: file.name,
      });
      const { accessUrl, signedUrl } = response.data;

      await FileCrudService.put({ url: signedUrl, file });

      return accessUrl;
    }
  };

  const initEditor = () => {
    if (!editorRef.current) {
      const tools: {
        [toolName: string]: ToolConstructable | ToolSettings;
      } = {
        ...editorTools,
        paragraph: {
          class: Paragraph,
          tunes: ['anyTuneName'],
          config: {
            preserveBlank: true,
          },
        },
        underline: Underline,
        anyTuneName: {
          class: AlignmentTuneTool,
        },
        fontFamily: { class: FontFamily, config: { fontFamilyList } },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fontSize: FontSizeTool,
        Color: {
          class: ColorPlugin,
          config: {
            customPicker: true,
            type: 'text',
          },
        },
        list: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          class: List,
          inlineToolbar: true,
          tunes: ['anyTuneName'],
        },
        image: {
          class: ImageTool,
          inlineToolbar: true,
          config: {
            uploader: {
              async uploadByFile(file: File) {
                try {
                  const url = await uploadFile(file);
                  return {
                    success: 1,
                    file: {
                      url,
                    },
                  };
                } catch {
                  errorNotify(
                    'Something went wrong. Please check image format. Supported formats: .png, .jpeg, .jpg, .svg',
                  );
                }
              },
            },
          },
        },
      };

      const editor = new EditorJS({
        holder,
        data: editorData,
        autofocus: false,
        readOnly: readOnly,
        tools,
        onChange,
        onReady() {
          editorRef.current = editor;
          setFontSize();
        },
      });
    }
  };

  const destroyEditor = () => {
    editorRef.current?.destroy();
    editorRef.current = null;
  };

  return (
    <Root $hasContent={!!editorData?.blocks?.length}>
      <EditorContainer className={className} id={holder} />
      <Label $hasContent={!!editorData?.blocks?.length}>{label}</Label>
    </Root>
  );
};

export default Editor;

const EditorContentCSS = css`
  .codex-editor {
    display: none;
    z-index: 2;
  }

  .ce-block__content,
  .ce-toolbar__content {
    max-width: 1244px;
  }

  .cdx-block {
    word-break: break-word;
    padding: 0;
  }

  .cdx-list {
    padding: 5px 0 5px 40px;
  }

  .cdx-list__item {
    padding: 2px 0 2px 3px;
  }

  .codex-editor__loader {
    width: 100%;
    height: fit-content;

    display: flex;
    justify-content: center;
    padding: 19.5px 0;
  }

  > :first-child {
    display: block;
  }

  .codex-editor__redactor {
    padding-bottom: 0 !important;
  }

  .ce-inline-toolbar .ce-inline-tool svg {
    width: 24px;
    height: 24px;
  }

  span {
    line-height: normal;
  }

  .ce-block {
    *:not(font) {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 20px;
    }
  }

  .colorPlugin {
    padding: 0;

    xy-color-picker {
      width: 100%;
      height: 100%;

      position: inherit;
      margin: 0;
    }
  }
`;

const FocusedInputCSS = css`
  :focus-within {
    label {
      transform: translate(12px, 4px) scale(0.75);
      transition: none;

      color: ${(props) => props.theme.palette?.primary?.main};
    }
  }
`;

const Root = styled.div<{ $hasContent: boolean }>`
  width: 100%;
  height: fit-content;
  position: relative;

  ${FocusedInputCSS}
`;

const EditorContainer = styled.div`
  height: fit-content;

  padding: 21px 100px;

  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: rgba(0, 0, 0, 0.04);

  @media (max-width: 900px) {
    padding: 21px 50px;
  }

  ::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;

    transform: scaleX(0);
    border-bottom: 2px solid ${(props) => props.theme.palette?.primary?.main};
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
  }

  :focus-within {
    ::after {
      transform: scaleX(1);
    }
  }

  ${EditorContentCSS}
`;

const Label = styled.label<{ $hasContent: boolean }>`
  position: absolute;
  left: 0;
  top: 0;

  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;

  transform: translate(12px, 13px) scale(1);

  transform-origin: top left;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  ${({ $hasContent }) =>
    $hasContent &&
    css`
      transition: none;

      color: rgba(0, 0, 0, 0.87);
      transform: translate(12px, 4px) scale(0.75);
    `}
`;
