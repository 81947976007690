import { getLastChildOfTree } from 'utils/getLastChildOfTree';
import './index.css';

export class FontSizeTool {
  static title = 'Font Size';
  isDropDownOpen = false;
  togglingCallback = null;
  emptyString = '&nbsp;&nbsp';
  fontSizeDropDown = 'font-size-dropdown';

  static get sanitize() {
    return {
      font: {
        size: true,
        face: true,
      },
    };
  }
  static get isInline() {
    return true;
  }
  commandName = 'fontSize';

  CSS = {
    button: 'ce-inline-tool',
    buttonActive: 'ce-font-size-tool--active',
    buttonModifier: 'ce-inline-tool--font',
  };
  nodes = {
    button: undefined,
  };
  selectedFontSize = null;

  selectionList = undefined;

  buttonWrapperText = undefined;

  createSvg = undefined;

  make(tagName, classNames = null) {
    const el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }
    return el;
  }

  createButton() {
    this.nodes.button = this.make('button', [
      this.CSS.button,
      this.CSS.buttonModifier,
    ]);
    this.nodes.button.type = 'button';
    this.nodes.button.setAttribute('id', 'fontSizeBtn');
    this.getFontSizeForButton();
    this.createSvg = this.svg('toggler-down', 13, 13);
    this.nodes.button.appendChild(this.createSvg);
  }
  getFontSizeForButton() {
    this.buttonWrapperText = this.make('div', 'button-wrapper-text');
    const displaySelectedFontSize = this.make('div');
    displaySelectedFontSize.setAttribute('id', this.fontSizeDropDown);
    displaySelectedFontSize.innerHTML = this.emptyString;
    this.buttonWrapperText.append(displaySelectedFontSize);
    this.nodes.button.append(this.buttonWrapperText);
  }

  addFontSizeOptions() {
    const fontSizeList = [
      { label: '8', value: '8' },
      { label: '10', value: '10' },
      { label: '12', value: '12' },
      { label: '13', value: '13' },
      { label: '14', value: '14' },
      { label: '15', value: '15' },
      { label: '16', value: '16' },
      { label: '18', value: '18' },
      { label: '20', value: '20' },
      { label: '22', value: '22' },
      { label: '24', value: '24' },
      { label: '26', value: '26' },
      { label: '28', value: '28' },
      { label: '30', value: '30' },
      { label: '32', value: '32' },
      { label: '34', value: '34' },
      { label: '38', value: '38' },
      { label: '40', value: '40' },
      { label: '42', value: '42' },
      { label: '44', value: '44' },
      { label: '46', value: '46' },
      { label: '48', value: '48' },
      { label: '50', value: '50' },
      { label: '52', value: '52' },
    ];
    this.selectionList = this.make('div', 'selectionList');
    const selectionListWrapper = this.make('div', 'selection-list-wrapper');

    for (const fontSize of fontSizeList) {
      const option = this.make('div');
      option.setAttribute('value', fontSize.value);
      option.setAttribute('id', fontSize.value);
      option.classList.add('selection-list-option');
      if (
        document.getElementById(this.fontSizeDropDown).innerHTML ===
          fontSize.label ||
        this.selectedFontSize === fontSize.value
      ) {
        option.classList.add('selection-list-option-active');
      }
      option.innerHTML = fontSize.label;
      selectionListWrapper.append(option);
    }
    this.selectionList.append(selectionListWrapper);
    this.nodes.button.append(this.selectionList);
    this.selectionList.addEventListener('click', this.toggleFontSizeSelector);

    setTimeout(() => {
      if (typeof this.togglingCallback === 'function') {
        this.togglingCallback(true);
      }
    }, 50);
  }

  toggleFontSizeSelector = (event) => {
    this.selectedFontSize = event.target.id;
    this.toggle();
  };

  removeFontSizeOptions() {
    if (this.selectionList) {
      this.isDropDownOpen = false;
      this.selectionList = this.selectionList.remove();
    }
    if (typeof this.togglingCallback === 'function') {
      this.togglingCallback(false);
    }
  }

  render() {
    this.createButton();
    this.nodes.button.addEventListener('click', this.toggleDropDown);
    return this.nodes.button;
  }

  toggleDropDown = ($event) => {
    if (
      $event.target.id === this.fontSizeDropDown ||
      $event.target.parentNode.id === 'fontSizeBtn' ||
      $event.target.id === 'fontSizeBtn'
    ) {
      this.toggle((toolbarOpened) => {
        if (toolbarOpened) {
          this.isDropDownOpen = true;
        }
      });
    }
  };

  toggle(togglingCallback) {
    if (!this.isDropDownOpen && togglingCallback) {
      this.addFontSizeOptions();
    } else {
      this.removeFontSizeOptions();
    }
    if (typeof togglingCallback === 'function') {
      this.togglingCallback = togglingCallback;
    }
  }

  removeFontSize(node) {
    if (node?.childNodes?.length) {
      node.style.fontSize = 'inherit';

      for (let i = 0; i < node.childNodes?.length; i++) {
        this.removeFontSize(node.childNodes?.item(i));
      }
    } else if (node?.style?.fontSize) {
      node.style.fontSize = 'inherit';
    }
  }

  surround(_range) {
    if (this.selectedFontSize) {
      document.execCommand('fontSize', false, this.selectedFontSize);

      const selection = window?.getSelection?.()?.getRangeAt?.(0);
      const startNode = selection.startContainer.parentElement.closest('li');
      const endNode = selection.endContainer.parentElement.closest('li');
      let currentNode = startNode;

      if (currentNode?.tagName === 'LI') {
        const selectedElements = [];
        do {
          selectedElements.push(currentNode);
          currentNode = currentNode.nextSibling;
        } while (currentNode !== endNode.nextSibling);

        selectedElements.forEach((li) => {
          li.setAttribute('style', `font-size: ${this.selectedFontSize}px`);

          const content = getLastChildOfTree(li);
          content.removeAttribute('size');
          content.style.fontSize = `${this.selectedFontSize}px`;
        });
      } else {
        const selectedContent = selection?.extractContents();
        if (selection && selectedContent) {
          const font = document.createElement('font');
          font.style.fontSize = `${this.selectedFontSize}px`;
          font.appendChild(selectedContent);

          selection.insertNode(font);
          for (let i = 0; i < font.childNodes?.length; i++) {
            this.removeFontSize(font.childNodes.item(i));
          }
        }
      }
    }
  }

  getComputedFontStyle(node) {
    return window
      .getComputedStyle(node.parentElement, null)
      .getPropertyValue('font-size');
  }

  checkState(selection) {
    const isActive = document.queryCommandState('fontSize');
    let anchoredElementFontSize = this.getComputedFontStyle(
      selection.anchorNode,
    );
    const focusedElementFontSize = this.getComputedFontStyle(
      selection.focusNode,
    );
    if (anchoredElementFontSize === focusedElementFontSize) {
      anchoredElementFontSize = anchoredElementFontSize.slice(
        0,
        anchoredElementFontSize.indexOf('p'),
      );
      const elementContainsDecimalValue = anchoredElementFontSize.indexOf('.');
      if (elementContainsDecimalValue !== -1) {
        anchoredElementFontSize = anchoredElementFontSize.slice(
          0,
          anchoredElementFontSize.indexOf('.'),
        );
      }
      this.replaceFontSizeInWrapper(anchoredElementFontSize);
    } else {
      const emptyWrapper = this.emptyString;
      this.replaceFontSizeInWrapper(emptyWrapper);
    }
    return isActive;
  }

  replaceFontSizeInWrapper(size) {
    const displaySelectedFontSize = document.getElementById(
      this.fontSizeDropDown,
    );
    displaySelectedFontSize.innerHTML = `${size}px`;
  }

  clear() {
    this.toggle();
    this.selectedFontSize = null;
  }

  svg(name, width = 14, height = 14) {
    const icon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

    icon.classList.add('icon', 'icon--' + name);
    icon.setAttribute('width', width + 'px');
    icon.setAttribute('height', height + 'px');
    icon.innerHTML = `<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#${name}"></use>`;

    return icon;
  }
}
