import { NestedResourcesContext } from 'context/NestedResources';
import { useOnFetchError } from 'hooks';
import React, { useContext } from 'react';
import {
  Create,
  maxLength,
  NumberInput,
  SimpleForm,
  TextInput,
  useCreate,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Resources } from 'types';
import { validator } from 'utils/validator';

const TestTopicsCreate = () => {
  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};

  const navigate = useNavigate();
  const { onError } = useOnFetchError();
  const [create] = useCreate(Resources.TEST_TOPICS, undefined, {
    onError,
    onSuccess() {
      navigate(`/${Resources.TEST_TOPICS}`);
    },
  });

  const createTopic = async (data: FieldValues) => {
    await create(Resources.TEST_TOPICS, {
      data: { ...data, courseId },
    });
  };

  return (
    <Create title="Create domain">
      <SimpleForm onSubmit={createTopic}>
        <TextInput
          source="title"
          validate={[validator.textRequired(), maxLength(500), validator.text]}
          fullWidth
          multiline
        />
        <NumberInput
          source="sortIndex"
          validate={validator.sortIndex()}
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};

export default TestTopicsCreate;
