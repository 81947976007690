import { ImportButton } from 'components';
import { ImportButtonProps } from 'components/ImportButton/ImportButton';
import React, { FC } from 'react';
import {
  CreateButton,
  ExportButton,
  FilterButton,
  TopToolbar,
} from 'react-admin';

type ListActionsWithImportProps = {
  hasFilterButton?: boolean;
  createResource?: string;
} & ImportButtonProps;

const ListActionsWithImport: FC<ListActionsWithImportProps> = ({
  hasFilterButton = true,
  createResource,
  ...props
}) => {
  return (
    <TopToolbar>
      {hasFilterButton && <FilterButton />}
      <CreateButton resource={createResource} />
      <ExportButton />
      <ImportButton {...props} />
    </TopToolbar>
  );
};

export default ListActionsWithImport;
