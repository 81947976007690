import {
  ShowButton,
  TextField,
  TextFieldProps,
  useRecordContext,
} from 'react-admin';
import styled from 'styled-components';

type ResourceFieldsProps = {
  showButtonLabel: string;
} & TextFieldProps;

const ResourceFields = ({
  source,
  resource,
  showButtonLabel,
  ...props
}: ResourceFieldsProps) => {
  const report = useRecordContext();

  if (source && report[source]) {
    return (
      <Container>
        <Wrapper>
          <Label className="MuiTypography-root MuiTypography-body1 RaLabeled-label css-1pnmrwp-MuiTypography-root">
            {source}
          </Label>
          <TextField {...props} source={source} />
        </Wrapper>

        <ShowButton
          resource={`/${resource}`}
          label={showButtonLabel}
          record={{ id: report[source] }}
        />
      </Container>
    );
  } else {
    return null;
  }
};

export default ResourceFields;

const Label = styled.p`
  font-size: 12px !important;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  gap: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
`;
