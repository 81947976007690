import { Show, TextField, SimpleShowLayout, DateField } from 'react-admin';
import { InviteActions } from './components/InviteActions';

const StudyPlansTaskShow = () => {
  return (
    <Show title="Invite" actions={<InviteActions />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="courseId" />
        <TextField source="email" />
        <DateField source="acceptedAt" />
      </SimpleShowLayout>
    </Show>
  );
};

export default StudyPlansTaskShow;
