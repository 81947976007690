import { useOnFetchError } from 'hooks';
import {
  Create,
  ReferenceInput,
  AutocompleteInput,
  useCreate,
  SimpleForm,
  useRefresh,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Resources } from 'types';
import Modal, { ModalProps } from '@mui/material/Modal';
import { FC, useContext } from 'react';
import styled from 'styled-components';
import { NestedResourcesContext } from 'context/NestedResources';

const AddTaskToPhaseModal: FC<Omit<ModalProps, 'children'>> = ({
  onClose,
  ...props
}) => {
  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};

  const { id } = useParams();
  const { onError } = useOnFetchError();
  const refresh = useRefresh();

  const [create] = useCreate(Resources.STUDY_PLANS_PHASES, undefined, {
    onError,
    onSuccess() {
      refresh();
      onClose?.({}, 'backdropClick');
    },
  });

  const handleSubmit = async (values: FieldValues) => {
    await create(Resources.STUDY_PLANS_PHASES_TASKS, {
      data: { planPhaseId: id, ...values },
    });
  };

  return (
    <StyledModal onClose={onClose} {...props}>
      <Create>
        <SimpleForm onSubmit={handleSubmit}>
          <ReferenceInput
            label="Task"
            source="taskId"
            reference={Resources.STUDY_PLANS_TASKS}
            filter={{ courseId }}
          >
            <AutocompleteInput
              filterToQuery={(searchText) => ({ name: searchText })}
              optionText="name"
            />
          </ReferenceInput>
        </SimpleForm>
      </Create>
    </StyledModal>
  );
};

export default AddTaskToPhaseModal;

const StyledModal = styled(Modal)`
  .MuiPaper-root {
    width: 100%;
    max-width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .MuiAutocomplete-root {
    width: 100%;
  }
`;
