import DeleteIcon from '@mui/icons-material/Delete';
import api from 'api';
import { CustomButton, ToggleResolveReportButton } from 'components';
import { useOnFetchError } from 'hooks';
import {
  Datagrid,
  DateField,
  DateInput,
  List,
  ListProps,
  Pagination,
  TextField,
  TopToolbar,
  useRefresh,
} from 'react-admin';
import styled from 'styled-components';
import { CondOperator, ErrorResponse, Resources } from 'types';

const ResolvedReportsListActions = () => {
  const { onError } = useOnFetchError();
  const refresh = useRefresh();

  const deleteAllResolved = async () => {
    try {
      await api.AdminPanelReports.adminReportsControllerDeleteResolved();
      refresh();
    } catch (error) {
      onError(error as ErrorResponse);
    }
  };
  return (
    <TopToolbar>
      <StyledButton
        label="Delete all resolved report"
        icon={<DeleteIcon />}
        onClick={deleteAllResolved}
      />
    </TopToolbar>
  );
};

const Filters = [
  <DateInput key="createdAt" source="createdAt" alwaysOn />,
  <DateInput
    key="resolvedAt"
    onChange={(e) => e || CondOperator.NOT_NULL}
    source="resolvedAt"
    alwaysOn
  />,
];

const ResolvedReportsList = (props: ListProps) => {
  return (
    <List
      disableSyncWithLocation
      resource={Resources.REPORTS}
      filters={Filters}
      queryOptions={{
        meta: { filter: `resolvedAt||${CondOperator.NOT_NULL}` },
      }}
      bulkActionButtons={false}
      sort={{ field: 'resolvedAt', order: 'DESC' }}
      actions={<ResolvedReportsListActions />}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
      {...props}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <ToggleResolveReportButton />
        <TextField source="resource" />
        <TextField source="content" />
        <DateField source="createdAt" />
        <DateField source="resolvedAt" />
      </Datagrid>
    </List>
  );
};

export default ResolvedReportsList;

const StyledButton = styled(CustomButton)`
  color: red;

  &:hover {
    background-color: #fff5f7;
  }
`;
