import {
  Edit,
  NumberInput,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  required,
} from 'react-admin';
import Chapters from '../Chapters/Chapters';
import Domains from '../Domains/Domains';
import { validator } from 'utils/validator';

const TestsEdit = () => {
  return (
    <Edit mutationMode="pessimistic">
      <TabbedShowLayout>
        <Tab label="Change test">
          <SimpleForm>
            <NumberInput
              source="durationSec"
              validate={[required(), validator.notZero]}
            />
            <Domains />
            <Chapters />
          </SimpleForm>
        </Tab>
      </TabbedShowLayout>
    </Edit>
  );
};

export default TestsEdit;
