import { AppConfig } from 'config';
import { http } from 'services';
import {
  AdminPanelDirectionsApi,
  AdminPanelCoursesApi,
  AdminPanelMnemonicCardsApi,
  AdminPanelFlashcardsApi,
  AdminPanelReportsApi,
  AdminPanelGuidesApi,
  AdminPanelAuthApi,
  AdminPanelQuestionsApi,
  AdminPanelCustomersApi,
  AdminPanelTimedTestsApi,
  AdminPanelSuggestionsApi,
  AdminPanelQuestionDomainsApi,
  AdminPanelQuestionChaptersApi,
} from './generated/api';

export default {
  AdminPanelDirections: new AdminPanelDirectionsApi(
    undefined,
    AppConfig.BASE_API_URL,
    http.axios,
  ),
  AdminPanelMnemonicCards: new AdminPanelMnemonicCardsApi(
    undefined,
    AppConfig.BASE_API_URL,
    http.axios,
  ),
  AdminPanelGuides: new AdminPanelGuidesApi(
    undefined,
    AppConfig.BASE_API_URL,
    http.axios,
  ),
  AdminPanelCustomers: new AdminPanelCustomersApi(
    undefined,
    AppConfig.BASE_API_URL,
    http.axios,
  ),
  AdminPanelCourses: new AdminPanelCoursesApi(
    undefined,
    AppConfig.BASE_API_URL,
    http.axios,
  ),
  AdminPanelFlashcards: new AdminPanelFlashcardsApi(
    undefined,
    AppConfig.BASE_API_URL,
    http.axios,
  ),
  AdminPanelReports: new AdminPanelReportsApi(
    undefined,
    AppConfig.BASE_API_URL,
    http.axios,
  ),
  AdminPanelAuth: new AdminPanelAuthApi(
    undefined,
    AppConfig.BASE_API_URL,
    http.axios,
  ),
  AdminPanelQuestions: new AdminPanelQuestionsApi(
    undefined,
    AppConfig.BASE_API_URL,
    http.axios,
  ),
  AdminPanelTests: new AdminPanelTimedTestsApi(
    undefined,
    AppConfig.BASE_API_URL,
    http.axios,
  ),
  AdminPanelSuggestions: new AdminPanelSuggestionsApi(
    undefined,
    AppConfig.BASE_API_URL,
    http.axios,
  ),
  AdminPanelDomains: new AdminPanelQuestionDomainsApi(
    undefined,
    AppConfig.BASE_API_URL,
    http.axios,
  ),
  AdminPanelChapters: new AdminPanelQuestionChaptersApi(
    undefined,
    AppConfig.BASE_API_URL,
    http.axios,
  ),
};
