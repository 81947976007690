import api from 'api';
import {
  FlashcardsSortIndexButton,
  ListActionsWithImport,
  OpenButton,
} from 'components';
import { NestedResourcesContext } from 'context/NestedResources';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  Datagrid,
  List,
  ListProps,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin';
import { ErrorResponse, UrlVariablesEnum } from 'types';
import { useAppNotify, useOnFetchError } from 'hooks';
import styled from 'styled-components';
import { AdminFlashcardsPackDto } from 'api/generated';

const Filters = [<TextInput key="name" source="name" alwaysOn />];

const FlashcardsPacksList = (props: ListProps) => {
  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};
  const { onError } = useOnFetchError();
  const { successNotify, errorNotify } = useAppNotify();
  const [flashcardsPack, setFlashcardsPack] = useState<
    AdminFlashcardsPackDto[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAllPacks = async () => {
    setIsLoading(true);

    try {
      const { data } =
        await api.AdminPanelFlashcards.getManyBaseAdminFlashcardsPacksControllerFlashcardsPack(
          undefined,
          undefined,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          `courseId||$eq||${courseId}`,
          undefined,
          undefined,
          undefined,
          100,
        );

      setFlashcardsPack(data.data);
    } catch {
      errorNotify('Flashcards packs downloading error. Please try again');
    } finally {
      setIsLoading(false);
    }
  };

  const createFlashcards = useCallback(async (file: File) => {
    try {
      const { data } =
        await api.AdminPanelCourses.adminCoursesControllerUploadFlashcardsInCsv(
          courseId || '',
          file,
        );

      successNotify(`${data?.length} flashcards has successful imported!`);
    } catch (error) {
      onError(error as ErrorResponse);
    }
  }, []);

  useEffect(() => {
    if (courseId) {
      getAllPacks();
    }
  }, []);

  return (
    <StyledList
      disableSyncWithLocation
      sort={{ field: 'order.orderIndex', order: 'ASC' }}
      filters={Filters}
      bulkActionButtons={false}
      filter={{ courseId }}
      actions={<ListActionsWithImport uploadFile={createFlashcards} />}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
      {...props}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField
          source="order.orderIndex"
          label="Order index"
          sortable={false}
        />
        <FlashcardsSortIndexButton
          sortIndexVariant="decrease"
          flashcardsPack={flashcardsPack}
          getAllPacks={getAllPacks}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
        <FlashcardsSortIndexButton
          sortIndexVariant="increase"
          flashcardsPack={flashcardsPack}
          getAllPacks={getAllPacks}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
        <TextField source="name" />
        <OpenButton
          label="Open pack"
          variable={UrlVariablesEnum.FLASHCARDS_PACK}
        />
      </Datagrid>
    </StyledList>
  );
};

export default FlashcardsPacksList;

const StyledList = styled(List)`
  .MuiTableCell-head:nth-child(1) {
    width: 80px;
    white-space: nowrap;
  }

  .MuiTableCell-head:nth-child(2),
  .MuiTableCell-head:nth-child(3) {
    width: 40px;
  }
`;
