import {
  ArrayField,
  Datagrid,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import { ShowActions } from 'components';

const TestsShow = () => {
  return (
    <Show actions={<ShowActions />}>
      <TabbedShowLayout>
        <Tab label="About test">
          <TextField source="id" />
          <TextField source="durationSec" />
          <ArrayField source="domains">
            <Datagrid bulkActionButtons={false}>
              <TextField source="title" />
              <TextField source="amountOfQuestions" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="chapters">
            <Datagrid bulkActionButtons={false}>
              <TextField source="title" />
              <TextField source="amountOfQuestions" />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default TestsShow;
