import {
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_GET_PERMISSIONS,
  AuthActionType,
} from 'react-admin';
import { http, StorageService } from 'services';
import { StorageKeys } from 'services/storage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const authProvider = (type: AuthActionType, params: any): Promise<void> => {
  if (type === AUTH_LOGIN) {
    const { username: email, password } = params;

    return http
      .post('auth/admin/signIn', { email, password })
      .then(
        ({
          data: {
            token,
            admin: { role },
          },
        }) => {
          http.setAuthorizationHeader(token);
          StorageService.setItem(StorageKeys.ACCESS_TOKEN, token);
          StorageService.setItem(StorageKeys.ROLE, role);
        },
      )
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  if (type === AUTH_LOGOUT) {
    StorageService.resetItem(StorageKeys.ACCESS_TOKEN);
    StorageService.resetItem(StorageKeys.ROLE);

    return Promise.resolve();
  }

  if (type === AUTH_ERROR) {
    const status = params?.response?.status;
    const token = StorageService.getItem(StorageKeys.ACCESS_TOKEN);

    if (status === 401 || status === 403 || !token) {
      StorageService.resetItem(StorageKeys.ACCESS_TOKEN);
      StorageService.resetItem(StorageKeys.ROLE);
      return Promise.reject();
    }

    return Promise.resolve();
  }

  if (type === AUTH_CHECK || type === AUTH_GET_PERMISSIONS) {
    const token = StorageService.getItem(StorageKeys.ACCESS_TOKEN);

    if (token) {
      http.setAuthorizationHeader(token);
      return Promise.resolve();
    }
    return Promise.reject();
  }

  return Promise.reject(new Error('Unknown method'));
};

export default authProvider;
