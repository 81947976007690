import { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

type AccordionProps = {
  title: string;
  children: ReactNode;
  isOpen: boolean;
  onClick: () => void;
};

const Accordion: FC<AccordionProps> = ({
  children,
  title,
  isOpen,
  onClick,
}) => {
  return (
    <Root>
      <Text onClick={onClick}>{title}</Text>

      <Content $isOpen={isOpen}>{children}</Content>
    </Root>
  );
};

export default Accordion;

const Root = styled.div`
  width: fit-content;

  display: flex;
  align-items: center;
  padding: 0 16px;

  border-radius: 4px;
  outline: 1px solid rgba(224, 224, 224, 1);
  background-color: white;
`;

const Text = styled.div`
  width: fit-content;
  height: 100%;

  display: flex;
  align-items: center;

  cursor: pointer;

  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 0.8125rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette?.primary?.main};
`;

const Content = styled.div<{ $isOpen: boolean }>`
  ${({ $isOpen }) => css`
    width: ${$isOpen ? 'fit-content' : 0};
    transform: translateX(${$isOpen ? 0 : '-100%'}) scaleX(${$isOpen ? 1 : 0});
    padding-left: ${$isOpen ? 16 : 0}px;

    opacity: ${$isOpen ? 1 : 0};
    transition: all ${$isOpen ? '0.2s' : '0'} linear;
  `}
`;
