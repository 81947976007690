import { RaThemeOptions } from 'react-admin';
import { StorageKeys, StorageService } from 'services';
import { AdminTheme } from 'styles';

const useDirectionTheme = (isDirection: boolean) => {
  const directionMainColor = StorageService.getItem(
    StorageKeys.DIRECTION_MAIN_COLOR,
  );

  const currentTheme: RaThemeOptions = {
    ...AdminTheme,
    palette:
      directionMainColor && isDirection
        ? {
            ...AdminTheme.palette,
            primary: { main: directionMainColor },
            secondary: { main: directionMainColor },
          }
        : AdminTheme.palette,
  };

  return { currentTheme };
};

export default useDirectionTheme;
