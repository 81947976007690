import { Show, TextField, SimpleShowLayout, DateField } from 'react-admin';
import { SuggestionsActions } from 'components';
import styled from 'styled-components';
import { useDisplayWidth } from 'hooks';
import React from 'react';

const SuggestionsShow = () => {
  const displayWidth = useDisplayWidth();

  return (
    <Show actions={<SuggestionsActions />} title="Suggestion">
      <SimpleShowLayout>
        <TextField source="customer.name" />
        <Label>Suggestion</Label>
        <Wrapper $width={displayWidth}>
          <TextField source="content" />
        </Wrapper>
        <DateField source="createdAt" showTime={true} />
        <DateField source="resolvedAt" showTime={true} />
      </SimpleShowLayout>
    </Show>
  );
};

export default SuggestionsShow;

const Wrapper = styled.div<{ $width: number }>`
  ${({ $width }) => `
    max-width: ${$width / 1.25}px;
    overflow: scroll;
  `}
`;

const Label = styled.p`
  font-size: 0.75em !important;
  margin-bottom: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
`;
