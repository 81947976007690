import { Button } from 'react-admin';
import { ChangeEvent, FC, useState } from 'react';
import styled from 'styled-components';
import Modal, { ModalProps } from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';

type DirectionDeleteModalProps = {
  onConfirm: () => void;
  onRequestClose: () => void;
  isLoading: boolean;
} & Omit<ModalProps, 'children'>;

const DirectionDeleteModal: FC<DirectionDeleteModalProps> = (props) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const value = event?.target?.value;

    setInputValue(value?.toUpperCase());
  };

  return (
    <StyledModal
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      {...props}
    >
      <Container>
        <Typography
          component="h4"
          variant="h4"
          align="center"
          style={{ marginBottom: '24px' }}
        >{`Enter "DELETE" to confirm`}</Typography>

        <Input
          style={{ marginBottom: '24px' }}
          fullWidth
          value={inputValue}
          onChange={(event) => handleInputChange(event)}
        />

        <ButtonContainer>
          <StyledButton
            variant="contained"
            label="Confirm"
            onClick={props.onConfirm}
            disabled={props.isLoading || inputValue !== 'DELETE'}
          />
          <StyledButton
            variant="contained"
            label="Cancel"
            onClick={props.onRequestClose}
            disabled={props.isLoading}
          />
        </ButtonContainer>
      </Container>
    </StyledModal>
  );
};

export default DirectionDeleteModal;

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiToolbar-root {
    display: none;
  }
`;

const Container = styled.div`
  background-color: lightgray;
  width: 500px;
  max-width: 500px;
  padding: 40px 50px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;
