import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import api from 'api';
import { AdminRoleEnum } from 'api/generated';
import CustomButton from 'components/CustomButton';
import {
  AppointReporterModal,
  RemoveReporterModal,
  ConfirmModal,
} from 'components/Modals';
import { useAppNotify, useToggle } from 'hooks';
import { useEffect, useState } from 'react';
import { EditButton, TopToolbar, useRecordContext } from 'react-admin';
import { StorageKeys, StorageService } from 'services';
import ReleaseButton from '../ReleaseButton';

const CourseActions = () => {
  const [isReleased, setIsReleased] = useState(false);
  const [isReleasingLoading, setIsReleasingLoading] = useState(false);
  const [isDuplicateLoading, setDuplicateLoading] = useState(false);
  const { successNotify, errorNotify } = useAppNotify();
  const course = useRecordContext();
  const {
    close: closeAppointReporterModal,
    isOpen: isOpenAppointReporterModal,
    open: openAppointReporterModal,
  } = useToggle();

  const {
    close: closeRemoveReporterModal,
    isOpen: isOpenRemoveReporterModal,
    open: openRemoveReporterModal,
  } = useToggle();

  const role = StorageService.getItem(StorageKeys.ROLE);
  const isSuperAdmin = role === AdminRoleEnum.Superadmin;

  useEffect(() => {
    setIsReleased(Boolean(course?.releasedAt));
  }, [course]);

  const onReleasedClick = async () => {
    setIsReleasingLoading(true);

    try {
      await api.AdminPanelCourses.adminCoursesControllerReleaseCourse(
        course?.id as string,
      );

      setIsReleased(true);
    } catch (err) {
      errorNotify(err);
    } finally {
      setIsReleasingLoading(false);
      closeConfirmReleaseModal();
    }
  };

  const onUnreleasedClick = async () => {
    setIsReleasingLoading(true);

    try {
      await api.AdminPanelCourses.adminCoursesControllerUnreleaseCourse(
        course?.id as string,
      );

      setIsReleased(false);
    } catch (err) {
      errorNotify(err);
    } finally {
      setIsReleasingLoading(false);
      closeConfirmReleaseModal();
    }
  };

  const onCourseDuplicate = async () => {
    setDuplicateLoading(true);

    try {
      await api.AdminPanelCourses.adminCoursesControllerDuplicateCourse(
        (course?.id as string) || '',
      );

      successNotify('Duplicate success');
    } catch (err) {
      errorNotify(err);
    } finally {
      setDuplicateLoading(false);
      closeConfirmDuplicateModal();
    }
  };

  const {
    close: closeConfirmReleaseModal,
    isOpen: isOpenReleaseModal,
    open: openReleaseModal,
  } = useToggle();

  const {
    close: closeConfirmDuplicateModal,
    isOpen: isOpenDuplicateModal,
    open: openDuplicateModal,
  } = useToggle();

  return (
    <TopToolbar>
      <EditButton />
      {isSuperAdmin && (
        <ReleaseButton isReleased={isReleased} onClick={openReleaseModal} />
      )}
      <CustomButton
        label="Duplicacte"
        onClick={openDuplicateModal}
        disabled={isDuplicateLoading}
      />
      <CustomButton
        label="Appoint a reporter"
        onClick={openAppointReporterModal}
        icon={<PersonAddIcon />}
      />
      <CustomButton
        label="Remove a reporter"
        onClick={openRemoveReporterModal}
        icon={<PersonRemoveIcon />}
      />
      <AppointReporterModal
        open={isOpenAppointReporterModal}
        onClose={closeAppointReporterModal}
        onRequestClose={closeAppointReporterModal}
      />
      <RemoveReporterModal
        open={isOpenRemoveReporterModal}
        onClose={closeRemoveReporterModal}
        onRequestClose={closeRemoveReporterModal}
      />
      <ConfirmModal
        onClose={closeConfirmReleaseModal}
        onRequestClose={closeConfirmReleaseModal}
        onConfirm={isReleased ? onUnreleasedClick : onReleasedClick}
        open={isOpenReleaseModal}
        label={isReleased ? 'Unrelease course?' : 'Release course?'}
        isLoading={isReleasingLoading}
      />
      <ConfirmModal
        onClose={closeConfirmDuplicateModal}
        onRequestClose={closeConfirmDuplicateModal}
        onConfirm={onCourseDuplicate}
        open={isOpenDuplicateModal}
        label="Duplicate course?"
        isLoading={isDuplicateLoading}
      />
    </TopToolbar>
  );
};

export default CourseActions;
