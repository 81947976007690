import { NestedResourcesContext } from 'context/NestedResources';
import { useOnFetchError } from 'hooks';
import React, { useContext } from 'react';
import {
  Create,
  maxLength,
  SimpleForm,
  TextInput,
  useCreate,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Resources } from 'types';
import { validator } from 'utils/validator';
import { useAppNotify } from 'hooks';

const FlashcardsPacksCreate = () => {
  const navigate = useNavigate();
  const { successNotify } = useAppNotify();

  const { onError } = useOnFetchError();
  const [create] = useCreate(Resources.FLASHCARDS_PACKS, undefined, {
    onError,
    onSuccess() {
      successNotify('Flashcards pack is created!');
      navigate(`/${Resources.FLASHCARDS_PACKS}`);
    },
  });
  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};

  const packSave = (data: FieldValues) => {
    create(Resources.FLASHCARDS_PACKS, {
      data: { ...data, courseId },
    });
  };

  return (
    <Create redirect="list">
      <SimpleForm onSubmit={packSave}>
        <TextInput
          source="name"
          validate={[validator.text, maxLength(500), validator.textRequired()]}
          fullWidth
          multiline
        />
      </SimpleForm>
    </Create>
  );
};

export default FlashcardsPacksCreate;
