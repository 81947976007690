import React, { FC } from 'react';
import { NumberField, SimpleShowLayout, TextField } from 'react-admin';
import styled from 'styled-components';

type FieldsProps = {
  type: string;
  subscriptionsTerm: string;
};

const Fields: FC<FieldsProps> = ({ subscriptionsTerm, type }) => {
  return (
    <Root>
      <NumberField
        label="amount"
        source={`prices.${subscriptionsTerm}.${type}.amount`}
      />
      <TextField
        label="description"
        source={`prices.${subscriptionsTerm}.${type}.description`}
      />
      <TextField
        label="Coupon ID"
        source={`prices.${subscriptionsTerm}.${type}.defaultCouponId`}
      />
      <TextField
        label="discount"
        source={`prices.${subscriptionsTerm}.${type}.cancellationPromotionTerms.discount`}
      />
      <TextField
        label="discount type"
        source={`prices.${subscriptionsTerm}.${type}.cancellationPromotionTerms.discountType`}
      />
      <TextField
        label="duration"
        source={`prices.${subscriptionsTerm}.${type}.cancellationPromotionTerms.duration`}
      />
      <TextField
        label="duration in months"
        source={`prices.${subscriptionsTerm}.${type}.cancellationPromotionTerms.durationInMonths`}
      />
    </Root>
  );
};

export default Fields;

const Root = styled(SimpleShowLayout)`
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  gap: 16px;
`;
