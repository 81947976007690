import { useOnFetchError } from 'hooks';
import api from 'api';
import { ImageInput } from 'components';

import React, { useContext } from 'react';
import {
  Create,
  maxLength,
  minLength,
  required,
  SimpleForm,
  TextInput,
  useCreate,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Resources } from 'types';
import { validator } from 'utils/validator';
import { NestedResourcesContext } from 'context/NestedResources';

const FlashcardsCreate = () => {
  const navigate = useNavigate();
  const { onError } = useOnFetchError();
  const [create] = useCreate(Resources.FLASHCARDS, undefined, {
    onError,
    onSuccess() {
      navigate(`/${Resources.FLASHCARDS}`);
    },
  });
  const { urlVariables } = useContext(NestedResourcesContext);
  const { flashcardsPack: packId = '' } = urlVariables || {};

  const createCard = async (data: FieldValues) => {
    await create(Resources.FLASHCARDS, {
      data: { ...data, packId },
    });
  };

  return (
    <Create redirect="list">
      <SimpleForm onSubmit={createCard}>
        <ImageInput
          label="Question image"
          source="questionImageUrl"
          uploadImage={({ filename }) =>
            api.AdminPanelFlashcards.adminFlashcardsControllerUploadImage({
              filename,
              packId,
            })
          }
        />
        <TextInput
          validate={[minLength(2), maxLength(500), required(), validator.text]}
          source="question"
          fullWidth
          multiline
        />
        <ImageInput
          label="Answer image"
          source="answerImageUrl"
          uploadImage={({ filename }) =>
            api.AdminPanelFlashcards.adminFlashcardsControllerUploadImage({
              filename,
              packId,
            })
          }
        />
        <TextInput
          validate={[minLength(2), maxLength(500), required(), validator.text]}
          source="answer"
          fullWidth
          multiline
        />
      </SimpleForm>
    </Create>
  );
};

export default FlashcardsCreate;
