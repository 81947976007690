import {
  Datagrid,
  DateField,
  DeleteButton,
  List,
  ListProps,
  Pagination,
  ShowButton,
  TextField,
  TextInput,
} from 'react-admin';

import { NestedResourcesContext } from 'context/NestedResources';
import { useContext } from 'react';
// import { UrlVariablesEnum } from 'types';

const Filters = [<TextInput key="name" source="name" alwaysOn />];

const CouponsList = (props: ListProps) => {
  const { urlVariables } = useContext(NestedResourcesContext);
  const { career: directionId } = urlVariables || {};

  return (
    <List
      disableSyncWithLocation
      filter={{ directionId }}
      filters={Filters}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField source="name" />
        <DateField source="createdAt" />
        <ShowButton label="Open" />
        <DeleteButton label="Remove" />
      </Datagrid>
    </List>
  );
};

export default CouponsList;
