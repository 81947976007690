import React, { FC } from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';

type CustomTextFieldProps = {
  label: string;
  text: string | number;
};

const CustomTextField: FC<CustomTextFieldProps> = ({ label, text }) => {
  return (
    <Root>
      <Label>{label}</Label>
      <Typography component="span" variant="body2">
        {text}
      </Typography>
    </Root>
  );
};

export default CustomTextField;

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.p`
  font-size: 0.75em !important;
  margin-bottom: 0.2em;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
`;
