import React, { FC } from 'react';
import {
  NumberField,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import styled from 'styled-components';

const PurchaseFields: FC = () => {
  const context = useRecordContext();
  const isPrices = !!context.prices?.purchase;

  return isPrices ? (
    <>
      <Container>
        <NumberField label="amount" source="prices.purchase.amount" />
        <TextField label="description" source="prices.purchase.description" />
        <TextField label="Coupon ID" source="prices.purchase.defaultCouponId" />
      </Container>
    </>
  ) : (
    <p>Course does not have this subscription type</p>
  );
};

export default PurchaseFields;

const Container = styled(SimpleShowLayout)`
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  gap: 16px;
`;
