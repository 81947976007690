import { OpenButton } from 'components';
import { NestedResourcesContext } from 'context/NestedResources';
import { useContext } from 'react';
import {
  Datagrid,
  List,
  ListProps,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin';
import { UrlVariablesEnum } from 'types';

const Filters = [<TextInput key="name" source="name" alwaysOn />];

const MnemonicCardsPacksList = (props: ListProps) => {
  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};

  return (
    <List
      disableSyncWithLocation
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={Filters}
      bulkActionButtons={false}
      filter={{ courseId }}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
      {...props}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="name" />
        <OpenButton
          label="Open pack"
          variable={UrlVariablesEnum.MNEMONIC_CARDS_PACK}
        />
      </Datagrid>
    </List>
  );
};

export default MnemonicCardsPacksList;
