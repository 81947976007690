import api from 'api';
import { ImageInput } from 'components';
import { NestedResourcesContext } from 'context/NestedResources';
import { useOnFetchError } from 'hooks';
import { useContext } from 'react';
import {
  Create,
  maxLength,
  NumberInput,
  required,
  SimpleForm,
  TextInput,
  useCreate,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Resources } from 'types';
import { validator } from 'utils/validator';

const MnemonicCardsCreate = () => {
  const navigate = useNavigate();
  const { onError } = useOnFetchError();
  const [create] = useCreate(Resources.MNEMONIC_CARDS, undefined, {
    onError,
    onSuccess() {
      navigate(`/${Resources.MNEMONIC_CARDS}`);
    },
  });

  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '', mnemonicCardsPack: packId = '' } =
    urlVariables || {};

  const createCard = async (data: FieldValues) => {
    await create(Resources.MNEMONIC_CARDS, {
      data: { ...data, packId },
    });
  };

  return (
    <Create redirect="list">
      <SimpleForm onSubmit={createCard}>
        <ImageInput
          uploadImage={async ({ filename }) =>
            await api.AdminPanelMnemonicCards.adminMnemonicCardsControllerUploadImage(
              { filename, courseId },
            )
          }
          validate={required()}
          isRequired
          source="imageUrl"
        />
        <TextInput
          validate={[validator.textRequired(), maxLength(500)]}
          source="title"
          fullWidth
          multiline
        />
        <NumberInput validate={[required()]} source="answersCount" fullWidth />
      </SimpleForm>
    </Create>
  );
};

export default MnemonicCardsCreate;
