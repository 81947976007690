import api from 'api';
import { DeleteButton } from 'components';
import { useAppNotify, useToggle } from 'hooks';
import { FC, useState } from 'react';
import { Confirm, SaveButton, Toolbar as DefaultToolbar } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Resources } from 'types';

type ToolbarProps = {
  chapterId?: string;
  isFetching: boolean;
};

const Toolbar: FC<ToolbarProps> = ({ isFetching, chapterId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, close, open } = useToggle();
  const { errorNotify } = useAppNotify();
  const navigate = useNavigate();

  const deleteChapter = async (chapterId: string) => {
    try {
      setIsLoading(true);

      await api.AdminPanelGuides.adminGuideChaptersControllerRemoveChapter(
        chapterId,
      );
      navigate(`/${Resources.STUDY_GUIDES}`);
    } catch (err) {
      errorNotify(err);
    } finally {
      setIsLoading(false);
      close();
    }
  };

  return (
    <Root>
      <SaveButton disabled={isLoading || isFetching} />
      {chapterId && (
        <DeleteButton disabled={isLoading || isFetching} onClick={open} />
      )}
      <Confirm
        title="Delete Chapter"
        content="Are you sure you want to delete this chapter?"
        onClose={close}
        onConfirm={() => deleteChapter(chapterId || '')}
        isOpen={isOpen}
        loading={isLoading}
      />
    </Root>
  );
};

export default Toolbar;

const Root = styled(DefaultToolbar)`
  justify-content: space-between;
`;
