import { ToggleResolveReportButton } from 'components';
import {
  Datagrid,
  DateField,
  DateInput,
  List,
  ListProps,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin';
import { NestedResourcesContext } from 'context/NestedResources';
import { useContext } from 'react';

const Filters = [
  <DateInput key="createdAt" source="createdAt" alwaysOn />,
  <TextInput key="course.name" source="course.name" alwaysOn />,
];

const ReportList = (props: ListProps) => {
  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};

  return (
    <List
      filter={{ courseId: courseId }}
      disableSyncWithLocation
      bulkActionButtons={false}
      filters={Filters}
      sort={{ field: 'createdAt', order: 'DESC' }}
      actions={false}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
      {...props}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <ToggleResolveReportButton />
        <TextField source="course.name" />
        <TextField source="resource" />
        <TextField label="Resource Id" source="resourceId" />
        <TextField source="resourceTitle" />
        <TextField source="content" />
        <DateField source="createdAt" />
        <DateField source="resolvedAt" />
      </Datagrid>
    </List>
  );
};

export default ReportList;
