import { useRecordContext } from 'react-admin';
import styled, { css } from 'styled-components';

const ImagesArrayField = ({
  source,
  filePreviewSize = 100,
}: {
  source: string;
  filePreviewSize?: number;
}) => {
  const record = useRecordContext();
  const images = record[source] as Array<string>;
  return (
    <Container>
      {images?.map((image, index) => (
        <StyledImage src={image} key={index} $size={filePreviewSize} />
      ))}
    </Container>
  );
};

export default ImagesArrayField;

const StyledImage = styled.img<{ $size: number }>`
  ${({ $size }) => css`
    width: ${`${$size}px`};
    height: ${`${$size}px`};
  `}

  overflow: hidden;
  object-fit: cover;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
`;
