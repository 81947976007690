import { FC, MouseEvent } from 'react';
import { Button, ShowButtonProps, useRecordContext } from 'react-admin';
import { StorageKeys, StorageService } from 'services';
import styled from 'styled-components';
import { UrlVariablesEnum } from 'types';

type OpenButtonProps = {
  variable: UrlVariablesEnum;
} & ShowButtonProps;

const OpenButton: FC<OpenButtonProps> = ({ variable, ...props }) => {
  const { id, mainColorHex } = useRecordContext(props);

  const selectCareer = () => {
    if (variable === UrlVariablesEnum.CAREER && mainColorHex) {
      StorageService.setItem(StorageKeys.DIRECTION_MAIN_COLOR, mainColorHex);
    }
  };

  const openResource = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    e.stopPropagation();
    e.preventDefault();

    selectCareer();
    const pathname =
      location.pathname[0] === '/'
        ? location.pathname
        : `/${location.pathname}`;

    location.replace(`${location.origin}${pathname}${variable}/${id}/`);
  };
  return <StyledButton {...props} onClick={openResource} />;
};

export default OpenButton;

const StyledButton = styled(Button)`
  white-space: nowrap;
`;
