import { Edit, maxLength, SimpleForm, TextInput } from 'react-admin';
import { validator } from 'utils/validator';

const FlashcardsPacksEdit = () => {
  return (
    <Edit title="Flashcards deck edit" redirect="show">
      <SimpleForm>
        <TextInput
          source="name"
          validate={[validator.text, maxLength(500), validator.textRequired()]}
          fullWidth
          multiline
        />
      </SimpleForm>
    </Edit>
  );
};

export default FlashcardsPacksEdit;
