import { TopToolbar } from 'react-admin';
import ToggleResolveSuggestionButton from '../ToggleResolveSuggestionButton';

const SuggestionsActions = () => {
  return (
    <TopToolbar>
      <ToggleResolveSuggestionButton />
    </TopToolbar>
  );
};

export default SuggestionsActions;
