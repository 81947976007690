import { Datagrid, Identifier, List, ListProps, TextField } from 'react-admin';

import React, { useContext } from 'react';
import { Resources } from 'types';
import { NestedResourcesContext } from 'context/NestedResources';

const StudyPlansList = (props: ListProps) => {
  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};

  const selectPlan = (id: Identifier) => `/${Resources.STUDY_PLANS}/${id}/show`;

  return (
    <List
      disableSyncWithLocation
      {...props}
      queryOptions={{
        meta: { courseId },
      }}
      title="Study plans"
      resource={Resources.STUDY_PLANS}
      bulkActionButtons={false}
    >
      <Datagrid bulkActionButtons={false} rowClick={selectPlan}>
        <TextField sortable={false} source="name" />
      </Datagrid>
    </List>
  );
};

export default StudyPlansList;
