import {
  Edit,
  maxLength,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
} from 'react-admin';

import React, { FC } from 'react';
import { validator } from 'utils/validator';

const StudyPlanEditToolbar: FC<ToolbarProps> = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const StudyPlanEdit = () => {
  return (
    <Edit title="Edit study plan" redirect="show">
      <SimpleForm toolbar={<StudyPlanEditToolbar />}>
        <TextInput
          validate={[validator.textRequired(), maxLength(500), validator.text]}
          source="name"
          fullWidth
          multiline
        />
      </SimpleForm>
    </Edit>
  );
};

export default StudyPlanEdit;
