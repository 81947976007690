import { NestedResourcesContext } from 'context/NestedResources';
import { useOnFetchError } from 'hooks';
import { useContext } from 'react';
import {
  Create,
  maxLength,
  SimpleForm,
  TextInput,
  useCreate,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Resources } from 'types';
import { validator } from 'utils/validator';

const StudyPlansTaskCreate = () => {
  const navigate = useNavigate();
  const { onError } = useOnFetchError(
    'Study plan tasks create error. Make sure that a task with the same name does not exist',
  );
  const [create] = useCreate(Resources.STUDY_PLANS_TASKS, undefined, {
    onError,
    onSuccess() {
      navigate(`/${Resources.STUDY_PLANS_TASKS}`);
    },
  });

  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};

  const handleSubmit = (data: FieldValues) => {
    create(Resources.STUDY_PLANS_TASKS, {
      data: {
        ...data,
        courseId,
      },
    });
  };

  return (
    <Create redirect="list">
      <SimpleForm onSubmit={handleSubmit}>
        <TextInput
          validate={[validator.textRequired(), maxLength(500), validator.text]}
          source="name"
          fullWidth
          multiline
        />
      </SimpleForm>
    </Create>
  );
};

export default StudyPlansTaskCreate;
