import { ExportButton, ImportButton } from 'components';
import { NestedResourcesContext } from 'context/NestedResources';
import React, { FC, useContext } from 'react';
import {
  CreateButton,
  Datagrid,
  DateField,
  List,
  ListProps,
  Pagination,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin';
import { ImportButtonProps } from 'components/ImportButton/ImportButton';
import { useUploadQuestions } from 'hooks';

const Filters = [<TextInput key="subject" source="subject" alwaysOn />];

type BulkActionButtonsProps = {
  courseId: string;
  createTest: (file: File) => Promise<void>;
} & ImportButtonProps;

const BulkActionButtons: FC<BulkActionButtonsProps> = ({
  courseId,
  createTest,
  ...props
}) => {
  return (
    <TopToolbar>
      <CreateButton />
      <ExportButton
        queryOptions={{
          meta: {
            join: ['domain', 'chapter'],
            or: `chapter.courseId||$eq||${courseId}`,
          },
        }}
      />
      <ImportButton {...props} uploadFile={createTest} />
    </TopToolbar>
  );
};

const TestQuestionsList = (props: ListProps) => {
  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};
  const { createTest } = useUploadQuestions(courseId);

  return (
    <List
      disableSyncWithLocation
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={Filters}
      actions={
        <BulkActionButtons courseId={courseId} createTest={createTest} />
      }
      queryOptions={{
        meta: {
          join: ['domain', 'chapter'],
        },
      }}
      filter={{ 'domain.courseId': courseId }}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
      {...props}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField
          label="Question"
          source="subject"
          sx={{
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word',
            wordBreak: 'break-word',
            flexWrap: 'wrap',
          }}
        />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List>
  );
};

export default TestQuestionsList;
