import { ShowActions } from 'components';
import { DateField, Show, Tab, TabbedShowLayout, TextField } from 'react-admin';

const FlashcardsPacksShow = () => {
  return (
    <Show title="Flashcards deck" actions={<ShowActions />}>
      <TabbedShowLayout>
        <Tab label="About flashcards packs">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="flashcardsCount" />
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default FlashcardsPacksShow;
