import { memo } from 'react';
import { maxLength, minLength, TextInput } from 'react-admin';

const PaymentFields = () => {
  return (
    <>
      <h3>Purchase</h3>
      <TextInput
        label="Start phrase (green part)"
        source="purchase.startPhrase"
        fullWidth
        multiline
      />
      <TextInput
        label="End phrase (black part)"
        source="purchase.endPhrase"
        fullWidth
        multiline
      />
      <h3>Yearly</h3>
      <TextInput
        label="Start phrase (green part)"
        source="annualSubscriptions.startPhrase"
        fullWidth
        multiline
      />
      <TextInput
        label="End phrase (black part)"
        source="annualSubscriptions.endPhrase"
        fullWidth
        multiline
      />
      <h3>Monthly</h3>
      <TextInput
        label="Start phrase (green part)"
        source="monthlySubscriptions.startPhrase"
        fullWidth
        multiline
      />
      <TextInput
        label="End phrase (black part)"
        source="monthlySubscriptions.endPhrase"
        fullWidth
        multiline
      />
      <h3>Most effective label</h3>
      <TextInput
        validate={[maxLength(30), minLength(5)]}
        label="Label text"
        source="mostEffective.phrase"
        fullWidth
      />
      <TextInput
        validate={[maxLength(30)]}
        label="Label color"
        source="mostEffective.color"
        fullWidth
      />
    </>
  );
};

export default memo(PaymentFields);
