import { ShowActions, StyledImageField } from 'components';
import { Show, Tab, TabbedShowLayout, TextField } from 'react-admin';

const FlashcardsShow = () => {
  return (
    <Show actions={<ShowActions />} title="Flashcard">
      <TabbedShowLayout>
        <Tab label="About flashcard">
          <TextField source="id" />
          <StyledImageField
            label="Question image"
            $size={100}
            source="questionImageUrl"
          />
          <TextField source="question" />
          <StyledImageField
            label="Answer image"
            $size={100}
            source="answerImageUrl"
          />
          <TextField source="answer" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default FlashcardsShow;
