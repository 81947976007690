import {
  Edit,
  maxLength,
  NumberInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { validator } from 'utils/validator';
import React from 'react';

const TestChaptersEdit = () => {
  return (
    <Edit title="Edit chapter" redirect="show">
      <SimpleForm>
        <TextInput
          source="title"
          validate={[validator.textRequired(), maxLength(500), validator.text]}
          fullWidth
          multiline
        />
        <NumberInput
          source="sortIndex"
          validate={validator.sortIndex()}
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};

export default TestChaptersEdit;
