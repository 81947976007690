import { useAppNotify, useToggle } from 'hooks';
import { useState } from 'react';
import { EditButton, TopToolbar, useRecordContext } from 'react-admin';
import DeleteButton from '../DeleteButton';
import { StorageKeys, StorageService } from 'services';
import { AdminRoleEnum } from 'api/generated';
import DirectionDeleteModal from '../Modals/DirectionDeleteModal';
import api from 'api';
import { useNavigate } from 'react-router-dom';

const DirectionActions = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { successNotify, errorNotify } = useAppNotify();
  const career = useRecordContext();
  const navigate = useNavigate();

  const role = StorageService.getItem(StorageKeys.ROLE);
  const isSuperAdmin = role === AdminRoleEnum.Superadmin;

  const onDeleteConfirm = async () => {
    setIsDeleting(true);

    try {
      await api.AdminPanelDirections.adminDirectionsControllerDelete(
        (career?.id as string) || '',
      );
      successNotify(`Career ${career?.name} successful deleted`);
      navigate(-1);
    } catch (err) {
      errorNotify(err);
    } finally {
      setIsDeleting(false);
      closeDeleteCareerModal();
    }
  };

  const {
    close: closeDeleteCareerModal,
    isOpen: isOpenReleaseModal,
    open: openDeleteCareerModal,
  } = useToggle();

  return (
    <TopToolbar>
      <EditButton />
      {isSuperAdmin && (
        <>
          <DeleteButton
            text="DELETE"
            onClick={openDeleteCareerModal}
            disabled={isDeleting}
            small
          />
          <DirectionDeleteModal
            onRequestClose={closeDeleteCareerModal}
            onConfirm={onDeleteConfirm}
            open={isOpenReleaseModal}
            isLoading={isDeleting}
          />
        </>
      )}
    </TopToolbar>
  );
};

export default DirectionActions;
