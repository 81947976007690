import { DomainOrChapterRequestDto } from 'api/generated';
import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { useFormContext, useFieldArray } from 'react-hook-form';
import styled from 'styled-components';
import { Domain } from './components';

type DomainsType = {
  domains: Array<DomainOrChapterRequestDto>;
  chapters?: [];
};

const Domains = ({ isLoading }: { isLoading?: boolean }) => {
  const { control, getValues } = useFormContext<DomainsType>();
  const { append, remove, update, ...params } = useFieldArray({
    control,
    keyName: 'keyId',
    name: 'domains',
    rules: { minLength: 1 },
  });

  const fields = params.fields;
  const values = getValues();

  const generateFields = () => {
    const fieldsLength = fields.length;
    const domainCount = values?.domains?.length || 0;

    if (fieldsLength !== 0) {
      for (let i = 0; i <= domainCount + 1; i++) {
        append(
          values?.domains[i]
            ? values?.domains[i]
            : { id: '', amountOfQuestions: 1 },
        );
      }
    }
  };

  const removeFields = () => {
    const fieldsLength = fields.length;

    for (let i = fieldsLength; i === 0; i--) {
      remove(i);
    }
  };

  const addField = () => {
    append({ id: '', amountOfQuestions: 1 });
  };

  useEffect(() => {
    generateFields();
    return removeFields();
  }, [Boolean(values?.domains)]);

  const hasChapters = Boolean(values.chapters?.length);

  if (hasChapters) {
    return null;
  }

  return (
    <Root>
      {values?.domains?.map((field, index) => (
        <Domain key={index} index={index} removeField={remove} />
      ))}
      <StyledButton variant="outlined" disabled={isLoading} onClick={addField}>
        Add domain
      </StyledButton>
    </Root>
  );
};

export default Domains;

const Root = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
`;

const StyledButton = styled(Button)`
  width: fit-content;
`;
