import { useToggle } from 'hooks';
import {
  Datagrid,
  DeleteButton,
  List,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  Button,
  RowClickFunction,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Resources } from 'types';
import { AddTaskToPhaseModal } from './components';

const StudyPlanPhaseShow = () => {
  const { id } = useParams();
  const { isOpen, open, close } = useToggle();

  const selectTask: RowClickFunction = (id, resource, record) => {
    const taskId = record.task.id.toString();

    return `/${Resources.STUDY_PLANS_TASKS}/${taskId}/show`;
  };

  return (
    <Show title="Phase">
      <TabbedShowLayout>
        <Tab label="Study plan phase">
          <TextField label="Phase id" source="id" />
          <TextField label="Phase name" source="name" />
          <h4>Tasks</h4>
          <List
            disableSyncWithLocation
            actions={false}
            empty={<StyledButton label="Add task to phase" onClick={open} />}
            resource={Resources.STUDY_PLANS_PHASES_TASKS}
            queryOptions={{ meta: { planPhaseId: id } }}
          >
            <>
              <Datagrid rowClick={selectTask} bulkActionButtons={false}>
                <TextField sortable={false} source="task.name" />
                <DeleteButton
                  redirect={`/${Resources.STUDY_PLANS_PHASES}/${id}/show`}
                />
              </Datagrid>
              <StyledButton label="Add task to phase" onClick={open} />
            </>
          </List>
        </Tab>
      </TabbedShowLayout>
      <AddTaskToPhaseModal open={isOpen} onClose={close} />
    </Show>
  );
};

export default StudyPlanPhaseShow;

const StyledButton = styled(Button)`
  margin: 8px 16px !important;
`;
