import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Button, CardContent, CircularProgress } from '@mui/material';
import { Form, useTranslate, useLogin, useSafeSetState } from 'ra-core';
import { FieldValues } from 'react-hook-form';
import { useState } from 'react';
import { ForgotPasswordModal } from 'components';
import { LoginFormFields } from './LoginFormFields';
import { useAppNotify } from 'hooks';

interface LoginFormProps {
  redirectTo?: string;
  className?: string;
}

const LoginForm = (props: LoginFormProps) => {
  const { redirectTo, className } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const [isForgotModalShow, setIsForgotModalShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const login = useLogin();
  const translate = useTranslate();
  const { warningNotify } = useAppNotify();

  const submit = async (values: FieldValues) => {
    setLoading(true);
    try {
      await login(values, redirectTo);

      setLoading(false);
      setErrorMessage('');
    } catch (error: any) {
      setLoading(false);
      setErrorMessage(error);

      const warningMessage =
        typeof error === 'string'
          ? error
          : typeof error === 'undefined' || !error.message
          ? 'ra.auth.sign_in_error'
          : 'Email or password not matched';

      warningNotify(warningMessage);
    }
  };

  return (
    <StyledForm onSubmit={submit} mode="onChange" className={className}>
      <CardContent className={LoginFormClasses.content}>
        <LoginFormFields errorMessage={errorMessage} />
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading}
          fullWidth
          className={LoginFormClasses.button}
        >
          {loading ? (
            <CircularProgress
              className={LoginFormClasses.icon}
              size={19}
              thickness={3}
            />
          ) : (
            translate('ra.auth.sign_in')
          )}
        </Button>
        <TextButton onClick={() => setIsForgotModalShow(true)}>
          Forgot password?
        </TextButton>
      </CardContent>
      <ForgotPasswordModal
        open={isForgotModalShow}
        onClose={() => setIsForgotModalShow(false)}
      />
    </StyledForm>
  );
};

export default LoginForm;

const PREFIX = 'RaLoginForm';

export const LoginFormClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
};

const StyledForm = styled(Form, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${LoginFormClasses.content}`]: {
    width: 300,
  },
  [`& .${LoginFormClasses.button}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${LoginFormClasses.icon}`]: {
    margin: theme.spacing(0.3),
  },
}));

const TextButton = styled(Button)`
  cursor: pointer;
  padding: 0;
  margin: 0;
  background-color: transparent;
  outline: 0;
  margin-top: 20px;
`;
