import { NestedResourcesContext } from 'context/NestedResources';
import { useOnFetchError } from 'hooks';
import React, { useContext } from 'react';
import {
  Create,
  maxLength,
  NumberInput,
  SimpleForm,
  TextInput,
  useCreate,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Resources } from 'types';
import { validator } from 'utils/validator';

const TestChaptersCreate = () => {
  const navigate = useNavigate();
  const { onError } = useOnFetchError();
  const [create] = useCreate(Resources.TEST_CHAPTERS, undefined, {
    onError,
    onSuccess() {
      navigate(`/${Resources.TEST_CHAPTERS}`);
    },
  });
  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};

  const createChapter = (data: FieldValues) => {
    create(Resources.TEST_CHAPTERS, { data: { ...data, courseId } });
  };

  return (
    <Create title="Create chapter" redirect="list">
      <SimpleForm onSubmit={createChapter}>
        <TextInput
          source="title"
          validate={[validator.textRequired(), maxLength(500), validator.text]}
          fullWidth
          multiline
        />
        <NumberInput
          source="sortIndex"
          fullWidth
          validate={validator.sortIndex()}
        />
      </SimpleForm>
    </Create>
  );
};

export default TestChaptersCreate;
