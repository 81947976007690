import { Layout } from 'components';
import { authProvider, dataProvider } from 'providers';
import { useContext } from 'react';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import {
  Chapter,
  CourseCreate,
  CourseEdit,
  CourseShow,
  CoursesList,
  CustomerShow,
  CustomerResetPassword,
  CustomersList,
  DirectionCreate,
  DirectionEdit,
  DirectionShow,
  DirectionsList,
  MnemonicCardsCreate,
  MnemonicCardsEdit,
  MnemonicCardsList,
  MnemonicCardsShow,
  MnemonicCardsPacksList,
  MnemonicCardsPacksShow,
  MnemonicCardsPacksCreate,
  MnemonicCardsPacksEdit,
  FlashcardsCreate,
  FlashcardsEdit,
  FlashcardsList,
  FlashcardsShow,
  FlashcardsPacksList,
  FlashcardsPacksShow,
  FlashcardsPacksCreate,
  FlashcardsPacksEdit,
  StudyPlansList,
  StudyPlanShow,
  StudyPlanPhaseShow,
  StudyPlanPhaseEdit,
  StudyPlansTaskShow,
  StudyPlanEdit,
  StudyPlansTasksList,
  StudyPlansTaskCreate,
  StudyPlansTaskEdit,
  ReportList,
  ReportShow,
  StudyGuide,
  TestsList,
  TestsShow,
  TestTopicsList,
  TestTopicsShow,
  TestQuestionsList,
  TestQuestionsShow,
  TestQuestionsEdit,
  TestQuestionsCreate,
  TestChaptersList,
  TestChaptersShow,
  TestChaptersCreate,
  TestChaptersEdit,
  TestTopicsCreate,
  TestTopicsEdit,
  TestsCreate,
  TestsEdit,
  AdminsList,
  AdminsShow,
  AdminsCreate,
  AdminsEdit,
  ResolvedReportsList,
  InviteList,
  InviteCreate,
  InviteShow,
  ManualsList,
  ManualsEdit,
  SuggestionsList,
  SuggestionsShow,
  CouponsList,
  CouponsShow,
  CouponsEdit,
  CouponsCreate,
} from 'resources';
import { Resources } from 'types';
import Bookmarks from '@mui/icons-material/Bookmarks';
import Style from '@mui/icons-material/Style';
import PeopleAlt from '@mui/icons-material/PeopleAlt';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import AllInbox from '@mui/icons-material/ArtTrack';
import DehazeIcon from '@mui/icons-material/Dehaze';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import EmailIcon from '@mui/icons-material/Email';
import ReportIcon from '@mui/icons-material/Report';
import MenuBook from '@mui/icons-material/MenuBook';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import { Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { LoginPage } from 'pages';
import { useDirectionTheme } from 'hooks';
import QuizIcon from '@mui/icons-material/Quiz';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import TopicIcon from '@mui/icons-material/Topic';
import { NestedResourcesContext } from 'context/NestedResources';
import { QuizTestsCreate, QuizTestsEdit } from 'resources/QuizTests';
import ResolvedReportIcon from '@mui/icons-material/ReportGmailerrorred';
import AddCommentIcon from '@mui/icons-material/AddComment';

//test change

const CourseResources = (
  <>
    <Resource
      options={{ label: 'Study guide' }}
      name={Resources.STUDY_GUIDES}
      icon={MenuBook}
      list={StudyGuide}
      create={Chapter}
      edit={Chapter}
    />
    <Resource
      name={Resources.MANUALS}
      options={{ label: 'Manuals' }}
      icon={LibraryBooks}
      list={ManualsList}
      edit={ManualsEdit}
    />
    <CustomRoutes>
      <Route path={Resources.CHAPTERS} element={<Chapter />} />
    </CustomRoutes>
    <Resource
      name={Resources.MNEMONIC_CARDS_PACKS}
      options={{ label: 'Mnemonic cards decks' }}
      icon={AllInbox}
      list={MnemonicCardsPacksList}
      show={MnemonicCardsPacksShow}
      create={MnemonicCardsPacksCreate}
      edit={MnemonicCardsPacksEdit}
    />
    <Resource
      name={Resources.FLASHCARDS_PACKS}
      options={{ label: 'Flashcards decks' }}
      icon={AllInbox}
      list={FlashcardsPacksList}
      show={FlashcardsPacksShow}
      create={FlashcardsPacksCreate}
      edit={FlashcardsPacksEdit}
    />
    <Resource
      name={Resources.STUDY_PLANS}
      options={{ label: 'Study plans' }}
      icon={DehazeIcon}
      list={StudyPlansList}
      show={StudyPlanShow}
      edit={StudyPlanEdit}
    />
    <Resource
      name={Resources.STUDY_PLANS_PHASES}
      show={StudyPlanPhaseShow}
      edit={StudyPlanPhaseEdit}
    />
    <Resource
      options={{ label: 'Study plan tasks' }}
      name={Resources.STUDY_PLANS_TASKS}
      icon={MenuOpenIcon}
      list={StudyPlansTasksList}
      show={StudyPlansTaskShow}
      create={StudyPlansTaskCreate}
      edit={StudyPlansTaskEdit}
    />
    <Resource
      name={Resources.TESTS_TIMED}
      options={{ label: 'Timed tests' }}
      icon={HourglassEmptyIcon}
      list={TestsList}
      show={TestsShow}
      create={TestsCreate}
      edit={TestsEdit}
    />
    <Resource
      name={Resources.TESTS_QUIZ}
      options={{ label: 'Quiz tests' }}
      icon={QuizIcon}
      create={QuizTestsCreate}
      edit={QuizTestsEdit}
    />
    <Resource
      name={Resources.TEST_TOPICS}
      options={{ label: 'Test domains' }}
      icon={TopicIcon}
      list={TestTopicsList}
      show={TestTopicsShow}
      create={TestTopicsCreate}
      edit={TestTopicsEdit}
    />
    <Resource
      name={Resources.TEST_CHAPTERS}
      options={{ label: 'Test chapters' }}
      icon={QuizIcon}
      list={TestChaptersList}
      show={TestChaptersShow}
      create={TestChaptersCreate}
      edit={TestChaptersEdit}
    />
    <Resource
      name={Resources.TEST_QUESTIONS}
      options={{ label: 'Questions' }}
      icon={QuestionMarkIcon}
      list={TestQuestionsList}
      show={TestQuestionsShow}
      create={TestQuestionsCreate}
      edit={TestQuestionsEdit}
    />
    <Resource
      options={{ label: 'Reports' }}
      name={Resources.REPORTS}
      icon={ReportIcon}
      list={ReportList}
      show={ReportShow}
    />
    <Resource
      options={{ label: 'Resolved reports' }}
      name="resolved_reports"
      icon={ResolvedReportIcon}
      list={ResolvedReportsList}
      show={ReportShow}
    />
    <Resource
      options={{ label: 'Suggestions' }}
      name={Resources.SUGGESTIONS}
      icon={AddCommentIcon}
      list={SuggestionsList}
      show={SuggestionsShow}
    />
    <Resource
      name={Resources.FLASHCARDS}
      options={{ label: 'Flashcards' }}
      icon={ArtTrackIcon}
      show={FlashcardsShow}
      create={FlashcardsCreate}
      edit={FlashcardsEdit}
    />
    <Resource
      name={Resources.MNEMONIC_CARDS}
      options={{ label: 'Mnemonic cards' }}
      icon={ArtTrackIcon}
      show={MnemonicCardsShow}
      create={MnemonicCardsCreate}
      edit={MnemonicCardsEdit}
    />
    <Resource
      name={Resources.INVITES}
      options={{ label: 'Invites' }}
      icon={EmailIcon}
      list={InviteList}
      create={InviteCreate}
      show={InviteShow}
    />
  </>
);

const DirectionResources = (
  <>
    <Resource
      name={Resources.COURSES}
      options={{ label: 'Courses' }}
      icon={Bookmarks}
      list={CoursesList}
      show={CourseShow}
      create={CourseCreate}
      edit={CourseEdit}
    />
    <Resource
      name={Resources.CUSTOMERS}
      options={{ label: 'Customers' }}
      icon={PeopleAlt}
      list={CustomersList}
      show={CustomerShow}
      edit={CustomerResetPassword}
    />
    <Resource
      name={Resources.COUPONS}
      options={{ label: 'Coupons' }}
      icon={ConfirmationNumberIcon}
      list={CouponsList}
      show={CouponsShow}
      create={CouponsCreate}
      edit={CouponsEdit}
    />
  </>
);

const RootResources = (
  <>
    <Resource
      name={Resources.DIRECTIONS}
      options={{ label: 'Careers' }}
      icon={Style}
      list={DirectionsList}
      show={DirectionShow}
      create={DirectionCreate}
      edit={DirectionEdit}
    />
    <Resource
      name={Resources.ADMINS}
      icon={SupervisorAccountIcon}
      list={AdminsList}
      show={AdminsShow}
      create={AdminsCreate}
      edit={AdminsEdit}
    />
  </>
);

const App = () => {
  const { urlVariables } = useContext(NestedResourcesContext);
  const { currentTheme } = useDirectionTheme(Boolean(urlVariables?.career));

  return (
    <ThemeProvider theme={currentTheme}>
      <Admin
        theme={currentTheme}
        dataProvider={dataProvider}
        authProvider={authProvider}
        layout={Layout}
        loginPage={LoginPage}
      >
        {urlVariables?.flashcardsPack ? (
          <Resource
            name={Resources.FLASHCARDS}
            options={{ label: 'Flashcards' }}
            icon={ArtTrackIcon}
            list={FlashcardsList}
            show={FlashcardsShow}
            create={FlashcardsCreate}
            edit={FlashcardsEdit}
          />
        ) : urlVariables?.mnemonicCardsPack ? (
          <Resource
            name={Resources.MNEMONIC_CARDS}
            options={{ label: 'Mnemonic cards' }}
            icon={ArtTrackIcon}
            list={MnemonicCardsList}
            show={MnemonicCardsShow}
            create={MnemonicCardsCreate}
            edit={MnemonicCardsEdit}
          />
        ) : urlVariables?.course ? (
          CourseResources
        ) : urlVariables?.career ? (
          DirectionResources
        ) : (
          RootResources
        )}
      </Admin>
    </ThemeProvider>
  );
};

export default App;
