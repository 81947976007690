import {
  ImageInput as DefaultImageInput,
  ImageInputProps as DefaultImageInputProps,
  ImageField,
  useRecordContext,
  IconButtonWithTooltip,
} from 'react-admin';
import React, { useEffect, useMemo, useState } from 'react';

import { FileCrudService } from 'services';
import styled, { css } from 'styled-components';
import { useFormContext } from 'react-hook-form';
import Close from '@mui/icons-material/Close';
import Undo from '@mui/icons-material/Undo';
import { File } from './components';
import { FileEnum } from './components/File/File';
import { AdminSignedUrlDto, AdminSignedUrlResponseDto } from 'api/generated';
import { useAppNotify } from 'hooks';

const IMAGE_INPUT = 'image-input';
const ACCEPTED_FILE_EXTENSIONS = 'image/png,image/jpg,image/jpeg,image/svg';

type ImageInputProps = {
  isRequired?: boolean;
  fileType?: FileEnum;
  filePreviewSize?: number;
  uploadImage: (body: { filename: string }) => Promise<{
    data: AdminSignedUrlDto | AdminSignedUrlResponseDto;
  }>;
} & DefaultImageInputProps;

const ImageInput = ({
  source,
  isRequired,
  fileType = FileEnum.IMAGE,
  uploadImage,
  filePreviewSize = 150,
  maxSize,
  ...props
}: ImageInputProps) => {
  const { errorNotify } = useAppNotify();
  const [uploadedFile, setUploadedFile] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const record = useRecordContext();
  const { setValue, getValues, watch, setError, clearErrors } =
    useFormContext();
  const initialValues = useMemo(() => getValues(), []);

  const { [source]: initialFileKey } = record || initialValues || {};
  const fileKey = watch(source);
  const isDirtySource = fileKey !== initialFileKey;
  const canRemoveImage = Boolean(fileKey) && !isRequired;

  const handleChange = async (file: File | null) => {
    if (file) {
      const isValid = validateSize(file.size);

      if (isValid) {
        uploadFile(file);
      } else {
        resetImage();
      }
    }
  };

  const validateSize = (fileSize: number) => {
    if (maxSize && fileSize > maxSize) {
      const sizeInMB = maxSize / 1000000;

      errorNotify(`Max file size ${sizeInMB}MB`);

      return false;
    }
    return true;
  };

  const uploadFile = async (file: File) => {
    if (file.name) {
      try {
        setIsLoading(true);

        const response = await uploadImage({
          filename: file.name,
        });

        const { signedUrl, accessUrl } = response.data;

        setUploadedFile(URL.createObjectURL(file));

        await FileCrudService.put({ url: signedUrl, file });

        setValue(source, accessUrl);
      } catch {
        resetImage();
      } finally {
        setIsLoading(false);
      }
    }
  };

  const resetImage = () => {
    setValue(source, initialFileKey);
    setValue(IMAGE_INPUT, undefined);
    setUploadedFile('');
  };

  const removeImage = () => {
    setValue(source, null, { shouldDirty: true, shouldTouch: true });
    setValue(IMAGE_INPUT, undefined);
    setUploadedFile('');
  };

  const onLoad = () => {
    if (isLoading) {
      setError(source, { message: 'loading' });
    } else {
      clearErrors();
    }
  };

  useEffect(onLoad, [isLoading]);

  return (
    <Root>
      <DefaultImageInput
        onChange={handleChange}
        accept={ACCEPTED_FILE_EXTENSIONS}
        options={{ disabled: isLoading }}
        {...props}
        source={IMAGE_INPUT}
      >
        <HiddenImageField source="src" title="title" />
      </DefaultImageInput>
      <Container>
        {uploadedFile ? (
          <File
            size={filePreviewSize}
            isLoading={isLoading}
            type={fileType}
            value={uploadedFile}
          />
        ) : (
          Boolean(fileKey) && (
            <File
              size={filePreviewSize}
              isLoading={isLoading}
              type={fileType}
              value={fileKey}
            />
          )
        )}
        <ButtonsContainer $filePreviewSize={filePreviewSize}>
          {canRemoveImage && (
            <IconButtonWithTooltip
              onClick={removeImage}
              type="button"
              label="remove"
            >
              <Close />
            </IconButtonWithTooltip>
          )}
          {isDirtySource && (
            <IconButtonWithTooltip
              onClick={resetImage}
              type="button"
              label="reset"
            >
              <Undo />
            </IconButtonWithTooltip>
          )}
        </ButtonsContainer>
      </Container>
    </Root>
  );
};

export default ImageInput;

const Root = styled.div`
  .previews {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const HiddenImageField = styled(ImageField)`
  width: 0;
  height: 0;
  opacity: 0;

  * {
    width: 0;
    height: 0;
  }
`;

const ButtonsContainer = styled.div<{ $filePreviewSize: number }>`
  display: flex;
  align-items: center;

  ${({ $filePreviewSize }) =>
    $filePreviewSize > 100 &&
    css`
      flex-direction: column;
    `}
`;
