import { NestedResourcesContext } from 'context/NestedResources';
import { useOnFetchError } from 'hooks';
import { useContext } from 'react';
import {
  Create,
  NumberInput,
  required,
  SimpleForm,
  useCreate,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Resources } from 'types';

import { DomainOrChapterRequestDto } from 'api/generated';
import Chapters from '../Chapters/Chapters';
import Domains from '../Domains/Domains';
import { validator } from 'utils/validator';

type TopicsType = {
  domains: Array<DomainOrChapterRequestDto>;
};

const TestsCreate = () => {
  const navigate = useNavigate();
  const { onError } = useOnFetchError();
  const [create] = useCreate(Resources.TESTS_TIMED, undefined, {
    onError,
    onSuccess() {
      navigate(`/${Resources.TESTS_TIMED}`);
    },
  });

  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};

  const createTest = async (data: FieldValues) => {
    await create(Resources.TESTS_TIMED, {
      data: { ...data, courseId },
    });
  };

  const handleSubmit = async (values: TopicsType) => {
    await createTest(values);
  };

  return (
    <Create>
      <SimpleForm onSubmit={(e) => handleSubmit(e as TopicsType)}>
        <NumberInput
          source="durationSec"
          validate={[required(), validator.notZero]}
        />
        <Domains />
        <Chapters />
      </SimpleForm>
    </Create>
  );
};

export default TestsCreate;
