import { AdminRoleEnum } from 'api/generated';
import React, { ChangeEvent, useState } from 'react';
import {
  AutocompleteArrayInput,
  Edit,
  RaRecord,
  ReferenceArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  maxLength,
  required,
  useEditController,
  TopToolbar,
  DeleteWithConfirmButton,
  SaveButton,
  ShowButton,
  RecordContext,
} from 'react-admin';
import { Resources } from 'types';
import { setStringArray } from 'utils/setStringArray';
import { validator } from 'utils/validator';
import { useAdminActions } from '../hooks';

const AdminsEdit = () => {
  const { record } = useEditController();
  const [isAdminSelected, setIsAdminSelected] = useState(
    record.role === 'admin',
  );

  const { onTransform } = useAdminActions();
  const onChangeRole = (params: ChangeEvent<HTMLInputElement> | RaRecord) => {
    setIsAdminSelected(params.target.value === 'admin');
  };

  const TopActionsBar = () => (
    <TopToolbar>
      <ShowButton />
      <DeleteWithConfirmButton />
    </TopToolbar>
  );

  const BottomActionsBar = () => (
    <TopToolbar>
      <SaveButton />
    </TopToolbar>
  );

  const getParsedData = (value: any) => {
    return {
      ...value,
      directions: value.directions.map((item: any) => item.id),
    };
  };

  return (
    <Edit
      title="Admin edit"
      redirect="show"
      transform={onTransform}
      actions={<TopActionsBar />}
    >
      <RecordContext.Consumer>
        {(value) => {
          const parsedValue = getParsedData(value);

          return (
            <SimpleForm toolbar={<BottomActionsBar />} record={parsedValue}>
              <TextInput
                source="email"
                validate={[maxLength(155), validator.email, required()]}
                fullWidth
                multiline
              />
              <TextInput
                source="nickname"
                validate={[maxLength(100), required(), validator.text]}
                fullWidth
                multiline
              />
              <TextInput
                source="firstName"
                validate={[maxLength(100), required(), validator.fullName]}
                fullWidth
                multiline
              />
              <TextInput
                source="lastName"
                validate={[maxLength(100), required(), validator.fullName]}
                fullWidth
                multiline
              />
              <SelectInput
                source="role"
                choices={[
                  { id: AdminRoleEnum.Admin, name: 'Admin' },
                  { id: AdminRoleEnum.Superadmin, name: 'Superadmin' },
                ]}
                validate={required()}
                onChange={onChangeRole}
                fullWidth
              />

              {isAdminSelected && (
                <ReferenceArrayInput
                  label="Attached careers"
                  source="directions"
                  reference={Resources.DIRECTIONS}
                  sort={{ field: 'name', order: 'ASC' }}
                >
                  <AutocompleteArrayInput
                    label="Careers"
                    filterToQuery={(searchText) => ({ subject: searchText })}
                    optionText="name"
                    format={setStringArray}
                    fullWidth
                  />
                </ReferenceArrayInput>
              )}
            </SimpleForm>
          );
        }}
      </RecordContext.Consumer>
    </Edit>
  );
};

export default AdminsEdit;
