import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import Close from '@mui/icons-material/Close';
import { IconButtonWithTooltip } from 'react-admin';

type RemoveButtonProps = {
  filePreviewSize: number;
  removeImage: () => void;
};

const RemoveButton: FC<RemoveButtonProps> = ({
  filePreviewSize,
  removeImage,
}) => {
  return (
    <ButtonsContainer $filePreviewSize={filePreviewSize}>
      <IconButtonWithTooltip onClick={removeImage} type="button" label="remove">
        <Close />
      </IconButtonWithTooltip>
    </ButtonsContainer>
  );
};

export default RemoveButton;

const ButtonsContainer = styled.div<{ $filePreviewSize: number }>`
  display: flex;
  align-items: center;

  ${({ $filePreviewSize }) =>
    $filePreviewSize > 100 &&
    css`
      flex-direction: column;
    `}
`;
