import { regex } from 'const';
import { useOutsideClick, useToggle } from 'hooks';
import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { TextInput, TextInputProps, Validator } from 'react-admin';
import { ChromePicker, ColorResult } from 'react-color';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

type ColorInputProps = {
  onColorPickInputChanged?: React.Dispatch<React.SetStateAction<boolean>>;
} & TextInputProps;

const ColorInput: FC<ColorInputProps> = (props) => {
  const { source, onColorPickInputChanged } = props;
  const rootRef = useRef(null);
  const { setValue, watch } = useFormContext();

  const { close, isOpen, toggle } = useToggle();
  useOutsideClick(rootRef, close);

  const color: string | null = watch(source);
  const [_, setColorState] = useState(color);

  const handlePickerChange = (colorResult: ColorResult) => {
    const newColor = colorResult.hex;
    changeColor(newColor);

    setColorState((prev) => {
      if (prev !== newColor) {
        onColorPickInputChanged?.(true);
      }

      return newColor;
    });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newColor = event.target.value as string;
    changeColor(newColor);
  };

  const changeColor = (newColor: string) => {
    setValue(source, newColor);
  };

  const validateColorCode: Validator = (value) => {
    return regex.hex.test(value)
      ? undefined
      : 'mainColorHex must match /^#([AaBbCcDdEeFf0-9]{6})$/ regular expression';
  };

  return (
    <Root ref={rootRef}>
      {isOpen && (
        <StyledChromePicker
          disableAlpha
          color={color || ''}
          onChange={handlePickerChange}
        />
      )}
      <TextInput
        onClick={toggle}
        onChange={handleChange}
        validate={validateColorCode}
        fullWidth
        multiline
        {...props}
      />
    </Root>
  );
};

export default ColorInput;

const Root = styled.div`
  position: relative;
`;

const StyledChromePicker = styled(ChromePicker)`
  position: absolute;
  background-color: white;
  top: -4px;
  transform: translateY(-100%);

  > :last-child > :last-child {
    display: none !important;
  }
`;
