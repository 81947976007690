import { common, regex } from 'const';

export const validator = {
  id: (value: string) => {
    if (!value) return;
    if (!regex.uuid.test(value)) {
      return {
        message: `Incorrect id format. Example: ${common.idMask}`,
        args: {},
      };
    }
  },
  text: (value: string) => {
    if (!value) return;
    if (!value.match(regex.text)) {
      return {
        message: 'Use only latin letters, spaces, numbers, special characters',
        args: {},
      };
    }
  },
  capitalText: (value: string) => {
    if (!value) return;
    if (!value.match(regex.promoCode)) {
      return {
        message:
          'Use only capital letters, numbers, underscores and dashes are allowed in promo code',
        args: {},
      };
    }
  },
  textWithoutNumber: (value: string) => {
    if (!value) return;
    if (!value.match(regex.textWithoutNumber)) {
      return {
        message: 'Use only latin letters, special characters',
        args: {},
      };
    }
  },
  email: (value: string) => {
    if (!value) return;
    if (!regex.emailRegExp.test(value)) {
      return {
        message: 'Incorrect email format. Example: namesurname@email.com',
        args: {},
      };
    }
  },
  password: (value: string) => {
    if (!value) return;
    if (!regex.password.test(value)) {
      return {
        message:
          'Use only latin letters. Must contain at least one number, one uppercase letter and one symbol',
        args: {},
      };
    }
  },

  passwordByCustomer: (value: string) => {
    if (!value) return;
    const regex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,20}$/;

    if (!regex.test(value)) {
      return {
        message:
          'Use only latin letters. Must contain at least one number, one uppercase letter, and one symbol. Length should be 6-20 characters.',
        args: {},
      };
    }
  },

  fullName: (value: string) => {
    if (!value) return;
    if (!regex.fullName.test(value)) {
      return { message: 'Use only latin letters, hyphen', args: {} };
    }
  },
  notZero: (value: number) => {
    if (value === 0) {
      return {
        message: 'Must be a positive number.',
        args: {},
      };
    }
  },
  textRequired:
    (message = 'The name should not consist only of spaces') =>
    (value: string) =>
      !value?.trim() ? message : undefined,
  urlRequired:
    (message = 'Invalid url') =>
    (value: string) => {
      const urlPattern = regex.url;
      return !urlPattern.test(value) ? message : undefined;
    },
  idRequired:
    (message = 'Wrong ID format') =>
    (value: string) => {
      const uuidPattern = regex.idTemplate;
      return !uuidPattern.test(value) ? message : undefined;
    },
  sortIndex:
    (message = 'Sort index must be a positive number') =>
    (value: string) => {
      return Number(value) > 0 ? undefined : message;
    },
};
