import { AppConfig } from 'config';
import { regex } from 'const';
import crudProvider from './crud-provider';
import { fetchUtils } from 'react-admin';
import { CondOperator } from 'types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('accessToken');
  const replaceUrl = url
    .replaceAll(regex.arrayIndex, '')
    .replaceAll(regex.filterConditionContainsLow, CondOperator.CONTAINS_LOW);

  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(replaceUrl, options);
};

export default crudProvider(AppConfig.API_URL || '', httpClient);
