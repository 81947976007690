import { Button, required, SimpleForm, TextInput } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import Modal, { ModalProps } from '@mui/material/Modal';
import { FC, useState } from 'react';
import styled from 'styled-components';
import api from 'api';
import { StorageKeys, StorageService } from 'services';
import { useNavigate } from 'react-router-dom';
import { ErrorResponse, Resources } from 'types';
import { validator } from 'utils/validator';
import { useOnFetchError } from 'hooks';

const ForgotPasswordModal: FC<Omit<ModalProps, 'children'>> = ({
  onClose,
  ...props
}) => {
  const [isShowPasswordField, setIsShowPasswordField] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate = useNavigate();
  const { onError } = useOnFetchError();

  const resetPassword = async (values: FieldValues) => {
    setIsUpdating(true);

    try {
      const response =
        await api.AdminPanelAuth.adminAuthControllerRestorePassword({
          email: values.email,
        });
      if (response) {
        setIsShowPasswordField(true);
      }
    } catch (error) {
      onError(error as ErrorResponse);
    } finally {
      setIsUpdating(false);
    }
  };

  const updatePassword = async (values: FieldValues) => {
    const email = values.email;
    const password = values.password;

    setIsUpdating(true);

    try {
      const updateResponse =
        await api.AdminPanelAuth.adminAuthControllerUpdateForgottenPassword({
          email,
          password,
        });

      if (updateResponse) {
        StorageService.setItem(
          StorageKeys.ACCESS_TOKEN,
          updateResponse.data.token,
        );
        StorageService.setItem(
          StorageKeys.ROLE,
          updateResponse.data.admin.role,
        );
        navigate(`/#/${Resources.DIRECTIONS}`);
      }
    } catch (error) {
      onError(error as ErrorResponse);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <StyledModal
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      {...props}
    >
      <SimpleForm
        onSubmit={isShowPasswordField ? updatePassword : resetPassword}
      >
        <Container>
          <TextInput
            source="email"
            autoFocus
            label="email"
            validate={[required(), validator.email]}
            fullWidth
          />
          {isShowPasswordField && (
            <TextInput
              source="password"
              label="Password from email"
              validate={required()}
              fullWidth
              multiline
            />
          )}
          <StyledButton
            variant="contained"
            label="Confirm"
            type="submit"
            disabled={isUpdating}
          />
        </Container>
      </SimpleForm>
    </StyledModal>
  );
};

export default ForgotPasswordModal;

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiToolbar-root {
    display: none;
  }
`;

const Container = styled.div`
  background-color: lightgray;
  max-width: 500px;
  max-height: 400px;
  padding: 40px 50px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;
