/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CondOperator,
  QueryFilter,
  QuerySort,
  RequestQueryBuilder,
} from '@nestjsx/crud-request';
import omitBy from 'lodash.omitby';
import { DataProvider, fetchUtils } from 'ra-core';
import { stringify } from 'query-string';
import { Resources } from 'types';

const countDiff = (
  o1: Record<string, any>,
  o2: Record<string, any>,
): Record<string, any> => omitBy(o1, (v, k) => o2[k] === v);

const composeFilter = (paramsFilter: any): QueryFilter[] => {
  const flatFilter = fetchUtils.flattenObject(paramsFilter);

  return Object.keys(flatFilter).map((key) => {
    const splitKey = key.split('||');
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/gi;
    const dataRegex = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/gi;

    let field = splitKey[0];
    let ops = splitKey[1];
    if (!ops) {
      if (flatFilter[key] === CondOperator.IS_NULL) {
        flatFilter[key] = '';
        ops = CondOperator.IS_NULL;
      } else if (flatFilter[key] === CondOperator.NOT_NULL) {
        flatFilter[key] = '';
        ops = CondOperator.NOT_NULL;
      } else if (flatFilter[key]?.match(dataRegex)) {
        const value = flatFilter[key];

        const splittedDate = value.split('-');
        splittedDate[splittedDate.length - 1] =
          Number(splittedDate[splittedDate.length - 1]) + 1;
        const nextDate = splittedDate.join('-');

        const betweenDate = `${flatFilter[key]},${nextDate}`;

        ops = CondOperator.BETWEEN;
        flatFilter[key] = betweenDate;
      } else if (
        typeof flatFilter[key] === 'boolean' ||
        typeof flatFilter[key] === 'number' ||
        flatFilter[key].match(uuidRegex)
      ) {
        ops = CondOperator.EQUALS;
      } else {
        ops = CondOperator.CONTAINS;
      }
    }

    if (field.startsWith('_') && field.includes('.')) {
      field = field.split(/\.(.+)/)[1];
    }
    return { field, operator: ops, value: flatFilter[key] } as QueryFilter;
  });
};

const composeQueryParams = (queryParams: any = {}): string => {
  return stringify(fetchUtils.flattenObject(queryParams), { skipNull: true });
};

const mergeEncodedQueries = (...encodedQueries: string[]) =>
  encodedQueries.map((query) => query).join('&');

export default (
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
): DataProvider => ({
  getList: (resource, params) => {
    const { meta } = params;
    const { page, perPage } = params.pagination;
    const { q: queryParams, $OR: orFilter, ...filter } = params.filter || {};

    const encodedQueryMeta = composeQueryParams(meta);
    const encodedQueryParams = composeQueryParams(queryParams);
    const encodedQueryFilter = RequestQueryBuilder.create({
      filter: composeFilter(filter),
      or: composeFilter(orFilter || {}),
    })
      .setLimit(perPage)
      .setPage(page)
      .sortBy(params.sort as QuerySort)
      .setOffset((page - 1) * perPage)
      .query();

    const query = mergeEncodedQueries(
      encodedQueryParams,
      encodedQueryFilter,
      encodedQueryMeta,
    );

    const url = `${apiUrl}/${resource}?${query}`;

    return httpClient(url).then(({ json }) => {
      if (json.meta) {
        const pagination = {
          page: json.meta.currentPage,
          pageCount: json.meta.totalPages,
          count: json.meta.itemCount,
          perPage: json.meta.itemsPerPage,
          total: json.meta.totalItems,
        };
        const data = {
          ...pagination,
          data: json.items,
        };

        return {
          data: data.data,
          total: data.total,
        };
      }

      return {
        data: json.data,
        total: json.total,
      };
    });
  },

  getOne: (resource, params) => {
    const { meta } = params;
    const encodedQueryMeta = composeQueryParams(meta);
    const query = mergeEncodedQueries(encodedQueryMeta);

    return httpClient(`${apiUrl}/${resource}/${params.id}?${query}`).then(
      ({ json }) => ({
        data: json,
      }),
    );
  },

  getMany: (resource, params) => {
    let requestResource = resource;

    if (resource === Resources.SEARCH_COUPONS && params.ids.length) {
      requestResource = Resources.COUPONS;
    }

    const query = RequestQueryBuilder.create()
      .setFilter({
        field: 'id',
        operator: CondOperator.IN,
        value: `${params.ids}`,
      })
      .query();

    const url = `${apiUrl}/${requestResource}?${query}`;

    return httpClient(url).then(({ json }) => ({ data: json.data || json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { q: queryParams, ...otherFilters } = params.filter || {};
    const filter: QueryFilter[] = composeFilter(otherFilters);

    filter.push({
      field: params.target,
      operator: CondOperator.EQUALS,
      value: params.id,
    });

    const encodedQueryParams = composeQueryParams(queryParams);
    const encodedQueryFilter = RequestQueryBuilder.create({
      filter,
    })
      .sortBy(params.sort as QuerySort)
      .setLimit(perPage)
      .setOffset((page - 1) * perPage)
      .query();

    const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);

    const url = `${apiUrl}/${resource}?${query}`;

    return httpClient(url).then(({ json }) => ({
      data: json.data,
      total: json.total,
    }));
  },

  update: (resource, params) => {
    const method = params.data.method || 'PATCH';
    // no need to send all fields, only updated fields are enough
    const data = countDiff(params.data, params.previousData);
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method,
      body: JSON.stringify(data),
    }).then(({ json }) => ({ data: json }));
  },

  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        }),
      ),
    ).then((responses) => ({
      data: responses.map(({ json }) => json),
    })),

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  create: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    })
      .then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }))
      .then(({ data }) => {
        if (data?.error) {
          return data.error;
        } else {
          return {
            data,
          };
        }
      });
  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: { ...json, id: params.id } })),

  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
        }),
      ),
    ).then((responses) => ({ data: responses.map(({ json }) => json) })),
});
