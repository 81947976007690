import { NestedResourcesContext } from 'context/NestedResources';
import { useContext } from 'react';
import {
  Datagrid,
  DateField,
  List,
  ListProps,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin';
import { useUploadQuestions } from 'hooks';
import { ChapterSortIndexButton, ListActionsWithImport } from 'components';
import styled from 'styled-components';

const Filters = [<TextInput key="name" source="name" alwaysOn />];

const TestChaptersList = (props: ListProps) => {
  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};
  const { createTest } = useUploadQuestions(courseId);

  return (
    <StyledList
      disableSyncWithLocation
      title="Test chapters"
      sort={{ field: 'sortIndex', order: 'ASC' }}
      filters={Filters}
      bulkActionButtons={false}
      actions={<ListActionsWithImport uploadFile={createTest} />}
      filter={{ courseId }}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
      {...props}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="sortIndex" sortable={false} />
        <ChapterSortIndexButton sortIndexVariant="increase" />
        <ChapterSortIndexButton sortIndexVariant="decrease" />
        <TextField
          source="title"
          sx={{
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word',
            wordBreak: 'break-word',
            flexWrap: 'wrap',
          }}
        />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </StyledList>
  );
};

export default TestChaptersList;

const StyledList = styled(List)`
  .MuiTableCell-head:nth-child(1) {
    width: 80px;
    white-space: nowrap;
  }

  .MuiTableCell-head:nth-child(2),
  .MuiTableCell-head:nth-child(3) {
    width: 40px;
  }
`;
