import React, { FC } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, ButtonProps } from '@mui/material';
import styled from 'styled-components';

type DeleteButtonProps = {
  text?: string;
  small?: boolean;
} & ButtonProps;

const DeleteButton: FC<DeleteButtonProps> = ({
  text = 'Delete',
  small = false,
  ...props
}) => {
  return (
    <StyledButton
      variant="text"
      startIcon={<DeleteIcon />}
      size={small ? 'small' : undefined}
      {...props}
    >
      {text}
    </StyledButton>
  );
};

export default DeleteButton;

const StyledButton = styled(Button)`
  color: #eb4040 !important;
`;
