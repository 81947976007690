import React, { ChangeEvent, FC, useState } from 'react';
import { useRefresh } from 'react-admin';
import styled from 'styled-components';
import PublishIcon from '@mui/icons-material/Publish';
import { AdminSignedUrlDto } from 'api/generated';
import { FileCrudService } from 'services';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppNotify } from 'hooks';

export type ImportButtonProps = {
  uploadFile?: (file: File) => Promise<void>;
  onUploadFileToStorage?: (url: string) => Promise<void>;
  uploadFileToStorage?: (body: {
    filename: string;
  }) => Promise<{ data: AdminSignedUrlDto }>;
  text?: string;
  accept?: string;
  id?: string;
};

const ImportButton: FC<ImportButtonProps> = ({
  uploadFile,
  uploadFileToStorage,
  onUploadFileToStorage,
  text = 'Import',
  accept = '.csv',
  id = 'csv-input',
}) => {
  const refresh = useRefresh();
  const { errorNotify } = useAppNotify();
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      setIsLoading(true);
      try {
        if (uploadFileToStorage) {
          await uploadToStorage(file);
        } else {
          await uploadFile?.(file);
        }
      } catch (err) {
        errorNotify(err);
      } finally {
        refresh();
        setIsLoading(false);

        /**
         * reset the value to be able to re-upload the same file
         * */
        e.target.value = '';
      }
    }
  };

  const uploadToStorage = async (file: File) => {
    if (file.name && uploadFileToStorage) {
      try {
        const response = await uploadFileToStorage({
          filename: file.name,
        });
        const { signedUrl, accessUrl } = response.data;

        await FileCrudService.put({
          url: signedUrl,
          file,
        });

        await onUploadFileToStorage?.(accessUrl);
      } catch (err) {
        errorNotify(err);
      }
    }
  };

  return (
    <>
      <Label htmlFor={id}>
        <IconContainer>
          {isLoading ? <CircularProgress size={18} /> : <PublishIcon />}
        </IconContainer>
        {text}
      </Label>
      <HiddenInput
        id={id}
        type="file"
        accept={accept}
        disabled={isLoading}
        onChange={(e) => handleChange(e)}
      />
    </>
  );
};

export default ImportButton;

const HiddenInput = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 0.8125rem;
  text-transform: uppercase;
  min-width: 64px;
  padding: 4px 5px;
  border-radius: 4px;
  color: ${(props) => props.theme.palette?.primary?.main};

  &:hover {
    cursor: pointer;
    text-decoration: none;
    background-color: ${(props) => props.theme.palette?.primary?.main + '0a'};
  }
`;

const IconContainer = styled.div`
  margin-right: 8px;
  margin-left: -2px;
  width: 18px;
  height: 18px;

  svg {
    width: 18px;
    height: 18px;
  }
`;
