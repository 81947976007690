import { DateField, Show, Tab, TabbedShowLayout, TextField } from 'react-admin';
import { ShowActions } from 'components';
import { DeletionType } from 'components/ShowActions/ShowActions';

const TestChaptersShow = () => {
  return (
    <Show
      title="Test chapter"
      actions={<ShowActions type={DeletionType.chapter} />}
    >
      <TabbedShowLayout>
        <Tab label="About chapter">
          <TextField source="id" />
          <TextField source="sortIndex" />
          <TextField
            source="title"
            sx={{
              whiteSpace: 'pre-wrap',
              overflowWrap: 'break-word',
              wordBreak: 'break-word',
              flexWrap: 'wrap',
            }}
          />
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default TestChaptersShow;
