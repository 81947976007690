import api from 'api';
import { AdminUpdateManualDto } from 'api/generated';
import { FC, memo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { Editor } from 'services';
import styled from 'styled-components';

type ManualContentProps = {
  content?: AdminUpdateManualDto;
  courseId: string;
};

const ManualContent: FC<ManualContentProps> = ({
  content = undefined,
  courseId,
}) => {
  const editorRef = useRef(null);

  const { setValue } = useFormContext();

  return (
    <StyledEditor
      uploadImage={async (data) =>
        //todo change upload image request
        await api.AdminPanelGuides.adminGuidesControllerUploadImage({
          ...data,
          courseId,
        })
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      defaultData={content?.content}
      label="Content"
      holder="manual"
      onSave={(values) => {
        setValue(`content`, values, { shouldDirty: true, shouldTouch: true });
      }}
      editorRef={editorRef}
    />
  );
};

export default memo(ManualContent);

const StyledEditor = styled(Editor)`
  margin-bottom: 40px;
`;
