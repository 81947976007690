import { useAppNotify, useOnFetchError } from 'hooks';
import React, { FC, MouseEventHandler } from 'react';
import { useRecordContext, useRefresh } from 'react-admin';
import { ErrorResponse, SortIndexVariant } from 'types';
import ArrowDownIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpIcon from '@mui/icons-material/ArrowUpward';
import { Button } from '@mui/material';
import styled from 'styled-components';
import api from 'api';

type SortIndexButtonProps = {
  sortIndexVariant: SortIndexVariant;
};

const DomainSortIndexButton: FC<SortIndexButtonProps> = ({
  sortIndexVariant,
}) => {
  const refresh = useRefresh();
  const { onError } = useOnFetchError();
  const { successNotify, errorNotify } = useAppNotify();
  const record = useRecordContext();
  const isIncrease = sortIndexVariant === 'increase';

  const onIndexIncrease: MouseEventHandler = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();

      await api.AdminPanelDomains.updateOneBaseAdminQuestionDomainsControllerQuestionDomain(
        String(record.id),
        {
          sortIndex: record.sortIndex + 1,
        },
      );

      successNotify(`SortIndex increased to ${record.sortIndex + 1}`);
      refresh();
    } catch (error) {
      onError(error as ErrorResponse);
    }
  };

  const onIndexDecrease: MouseEventHandler = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();

      if (record.sortIndex < 2) {
        errorNotify('SortIndex cannot be lower than 0');
      } else {
        await api.AdminPanelDomains.updateOneBaseAdminQuestionDomainsControllerQuestionDomain(
          String(record.id),
          {
            sortIndex: record.sortIndex - 1,
          },
        );

        successNotify(`SortIndex decreased to ${record.sortIndex - 1}`);
      }

      refresh();
    } catch (error) {
      onError(error as ErrorResponse);
    }
  };

  if (record.sortIndex === null) {
    return null;
  }

  return (
    <StyledButton
      variant="text"
      startIcon={isIncrease ? <ArrowDownIcon /> : <ArrowUpIcon />}
      onClick={isIncrease ? onIndexIncrease : onIndexDecrease}
    />
  );
};

export default DomainSortIndexButton;

const StyledButton = styled(Button)`
  width: 40px !important;
  min-width: 40px !important;

  & > span {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
`;
