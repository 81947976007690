export enum Resources {
  CUSTOMERS = 'admin/customers',
  DIRECTIONS = 'admin/directions',
  COURSES = 'admin/course',
  MNEMONIC_CARDS = 'admin/mnemonicCards',
  MNEMONIC_CARDS_PACKS = 'admin/mnemonicCards/packs',
  FLASHCARDS = 'admin/flashcards',
  FLASHCARDS_PACKS = 'admin/flashcards/packs',
  STUDY_PLANS = 'admin/plans',
  STUDY_PLANS_PHASES = 'admin/plans/phases',
  STUDY_PLANS_TASKS = 'admin/tasks',
  STUDY_PLANS_PHASES_TASKS = 'admin/phase-tasks',
  REPORTS = 'admin/reports',
  STUDY_GUIDES = 'admin/studyGuide',
  GUIDES = 'admin/guides',
  CHAPTERS = 'admin/chapter',
  TESTS_TIMED = 'admin/timed-tests',
  TEST_TOPICS = 'admin/question-domains',
  TEST_CHAPTERS = 'admin/question-chapters',
  TEST_QUESTIONS = 'admin/questions',
  TESTS_QUIZ = 'admin/quizzes',
  ADMINS = 'admins',
  INVITES = 'admin/customer-invites',
  MANUALS = 'admin/courses',
  SUGGESTIONS = 'admin/suggestions',
  COUPONS = 'admin/coupons',
  SEARCH_COUPONS = 'admin/coupons/search-by-course',
}
