import { Switch } from '@mui/material';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Inputs } from './components';

type SubscriptionsInputsProps = {
  isActive: boolean;
  isEdit?: boolean;
  setIsActive: (value: boolean) => void;
  subscriptionsTerm: string;
};

const SubscriptionsInputs: FC<SubscriptionsInputsProps> = ({
  subscriptionsTerm,
  setIsActive,
  isActive,
  isEdit = false,
}) => {
  const context = useFormContext();
  const isPrices =
    !!context.getValues()?.prices?.[subscriptionsTerm]?.bronze?.amount;

  useEffect(() => {
    if (isPrices && isEdit && !isActive) {
      setIsActive(true);
    }
  }, [isPrices]);

  const enable = () => setIsActive(true);
  const disable = () => setIsActive(false);

  return (
    <>
      <h3>Enable</h3>
      <Switch
        checked={isActive}
        defaultValue=""
        onChange={isActive ? disable : enable}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <h3>Bronze</h3>
      <Inputs
        disabled={!isActive}
        isActive={isActive}
        subscriptionsTerm={subscriptionsTerm}
        type="bronze"
      />
      <h3>Silver</h3>
      <Inputs
        disabled={!isActive}
        isActive={isActive}
        subscriptionsTerm={subscriptionsTerm}
        type="silver"
      />
      <h3>Gold</h3>
      <Inputs
        disabled={!isActive}
        isActive={isActive}
        subscriptionsTerm={subscriptionsTerm}
        type="gold"
      />
    </>
  );
};

export default SubscriptionsInputs;
