import { ImagesArrayField, ShowActions, StyledImageField } from 'components';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import { DeletionType } from 'components/ShowActions/ShowActions';

const TestQuestionsShow = () => {
  return (
    <Show
      actions={<ShowActions type={DeletionType.question} />}
      title="Question"
      queryOptions={{
        meta: {
          join: ['domain', 'chapter'],
        },
      }}
    >
      <TabbedShowLayout>
        <Tab label="About questions">
          <TextField source="id" />
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
          <TextField
            label="Question"
            source="subject"
            sx={{
              whiteSpace: 'pre-wrap',
              overflowWrap: 'break-word',
              wordBreak: 'break-word',
              flexWrap: 'wrap',
            }}
          />
          <TextField label="Hint" source="hint" />
          <ImagesArrayField source="imagesUrls" filePreviewSize={150} />
          <BooleanField source="availableForFree" />
          <StyledImageField
            source="explanationImageUrl"
            label="Explanation image"
            $size={150}
          />
          <TextField
            source="explanationText"
            sx={{
              whiteSpace: 'pre-wrap',
              overflowWrap: 'break-word',
              wordBreak: 'break-word',
              flexWrap: 'wrap',
            }}
          />
          <TextField label="Domain" source="domain.title" />
          <TextField label="Chapter" source="chapter.title" />
          <ArrayField source="options">
            <Datagrid bulkActionButtons={false}>
              <TextField
                source="title"
                sx={{
                  whiteSpace: 'pre-wrap',
                  overflowWrap: 'break-word',
                  wordBreak: 'break-word',
                  flexWrap: 'wrap',
                }}
              />
              <BooleanField source="isCorrect" />
              <DateField source="createdAt" />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default TestQuestionsShow;
