import { useNotify } from 'react-admin';
import { Notify } from 'utils/norifyVO';

const useAppNotify = () => {
  const notify = useNotify();
  const { success, error, warning } = Notify;

  const successNotify = (msg: string) => {
    const { message, type } = success(msg);

    notify(message, { type });
  };

  const errorNotify = (err: unknown) => {
    const { message, type } = error(err);

    notify(message, { type });
  };

  const warningNotify = (msg: string) => {
    const { message, type } = warning(msg);

    notify(message, { type });
  };

  return {
    successNotify,
    errorNotify,
    warningNotify,
  };
};

export default useAppNotify;
