import api from 'api';
import { ImageInput } from 'components';
import { NestedResourcesContext } from 'context/NestedResources';
import { useContext } from 'react';
import {
  Edit,
  maxLength,
  NumberInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { validator } from 'utils/validator';

const MnemonicCardsEdit = () => {
  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};

  return (
    <Edit redirect="show" title="Mnemonic card edit">
      <SimpleForm>
        <ImageInput
          uploadImage={async ({ filename }) =>
            await api.AdminPanelMnemonicCards.adminMnemonicCardsControllerUploadImage(
              { filename, courseId },
            )
          }
          isRequired
          source="imageUrl"
        />
        <TextInput
          validate={[validator.textRequired(), maxLength(500)]}
          source="title"
          fullWidth
          multiline
        />
        <NumberInput validate={[required()]} source="answersCount" fullWidth />
      </SimpleForm>
    </Edit>
  );
};

export default MnemonicCardsEdit;
