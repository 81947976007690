import { FC } from 'react';
import { ToolbarProps, Toolbar, SaveButton } from 'react-admin';

const CourseEditToolbar: FC<ToolbarProps> = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export default CourseEditToolbar;
