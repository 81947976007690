export const regex = {
  arrayIndex: /%5B\d%5D/g,
  filterConditionContainsLow: /%24cont/g,
  uuid: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/gi,
  hex: /^#([AaBbCcDdEeFf0-9]{6})$/,
  text: /^[\s\S]*$/,
  textWithoutNumber:
    /^([a-zA-Z!@#$?!:'",.`%\\^&*)(+=._-]+\s)*[a-zA-Z!@#$?!:'",.`%\\^&*)(+=._-]+$/gm,
  textWithNumbers: /^[a-zA-Z0-9\d!@#$?!:'",.`%\\^&*\s)(+=._-]*$/,
  emailRegExp:
    /^(([^А-Яа-яЕеЁё\\|/?*#~`<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password:
    /^(?=.*\d)(?=.*[A-Z])(?=.*[-+_(){}[\]`~!@#$№%^&*.,?])[A-Za-z0-9-_(){}[\]`~!@#$№%^&*.,?]*$/,
  fullName: /^[A-Za-z]+(?:[-|\s][A-Za-z]+)*$/,
  idTemplate: /^d-[0-9a-fA-F]{32}$/,
  url: /^(https?:\/\/)/,
  promoCode: /^[A-Z0-9_-]+$/,
};
