import { Show, Tab, TabbedShowLayout, TextField } from 'react-admin';

const StudyPlansTaskShow = () => {
  return (
    <Show title="Task">
      <TabbedShowLayout>
        <Tab label="Study plan phase">
          <TextField source="id" />
          <TextField source="name" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default StudyPlansTaskShow;
