import {
  DeleteWithConfirmButton,
  EditButton,
  TopToolbar,
  useRecordContext,
} from 'react-admin';

interface Record extends Object {
  id: string;
  subject?: string;
  title?: string;
}

export enum DeletionType {
  question = 'question',
  domain = 'domain',
  chapter = 'chapter',
}

interface ShowActionsProps {
  type?: DeletionType | string;
  personalTitle?: string;
  customerId?: string | undefined | null;
}

const ShowActions = ({
  type = '',
  personalTitle,
  customerId,
}: ShowActionsProps) => {
  const record = useRecordContext<Record>();
  const title = record?.subject || record?.title;
  const name = title ? `"${title}"` : 'this item';

  return (
    <TopToolbar>
      <EditButton />
      {record && record.id && (
        <DeleteWithConfirmButton
          record={record}
          confirmTitle={
            personalTitle ? personalTitle : `Delete ${type} ${name}?`
          }
          confirmContent={
            personalTitle
              ? `Copy Customer Stripe ID: ${customerId} to give a refund after account was deleted`
              : 'It cannot be returned'
          }
        />
      )}
    </TopToolbar>
  );
};

export default ShowActions;
