import api from 'api';
import { Guide } from 'api/generated';
import { NestedResourcesContext } from 'context/NestedResources';
import { useContext, useEffect, useState } from 'react';
import {
  CreateButton,
  Datagrid,
  Identifier,
  List,
  ListProps,
  Loading,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ErrorType, Resources } from 'types';
import { Actions } from './components';
import { useAppNotify } from 'hooks';

const Filters = [<TextInput key="name" source="name" alwaysOn />];

const StudyGuide = (props: ListProps) => {
  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};

  const navigate = useNavigate();
  const { errorNotify } = useAppNotify();

  const [isLoading, setIsLoading] = useState(false);
  const [studyGuide, setStudyGuide] = useState<Guide | null>(null);

  const getCourseStudyGuide = async () => {
    try {
      setIsLoading(true);
      const response =
        await api.AdminPanelGuides.adminGuidesControllerGetGuideByCourseId(
          courseId,
        );

      setStudyGuide(response.data as unknown as Guide);
    } catch (err) {
      const error = err as ErrorType;

      if (error?.response?.status !== 404) {
        errorNotify(error?.message || 'Something went wrong');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCourseStudyGuide();
  }, []);

  const selectChapter = (id: Identifier, _resource: string) => {
    const url = `/${Resources.STUDY_GUIDES}/${id}/edit?studyGuideId=${studyGuide?.id}`;
    return url;
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!studyGuide?.chapters?.length) {
    return (
      <Root>
        <p>There are not chapters in this study guide</p>
        <CreateButton
          onClick={() => navigate(`${Resources.STUDY_GUIDES}/create`)}
          label="Create first chapter"
        />
      </Root>
    );
  }

  return (
    <List
      disableSyncWithLocation
      bulkActionButtons={false}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
      {...props}
      actions={
        <Actions
          createResource={`${Resources.STUDY_GUIDES}/create?studyGuideId=${studyGuide?.id}&`}
          setStudyGuide={setStudyGuide}
          cheatSheetUrl={studyGuide.cheatSheetUrl || ''}
          textbookUrl={studyGuide?.textbookUrl || ''}
          guideId={studyGuide.id}
          courseId={courseId}
        />
      }
      filters={Filters}
      sort={{ field: 'orderIndex', order: 'DESC' }}
      title="Chapters"
      resource={`${Resources.GUIDES}/${studyGuide?.id}/chapters`}
    >
      <Datagrid rowClick={selectChapter} bulkActionButtons={false}>
        <TextField
          sortable={false}
          label="Chapter title"
          source="name"
          sx={{
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word',
            wordBreak: 'break-word',
            flexWrap: 'wrap',
          }}
        />
        <TextField
          sortable={false}
          label="Chapter order index"
          source="orderIndex"
        />
      </Datagrid>
    </List>
  );
};

export default StudyGuide;

const Root = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 24px;
`;
