import { Direction } from 'api/generated';
import React, { FC } from 'react';
import { TextFieldProps, useRecordContext } from 'react-admin';
import styled from 'styled-components';

type ColorFieldProps = {
  old?: boolean;
} & TextFieldProps;

const ColorField: FC<ColorFieldProps> = ({ old }) => {
  const record = useRecordContext<Direction>();
  const { mainColorHex, oldColorHex } = record;
  const colorHex = old ? oldColorHex : mainColorHex;

  return (
    <Root>
      <Color $color={colorHex} />
      {colorHex}
    </Root>
  );
};

export default ColorField;

const Root = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 8px;
`;

const Color = styled.div<{ $color: string }>`
  width: 4px;
  height: 50px;

  border-radius: 4px;
  background-color: ${({ $color }) => $color};
`;
