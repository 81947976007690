import InboxIcon from '@mui/icons-material/Inbox';
import { ImportButton } from 'components';
import { CreateButton } from 'react-admin';
import styled from 'styled-components';

const EmptyListActions = ({ ...props }) => {
  return (
    <Root>
      <InboxIcon color="action" sx={{ height: 216, width: 216 }} />
      <Title>No Test domains yet.</Title>
      <Text>Do you want to add one?</Text>
      <ButtonContainer>
        <CreateButton />
        <ImportButton {...props} />
      </ButtonContainer>
    </Root>
  );
};

export default EmptyListActions;

const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const Title = styled.h1`
  color: gray;
`;

const Text = styled.p`
  color: gray;
`;
