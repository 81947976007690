import {
  ImageInput as DefaultImageInput,
  ImageInputProps as DefaultImageInputProps,
  ImageField,
  useRecordContext,
  IconButtonWithTooltip,
} from 'react-admin';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { FileCrudService } from 'services';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import Undo from '@mui/icons-material/Undo';

import File, { FileEnum } from 'components/ImageInput/components/File/File';
import { RemoveButton } from './components';
import CircularProgress from '@mui/material/CircularProgress';
import { AdminSignedUrlDto, AdminSignedUrlResponseDto } from 'api/generated';

const IMAGE_INPUT = 'image-input';

type ImageInputProps = {
  isRequired?: boolean;
  fileType?: FileEnum;
  filePreviewSize?: number;
  uploadImage: (body: { filename: string }) => Promise<{
    data: AdminSignedUrlDto | AdminSignedUrlResponseDto;
  }>;
} & DefaultImageInputProps;

const ImageInput = ({
  source,
  isRequired,
  fileType = FileEnum.IMAGE,
  uploadImage,
  filePreviewSize = 150,
  ...props
}: ImageInputProps) => {
  const [uploadedFiles, setUploadedFiles] = useState<Array<string>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const record = useRecordContext();
  const { setValue, getValues, watch, setError, clearErrors } =
    useFormContext();
  const initialValues = useMemo(() => getValues(), []);

  const { [source]: initialFileKey } = record || initialValues || {};
  const filesKeys: Array<string> = watch(source) || [];

  const checkIsDirtyFields = useCallback((filesKeys: Array<string>) => {
    let isDirtyFields = false;

    for (let i = 0; i <= filesKeys?.length; i++) {
      if (filesKeys?.[i] !== initialFileKey?.[i]) {
        isDirtyFields = true;
        break;
      }
    }

    return isDirtyFields;
  }, []);

  const getIsDirtyFields = useCallback((filesKeys: Array<string>) => {
    if (!filesKeys && !initialFileKey) {
      return false;
    }
    return checkIsDirtyFields(filesKeys);
  }, []);

  const isShowReset = useMemo(() => getIsDirtyFields(filesKeys), [filesKeys]);

  const handleChange = async (file: File | null) => {
    if (file) {
      uploadFile(file);
    }
  };

  const uploadFile = async (file: File) => {
    if (file.name) {
      try {
        setIsLoading(true);

        const response = await uploadImage({
          filename: file.name,
        });

        const { signedUrl, accessUrl } = response.data;

        setUploadedFiles((prev) => [...prev, URL.createObjectURL(file)]);

        await FileCrudService.put({ url: signedUrl, file });

        setValue(source, [...filesKeys, accessUrl]);
      } catch {
        resetImage();
      } finally {
        setIsLoading(false);
      }
    }
  };

  const resetImage = () => {
    setValue(source, initialFileKey);
    setValue(IMAGE_INPUT, undefined);
    setUploadedFiles(initialFileKey);
  };

  const removeImage = (images: Array<string>, imageIndex: number) => {
    const newImages = images.filter((_, index) => index !== imageIndex);
    setValue(source, newImages, { shouldDirty: true, shouldTouch: true });
    setValue(IMAGE_INPUT, newImages);
    setUploadedFiles(newImages);
  };

  const onLoad = () => {
    if (isLoading) {
      setError(source, { message: 'loading' });
    } else {
      clearErrors();
    }
  };

  useEffect(onLoad, [isLoading]);

  return (
    <Root>
      <DefaultImageInput
        onChange={handleChange}
        accept="image/*"
        options={{ disabled: isLoading }}
        {...props}
        source={IMAGE_INPUT}
      >
        <HiddenImageField source="src" title="title" />
      </DefaultImageInput>
      <Container>
        {uploadedFiles.length
          ? uploadedFiles.map((uploadedFile, index) => (
              <Fragment key={index}>
                <File
                  size={filePreviewSize}
                  type={fileType}
                  value={uploadedFile}
                />
                <RemoveButton
                  filePreviewSize={filePreviewSize}
                  removeImage={() => removeImage(uploadedFiles, index)}
                />
              </Fragment>
            ))
          : Boolean(filesKeys.length) &&
            filesKeys.map((fileKey, index) => (
              <Fragment key={index}>
                <File size={filePreviewSize} type={fileType} value={fileKey} />
                <RemoveButton
                  filePreviewSize={filePreviewSize}
                  removeImage={() => removeImage(filesKeys, index)}
                />
              </Fragment>
            ))}
        {isShowReset && (
          <IconButtonWithTooltip
            onClick={resetImage}
            type="button"
            label="reset"
          >
            <Undo />
          </IconButtonWithTooltip>
        )}
        {isLoading && (
          <LoaderContainer>
            <CircularProgress />
          </LoaderContainer>
        )}
      </Container>
    </Root>
  );
};

export default ImageInput;

const Root = styled.div`
  .previews {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const HiddenImageField = styled(ImageField)`
  width: 0;
  height: 0;
  opacity: 0;
  * {
    width: 0;
    height: 0;
  }
`;

const LoaderContainer = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
