import { StyledImageField } from 'components';
import { NestedResourcesContext } from 'context/NestedResources';
import { useContext } from 'react';
import {
  Datagrid,
  List,
  ListProps,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin';

const Filters = [
  <TextInput key="question" source="question" alwaysOn />,
  <TextInput key="answer" source="answer" alwaysOn />,
];

const FlashcardsList = (props: ListProps) => {
  const { urlVariables } = useContext(NestedResourcesContext);
  const { flashcardsPack: packId = '' } = urlVariables || {};

  return (
    <List
      disableSyncWithLocation
      filters={Filters}
      bulkActionButtons={false}
      filter={{ packId }}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
      {...props}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <StyledImageField
          sortable={false}
          label="Question image"
          source="questionImageUrl"
        />
        <TextField source="question" />
        <StyledImageField
          sortable={false}
          label="Answer image"
          source="answerImageUrl"
        />
        <TextField source="answer" />
      </Datagrid>
    </List>
  );
};

export default FlashcardsList;
