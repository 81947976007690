export * from './resources';

export type ErrorResponse = {
  body: {
    error: string;
    message: string[] | string;
    statusCode: number;
  };
  name: string;
  status: number;
};

export type ErrorType = {
  message: string;
  response: {
    status: number;
    data: {
      message: Array<string>;
    };
  };
};

export enum UrlVariablesEnum {
  COURSE = 'course',
  CAREER = 'career',
  FLASHCARDS_PACK = 'flashcardsPack',
  MNEMONIC_CARDS_PACK = 'mnemonicCardsPack',
  CHAPTER = 'chapter',
  TOPIC = 'topic',
  COUPON = 'coupon',
}

export type UrlVariables = {
  [UrlVariablesEnum.COURSE]?: string;
  [UrlVariablesEnum.CAREER]?: string;
  [UrlVariablesEnum.FLASHCARDS_PACK]?: string;
  [UrlVariablesEnum.MNEMONIC_CARDS_PACK]?: string;
  [UrlVariablesEnum.CHAPTER]?: string;
  [UrlVariablesEnum.TOPIC]?: string;
};

export enum CondOperator {
  EQUALS = '$eq',
  NOT_EQUALS = '$ne',
  GREATER_THAN = '$gt',
  LOWER_THAN = '$lt',
  GREATER_THAN_EQUALS = '$gte',
  LOWER_THAN_EQUALS = '$lte',
  STARTS = '$starts',
  ENDS = '$ends',
  CONTAINS = '$cont',
  EXCLUDES = '$excl',
  IN = '$in',
  NOT_IN = '$notin',
  IS_NULL = '$isnull',
  NOT_NULL = '$notnull',
  BETWEEN = '$between',
  EQUALS_LOW = '$eqL',
  NOT_EQUALS_LOW = '$neL',
  STARTS_LOW = '$startsL',
  ENDS_LOW = '$endsL',
  CONTAINS_LOW = '$contL',
  EXCLUDES_LOW = '$exclL',
  IN_LOW = '$inL',
  NOT_IN_LOW = '$notinL',
}

export type SortIndexVariant = 'increase' | 'decrease';
