import { CourseActions, PurchaseFields, SubscriptionsFields } from 'components';
import { DateField, Show, Tab, TabbedShowLayout, TextField } from 'react-admin';

const CourseShow = () => {
  return (
    <Show title="Course" actions={<CourseActions />}>
      <TabbedShowLayout>
        <Tab label="About course">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="description" />
          <TextField source="leastKnownCategory" />
          <TextField source="assignmentsWeight" />
          <TextField source="flashcardsWeight" />
          <TextField source="testTopicsWeight" />
          <TextField source="domainButtonName" label="Domain button name" />
          <TextField
            source="domainStatsButtonName"
            label="Domain status button name"
          />
          <TextField source="chapterButtonName" label="Chapter button name" />
          <TextField
            source="chapterStatsButtonName"
            label="Chapter status button name"
          />
          <DateField source="releasedAt" />
          <DateField source="createdAt" />
          <TextField source="activeCampaignListId" />
        </Tab>
        <Tab label="purchase">
          <PurchaseFields />
        </Tab>
        <Tab label="Monthly subscriptions">
          <SubscriptionsFields subscriptionsTerm="monthlySubscriptions" />
        </Tab>
        <Tab label="Yearly subscriptions">
          <SubscriptionsFields subscriptionsTerm="annualSubscriptions" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default CourseShow;
