import {
  Datagrid,
  DateField,
  List,
  ListProps,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin';

import { OpenButton } from 'components';
import { NestedResourcesContext } from 'context/NestedResources';
import { useContext } from 'react';
import { UrlVariablesEnum } from 'types';

const Filters = [<TextInput key="name" source="name" alwaysOn />];

const CoursesList = (props: ListProps) => {
  const { urlVariables } = useContext(NestedResourcesContext);
  const { career: directionId } = urlVariables || {};

  return (
    <List
      disableSyncWithLocation
      filter={{ directionId }}
      filters={Filters}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
      {...props}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="name" />
        <DateField source="createdAt" />
        <DateField source="releasedAt" />
        <OpenButton label="Open course" variable={UrlVariablesEnum.COURSE} />
      </Datagrid>
    </List>
  );
};

export default CoursesList;
