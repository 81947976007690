import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import styled from 'styled-components';

type CustomButtonProps = {
  label?: string;
  icon?: ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const CustomButton: FC<CustomButtonProps> = ({ label, icon, ...props }) => {
  return (
    <Button {...props}>
      {icon && icon}
      {label && label}
    </Button>
  );
};

export default CustomButton;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  outline: none;
  border: none;
  background-color: transparent;
  padding: 4px 5px;
  height: 28px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: lightgray;
  }
`;
