import { Layout as DefaultLayout, LayoutProps } from 'react-admin';
import { Menu } from './components';
import styled from 'styled-components';

const Layout = (props: LayoutProps) => {
  return <StyledLayout {...props} menu={Menu} />;
};

export default Layout;

// Hides redundant title from ra
const StyledLayout = styled(DefaultLayout)`
  .MuiTypography-root {
    & span:not(:first-child) {
      display: none;
    }
  }
`;
