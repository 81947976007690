import { FieldValues } from 'react-hook-form';

type Maybe<T> = T | null;

export const setStringArray = <T extends Array<FieldValues | string>>(
  values?: Maybe<T>,
): Maybe<string[]> => {
  if (!values) {
    return null;
  }

  return values.map((value) => (typeof value === 'string' ? value : value.id));
};
