import {
  Datagrid,
  DateField,
  DateInput,
  List,
  ListProps,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin';
import { ToggleResolveSuggestionButton } from 'components';
import React from 'react';
import styled from 'styled-components';

const Filters = [
  <DateInput key="createdAt" name="createdAt" source="createdAt" alwaysOn />,
  <TextInput
    key="customer.name"
    name="customer.name"
    source="customer.name"
    alwaysOn
  />,
];

const SuggestionsList = (props: ListProps) => {
  return (
    <List
      disableSyncWithLocation
      bulkActionButtons={false}
      filters={Filters}
      sort={{ field: 'createdAt', order: 'DESC' }}
      actions={false}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
      {...props}
    >
      <StyledDatagrid bulkActionButtons={false} rowClick="show">
        <ToggleResolveSuggestionButton />
        <StyledTextField source="customer.name" />
        <StyledTextField source="course.name" />
        <TextField source="content" />
        <DateField source="createdAt" />
        <DateField source="resolvedAt" />
      </StyledDatagrid>
    </List>
  );
};

export default SuggestionsList;

const StyledTextField = styled(TextField)`
  display: inline-block;
  width: 200px;
`;

const StyledDatagrid = styled(Datagrid)`
  td {
    max-width: 200px;
    overflow: scroll;
  }

  td:nth-child(4) {
    max-width: 400px;
  }
`;
