import { FC, memo, useRef, useState } from 'react';
import { Confirm, TextInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Editor } from 'services';
import styled from 'styled-components';
import api from 'api';
import { DeleteButton } from 'components';
import { OutputData } from '@editorjs/editorjs';
import { useAppNotify, useToggle } from 'hooks';

type UnitProps = {
  index: number;
  id?: string;
  courseId: string;
  removeField: (index: number) => void;
};

const Unit: FC<UnitProps> = ({ index, id, courseId, removeField }) => {
  const editorRef = useRef(null);
  const { errorNotify } = useAppNotify();
  const { setValue, getValues } = useFormContext();
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, close, open } = useToggle();

  const deleteUnit = async (unitIndex: number, unitId?: string) => {
    try {
      setIsLoading(true);
      close();

      if (unitId) {
        await api.AdminPanelGuides.adminGuideChapterUnitsControllerRemoveUnit(
          unitId,
        );
      }

      removeField(unitIndex);
    } catch (err) {
      errorNotify(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = (values: OutputData) => {
    setValue(`units[${index}].content`, values, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const content = getValues()?.units?.[index]?.content;

  return (
    <Root>
      <DeleteButton
        disabled={isLoading}
        text="Delete Unit"
        startIcon={null}
        onClick={open}
      />
      <StyledTextInput
        label="Unit title"
        source={`units[${index}].name`}
        fullWidth
        multiline
      />
      <Editor
        uploadImage={async (data) =>
          await api.AdminPanelGuides.adminGuidesControllerUploadImage({
            ...data,
            courseId,
          })
        }
        onSave={handleSave}
        defaultData={content}
        label="Unit content"
        holder={`units[${index}].content`}
        editorRef={editorRef}
      />
      <Confirm
        title="Are you sure you want to delete the unit?"
        content=""
        isOpen={isOpen}
        onClose={close}
        onConfirm={() => deleteUnit(index, id)}
      />
    </Root>
  );
};

export default memo(Unit);

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
`;
