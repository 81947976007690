import { ImageInput, MultipleImageInput } from 'components';

import api from 'api';
import { NewQuestionOption } from 'api/generated';
import { useOnFetchError } from 'hooks';
import {
  ArrayInput,
  BooleanInput,
  Edit,
  maxLength,
  minLength,
  SimpleForm,
  SimpleFormIterator,
  Tab,
  TabbedShowLayout,
  TextInput,
  useRefresh,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { ErrorResponse, Resources } from 'types';
import { validator } from 'utils/validator';
import { NestedResourcesContext } from 'context/NestedResources';
import { useContext } from 'react';

const TestQuestionsEdit = () => {
  const { id } = useParams();
  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};

  const refresh = useRefresh();
  const { onError } = useOnFetchError();
  const navigate = useNavigate();

  const handleSubmit = async (values: FieldValues) => {
    try {
      const { options, rightAnswerIndex } = getUpdateOptions(values);

      await api.AdminPanelQuestions.updateOneBaseAdminNewQuestionsControllerNewQuestion(
        id || '',
        {
          ...values,
          options: options,
          rightAnswerIndex,
        },
      );

      navigate(`/${Resources.TEST_QUESTIONS}/${id}/show`);
    } catch (error) {
      onError(error as ErrorResponse);
    } finally {
      refresh();
    }
  };

  const getUpdateOptions = (values: FieldValues) => {
    const options: Array<string> = [];
    let rightAnswerIndex = 0;

    const countRightAnswer = (
      values.options as Array<NewQuestionOption>
    ).reduce((accum, item, index) => {
      if (item.isCorrect) {
        accum += 1;
        rightAnswerIndex = index;
      }

      options.push(item.title);
      return accum;
    }, 0);

    if (countRightAnswer !== 1) {
      throw { body: { message: 'Only one correct answer' } };
    }

    return { options, rightAnswerIndex };
  };

  return (
    <Edit title="Question edit" redirect="show">
      <TabbedShowLayout>
        <Tab label="Question">
          <SimpleForm onSubmit={handleSubmit}>
            <TextInput
              label="Question"
              source="subject"
              fullWidth
              multiline
              validate={[maxLength(500), validator.text]}
            />
            <TextInput source="hint" fullWidth multiline label="Hint" />
            <MultipleImageInput
              label="Question image"
              source="imagesUrls"
              uploadImage={({ filename }) =>
                api.AdminPanelQuestions.adminNewQuestionsControllerUploadImage({
                  filename,
                })
              }
            />
            <BooleanInput source="availableForFree" />
            <TextInput
              source="explanationText"
              fullWidth
              multiline
              validate={[maxLength(700), validator.text]}
            />
            <ImageInput
              label="Explanation image"
              source="explanationImageUrl"
              uploadImage={({ filename }) =>
                api.AdminPanelQuestions.adminNewQuestionsControllerUploadImage({
                  filename,
                })
              }
            />
            <ReferenceInput
              label="Domain"
              source="domainId"
              reference={Resources.TEST_TOPICS}
              sort={{ field: 'title', order: 'ASC' }}
              filter={{ courseId: courseId }}
            >
              <AutocompleteInput
                label="Domain"
                filterToQuery={(searchText) => ({ subject: searchText })}
                optionText="title"
                fullWidth
              />
            </ReferenceInput>
            <ReferenceInput
              label="Bank"
              source="chapterId"
              reference={Resources.TEST_CHAPTERS}
              sort={{ field: 'title', order: 'ASC' }}
              filter={{ courseId: courseId }}
            >
              <AutocompleteInput
                label="Bank"
                filterToQuery={(searchText) => ({ subject: searchText })}
                optionText="title"
                fullWidth
              />
            </ReferenceInput>
            <ArrayInput
              validate={[minLength(2), maxLength(4)]}
              fullWidth
              source="options"
            >
              <SimpleFormIterator fullWidth>
                <BooleanInput source="isCorrect" />
                <TextInput
                  source="title"
                  validate={[
                    validator.textRequired(),
                    maxLength(500),
                    validator.text,
                  ]}
                  fullWidth
                  multiline
                />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleForm>
        </Tab>
      </TabbedShowLayout>
    </Edit>
  );
};

export default TestQuestionsEdit;
