import { ErrorResponse, ErrorType } from 'types';
import useAppNotify from '../hooks/useAppNotify';

const useOnFetchError = (customError?: string) => {
  const { errorNotify } = useAppNotify();

  const onError = (error: ErrorResponse | ErrorType) => {
    const errorBody =
      (error as ErrorResponse)?.body ||
      (error as ErrorType)?.response?.data ||
      error;
    const isMessageArray = Array.isArray(errorBody?.message);
    const message = isMessageArray
      ? errorBody?.message[0]
      : (errorBody?.message as string);

    errorNotify(customError || message || 'Something went wrong');
  };
  return { onError };
};

export default useOnFetchError;
