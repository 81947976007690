import Modal, { ModalProps } from '@mui/material/Modal';
import { useOnFetchError } from 'hooks';
import { FC } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  useCreate,
  useRefresh,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Resources } from 'types';

const CreatePhaseModal: FC<Omit<ModalProps, 'children'>> = ({
  onClose,
  ...props
}) => {
  const { id } = useParams();
  const refresh = useRefresh();
  const { onError } = useOnFetchError();
  const [create] = useCreate(
    Resources.STUDY_PLANS_PHASES,
    { data: { planId: id } },
    {
      onError,
      onSuccess() {
        refresh();
        onClose?.({}, 'backdropClick');
      },
    },
  );

  const handleSubmit = async (values: FieldValues) => {
    await create(Resources.STUDY_PLANS_PHASES, {
      data: { planId: id, name: values.name },
    });
  };

  return (
    <StyledModal onClose={onClose} {...props}>
      <Create>
        <SimpleForm onSubmit={handleSubmit}>
          <TextInput
            source="name"
            label="Name"
            defaultValue="Week "
            fullWidth
            multiline
          />
        </SimpleForm>
      </Create>
    </StyledModal>
  );
};

export default CreatePhaseModal;

const StyledModal = styled(Modal)`
  .MuiPaper-root {
    width: 100%;
    max-width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .MuiAutocomplete-root {
    width: 100%;
  }
`;
