import { Button } from 'react-admin';
import { FC } from 'react';
import styled from 'styled-components';
import Modal, { ModalProps } from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

type ReleaseModalProps = {
  onConfirm: () => void;
  onRequestClose: () => void;
  label: string;
  isLoading: boolean;
} & Omit<ModalProps, 'children'>;

const ConfirmModal: FC<ReleaseModalProps> = (props) => {
  return (
    <StyledModal
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      {...props}
    >
      <Container>
        <Typography
          component="h4"
          variant="h4"
          align="center"
          style={{ marginBottom: '24px' }}
        >
          {props.label}
        </Typography>
        <ButtonContainer>
          <StyledButton
            variant="contained"
            label="Confirm"
            onClick={props.onConfirm}
            disabled={props.isLoading}
          />
          <StyledButton
            variant="contained"
            label="Cancel"
            onClick={props.onRequestClose}
            disabled={props.isLoading}
          />
        </ButtonContainer>
      </Container>
    </StyledModal>
  );
};

export default ConfirmModal;

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiToolbar-root {
    display: none;
  }
`;

const Container = styled.div`
  background-color: lightgray;
  width: 500px;
  max-width: 500px;
  padding: 40px 50px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;
