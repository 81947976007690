import { DeleteButton, TopToolbar, useRecordContext } from 'react-admin';

const InviteActions = () => {
  const invite = useRecordContext();
  const isAccepted = invite.acceptedAt;

  return <TopToolbar>{!isAccepted && <DeleteButton />}</TopToolbar>;
};

export default InviteActions;
