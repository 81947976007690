import api from 'api';
import {
  CreateGuideChapterDto,
  CreateGuideChapterUnitRequestDto,
  CreateNewChapterDto,
  GuideChapterResponseDto,
  UpdateGuideChapterDto,
} from 'api/generated';
import { ImageInput } from 'components';
import { FileEnum } from 'components/ImageInput/components/File/File';
import { NestedResourcesContext } from 'context/NestedResources';
import { useAppNotify, useQuery } from 'hooks';
import { useContext, useEffect, useState } from 'react';
import { Button, Loading, SimpleForm, TextInput } from 'react-admin';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { CardCSS } from 'styles/ui/card';
import { Resources } from 'types';
import { Toolbar, Units } from './components';

type ChapterType = {
  content: object;
  name: string;
  units: Array<UnitType>;
  cheatSheetUrl?: string;
};

type UnitType = {
  id?: string;
  content: string;
  name: string;
};

const MB_100 = 100000000;

const Chapter = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const { errorNotify, successNotify } = useAppNotify();

  const { id } = useParams();
  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};

  const studyGuideId = query.get('studyGuideId');

  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [chapter, setChapter] = useState<GuideChapterResponseDto | null>(null);

  const getChapter = async (id: string) => {
    try {
      setIsLoading(true);

      const response =
        await api.AdminPanelGuides.adminGuideChaptersControllerGetGuideChapter(
          id,
        );

      setChapter(
        (response.data as unknown as Array<GuideChapterResponseDto>)[0],
      );
    } catch (err) {
      errorNotify(err);

      navigate(`/${Resources.STUDY_GUIDES}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values: ChapterType) => {
    if (studyGuideId && id) {
      await updateChapter(values, id);
    } else if (studyGuideId && !id) {
      await createChapter(
        values as unknown as CreateNewChapterDto,
        studyGuideId,
      );
    } else {
      await createStudyGuide(values);
    }
  };

  const updateChapter = async (values: ChapterType, chapterId: string) => {
    try {
      setIsFetching(true);
      await api.AdminPanelGuides.adminGuideChaptersControllerUpdateChapter(
        chapterId,
        values as UpdateGuideChapterDto,
      );

      await updateChaptersUnits(
        values.units as unknown as (CreateGuideChapterUnitRequestDto & {
          id?: string | undefined;
        })[],
        chapterId,
      );
      location.reload();
      successNotify('Chapter updated successfully');
    } catch (err) {
      errorNotify(err);
    } finally {
      setIsFetching(false);
    }
  };

  const updateChaptersUnits = async (
    units: Array<CreateGuideChapterUnitRequestDto & { id?: string }>,
    chapterId: string,
  ) => {
    const newUnits = units.filter((u) => !u.id);

    await Promise.all([
      ...units.map(async (unit) => {
        if (unit.id) {
          return api.AdminPanelGuides.adminGuideChapterUnitsControllerUpdateUnit(
            unit.id,
            unit,
          );
        }
      }),
      api.AdminPanelGuides.adminGuideChapterUnitsControllerCreateUnitsBulk(
        chapterId,
        { units: newUnits },
      ),
    ]);
  };

  const createChapter = async (
    values: CreateNewChapterDto,
    studyGuideId: string,
  ) => {
    try {
      setIsFetching(true);

      await api.AdminPanelGuides.adminGuideChaptersControllerCreateChapter(
        studyGuideId,
        values,
      );

      navigate(`/${Resources.STUDY_GUIDES}`);

      successNotify('Chapter created successfully');
    } catch (err) {
      errorNotify(err);
    } finally {
      setIsFetching(false);
    }
  };

  const createStudyGuide = async ({
    cheatSheetUrl,
    ...values
  }: ChapterType) => {
    if (courseId)
      try {
        setIsFetching(true);

        await api.AdminPanelGuides.adminGuidesControllerCreateGuide({
          courseId,
          cheatSheetUrl,
          chapters: [values as unknown as CreateGuideChapterDto],
        });

        navigate(`/${Resources.STUDY_GUIDES}`);
        successNotify('Study guide created successfully');
      } catch (err) {
        errorNotify(err);
      } finally {
        setIsFetching(false);
      }
  };

  useEffect(() => {
    if (id) {
      getChapter(id);
    } else {
      setIsLoading(false);
    }
  }, [id]);

  const navigateToCreateQuizTest = () =>
    navigate(`/${Resources.TESTS_QUIZ}/create?chapterId=${chapter?.id}`);
  const navigateToUpdateQuizTest = () =>
    navigate(
      `/${Resources.TESTS_QUIZ}/${chapter?.quizId}/edit?chapterId=${chapter?.id}`,
    );

  return (
    <Root>
      {isLoading ? (
        <Loading />
      ) : (
        <SimpleForm
          defaultValues={{
            name: chapter?.name,
            units: chapter?.units,
            audioUrl: chapter?.audioUrl,
          }}
          toolbar={<Toolbar isFetching={isFetching} chapterId={id} />}
          onSubmit={(e) => handleSubmit(e as ChapterType)}
        >
          <Wrapper>
            {chapter && (
              <div>
                <Button
                  variant="outlined"
                  fullWidth={false}
                  onClick={
                    chapter.quizId
                      ? navigateToUpdateQuizTest
                      : navigateToCreateQuizTest
                  }
                  size="large"
                  label={
                    chapter.quizId
                      ? 'Change chapter test'
                      : 'Create test for chapter'
                  }
                />
              </div>
            )}
            {!studyGuideId && (
              <ImageInput
                uploadImage={async ({ filename }) =>
                  await api.AdminPanelGuides.adminGuidesControllerUploadCheatSheet(
                    {
                      courseId,
                      filename,
                    },
                  )
                }
                filePreviewSize={48}
                fileType={FileEnum.DOCUMENT}
                labelSingle="Drop a cheat-sheet to upload, or click to select it."
                accept="application/pdf"
                label="Cheatsheet"
                source="cheatSheetUrl"
              />
            )}
            <ImageInput
              uploadImage={async ({ filename }) =>
                await api.AdminPanelGuides.adminGuidesControllerUploadAudio({
                  courseId,
                  filename,
                })
              }
              maxSize={MB_100}
              filePreviewSize={48}
              fileType={FileEnum.AUDIO}
              accept="audio/*"
              label="Chapter audio"
              labelSingle="Drop an audio to upload, or click to select it."
              source="audioUrl"
            />
            <div>
              <StyledTextInput
                label="Title"
                source="name"
                fullWidth
                multiline
              />
            </div>
            <Units
              courseId={courseId}
              chapter={chapter}
              isLoading={isFetching}
            />
          </Wrapper>
        </SimpleForm>
      )}
    </Root>
  );
};

export default Chapter;

const Root = styled.div`
  ${CardCSS}
  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .MuiFormLabel-root {
    z-index: 0;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
`;
