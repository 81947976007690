import {
  GuideChapterResponseDto,
  GuideChapterResponseUnitDto,
} from 'api/generated';
import React, { FC, useEffect } from 'react';
import { Button } from '@mui/material';
import {
  FieldValue,
  FieldValues,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import styled from 'styled-components';
import Unit from '../Unit/Unit';

type UnitsProps = {
  chapter?: GuideChapterResponseDto | null;
  isLoading: boolean;
  courseId: string;
};

type UnitField = {
  id: string;
  name: string;
  value: GuideChapterResponseUnitDto;
} & FieldValue<FieldValues>;

const Units: FC<UnitsProps> = ({ chapter, courseId, isLoading }) => {
  const { control } = useFormContext();

  const { append, remove, update, ...params } = useFieldArray({
    control,
    keyName: 'keyId',
    name: 'units',
    rules: { minLength: 1 },
  });

  const fields = params?.fields as UnitField[];

  const generateFields = () => {
    const fieldsLength = fields?.length;
    const unitsCount = chapter?.units?.length || 0;

    if (!fieldsLength)
      for (let i = 0; i <= unitsCount + 1; i++) {
        append(chapter?.units?.[i] || {});
      }
  };

  const removeFields = () => {
    const fieldsLength = fields.length;

    for (let i = fieldsLength; i === 0; i--) {
      remove(i);
    }
  };

  const addField = () => {
    append({});
  };

  useEffect(() => {
    generateFields();
    return removeFields();
  }, [Boolean(chapter)]);

  return (
    <Root>
      {fields.map((field, index) => (
        <Unit
          index={index}
          id={field?.id}
          courseId={courseId}
          removeField={remove}
          key={field.keyId}
        />
      ))}
      <StyledButton variant="outlined" disabled={isLoading} onClick={addField}>
        Add unit
      </StyledButton>
    </Root>
  );
};

export default Units;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledButton = styled(Button)`
  width: fit-content;
`;
