import api from 'api';
import { AdminCustomerCourseStatisticsDtoV2 } from 'api/generated';
import { useEffect, useState } from 'react';
import {
  ArrayField,
  Confirm,
  Datagrid,
  DateField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useRefresh,
  useShowController,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { CustomTextField, ShowActions } from 'components';
import Grid from '@mui/material/Grid';
import { useAppNotify, useToggle } from 'hooks';
import { ResetButton } from './components';

const CustomerShow = () => {
  const { id } = useParams() || {};
  const { errorNotify, successNotify } = useAppNotify();
  const refresh = useRefresh();
  const { record } = useShowController({ resource: 'admin/customers' });

  const [statistics, setStatistics] = useState<
    null | AdminCustomerCourseStatisticsDtoV2[]
  >(null);
  const [courseId, setCourseId] = useState<string | null>(null);
  const { isOpen, open, close } = useToggle();

  const getCustomerProgressStats = async (customerId: string) => {
    try {
      const response =
        await api.AdminPanelCustomers.adminCustomersControllerGetCustomerProgressStatsV2(
          customerId,
        );
      setStatistics(response.data);
    } catch (err) {
      errorNotify(err);
    }
  };

  const resetCourseSubscription = async (
    courseId: string,
    customerId: string,
  ) => {
    try {
      await api.AdminPanelCustomers.adminCustomersControllerCancelCustomerSubscription(
        customerId,
        courseId,
      );

      successNotify('Course reset successfully');
    } catch (err) {
      errorNotify(err);
    } finally {
      refresh();
      onClose();
    }
  };

  const onClose = () => {
    close();
    setCourseId(null);
  };

  const handleResetButtonClick = (courseId: string) => {
    setCourseId(courseId);
    open();
  };

  useEffect(() => {
    if (id) getCustomerProgressStats(id);
  }, [id]);

  return (
    <>
      <Label>Press EDIT to reset the user password</Label>
      <Show
        title="Customer"
        actions={
          <ShowActions
            personalTitle="WARNING!!! User account will be permanently deleted"
            customerId={record ? record.stripeCustomerId : ''}
          />
        }
      >
        <StyledTabbedShowLayout>
          <Tab label="About customer">
            <TextField source="id" />
            <TextField label="Direction id" source="directionId" />

            <TextField label="Stripe customer id" source="stripeCustomerId" />
            <TextField source="email" />
            <TextField source="name" />
            <DateField source="lastSessionAt" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
            <DateField source="deletedAt" />
          </Tab>
          <Tab label="Customers all courses">
            <ArrayField source="customerCourses">
              <Datagrid bulkActionButtons={false}>
                <TextField source="course.id" />
                <TextField source="course.name" />
                <DateField source="lastActivatedAt" />
              </Datagrid>
            </ArrayField>
          </Tab>
          <Tab label="Customers purchased courses">
            <ArrayField source="purchases">
              <Datagrid bulkActionButtons={false}>
                <TextField label="Course id" source="courseId" />
                <TextField source="courseName" />
                <TextField source="priceId" />
              </Datagrid>
            </ArrayField>
          </Tab>
          <Tab label="Customers subscripted  courses">
            <ArrayField source="subscriptions">
              <Datagrid bulkActionButtons={false}>
                <TextField label="Course id" source="courseId" />
                <TextField source="courseName" />
                <TextField source="billingPeriod" />
                <TextField source="billingType" />
                <TextField source="prevPaymentDate" />
                <TextField source="nextPaymentDate" />
                <TextField source="priceId" />
                <TextField source="status" />
                <ResetButton
                  onClick={handleResetButtonClick}
                  label="Reset subscription"
                />
              </Datagrid>
            </ArrayField>
          </Tab>
          <Tab label="Customers courses points">
            {statistics?.map((item, index) => (
              <Wrapper key={index}>
                <Grid item>
                  <CustomTextField label="Course id" text={item.courseId} />
                </Grid>
                <CustomTextField label="name" text={item.courseName} />
                <CustomTextField
                  label="Flashcards points"
                  text={item.points.flashcardsPoints.learnedInPoints}
                />
                <CustomTextField
                  label="PlanAssignments points"
                  text={item.points.tasksPoints.markedInPoints}
                />
                <CustomTextField
                  label="Tests points"
                  text={item.points.testsPoints.correctInPoints}
                />
                <CustomTextField
                  label="Total"
                  text={item.points.totalInPoints}
                />
              </Wrapper>
            ))}
          </Tab>
        </StyledTabbedShowLayout>
        <Confirm
          title="Are you sure you want to reset the customer courses subscription?"
          content=""
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={() =>
            courseId && id && resetCourseSubscription(courseId, id)
          }
        />
      </Show>
    </>
  );
};

export default CustomerShow;

const StyledTabbedShowLayout = styled(TabbedShowLayout)`
  .MuiTabs-scroller {
    overflow-x: auto !important;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: minmax(100px, 300px) repeat(5, minmax(100px, 200px));
`;

const Label = styled.p`
  font-size: 16px !important;
  margin-bottom: 0.2em;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 100rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgb(255, 99, 71);
  position: absolute;
  right: 190px;
  top: 67px;
`;
