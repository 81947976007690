import { AdminRoleEnum } from 'api/generated';
import { Edit } from 'react-admin';
import { StorageKeys, StorageService } from 'services';
import { AdminForm, SuperAdminForm } from './components';

const CourseEdit = () => {
  const role = StorageService.getItem(StorageKeys.ROLE);
  const isSuperAdmin = role === AdminRoleEnum.Superadmin;

  return <Edit>{isSuperAdmin ? <SuperAdminForm /> : <AdminForm />}</Edit>;
};

export default CourseEdit;
