import { FC } from 'react';
import {
  AutocompleteInput,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import {
  discountTypeOptions,
  subscriptionDurationTypeOptions,
} from 'resources/Coupons/CouponsCreate/constants';
import styled from 'styled-components';
import { Resources } from 'types';
import { validator } from 'utils/validator';

type InputsProps = {
  type: string;
  subscriptionsTerm: string;
  disabled: boolean;
  isActive: boolean;
};

const Inputs: FC<InputsProps> = ({
  subscriptionsTerm,
  type,
  disabled,
  isActive,
}) => {
  const { id } = useParams();
  const period = {
    monthlySubscriptions: 'monthly',
    annualSubscriptions: 'annual',
  };
  return (
    <Root>
      <NumberInput
        label="amount"
        source={`prices.${subscriptionsTerm}.${type}.amount`}
        validate={isActive ? [required(), validator.notZero] : undefined}
        disabled={disabled}
        fullWidth
      />
      <NumberInput
        label="discount"
        source={`prices.${subscriptionsTerm}.${type}.cancellationPromotionTerms.discount`}
        validate={isActive ? [required(), validator.notZero] : undefined}
        disabled={disabled}
        fullWidth
      />
      <SelectInput
        label="discount type"
        source={`prices.${subscriptionsTerm}.${type}.cancellationPromotionTerms.discountType`}
        validate={isActive ? [required(), validator.notZero] : undefined}
        choices={discountTypeOptions}
        disabled={disabled}
        fullWidth
      />
      <SelectInput
        label="duration"
        source={`prices.${subscriptionsTerm}.${type}.cancellationPromotionTerms.duration`}
        validate={isActive ? [required(), validator.notZero] : undefined}
        choices={subscriptionDurationTypeOptions}
        disabled={disabled}
        fullWidth
      />
      <NumberInput
        label="duration in months"
        source={`prices.${subscriptionsTerm}.${type}.cancellationPromotionTerms.durationInMonths`}
        disabled={disabled}
        fullWidth
      />
      <ReferenceInput
        label="Coupon"
        source={`prices.${subscriptionsTerm}.${type}.defaultCouponId`}
        disabled={disabled}
        queryOptions={{
          meta: {
            courseId: id,
            period: period[subscriptionsTerm as keyof typeof period],
            level: type,
          },
        }}
        reference={Resources.SEARCH_COUPONS}
      >
        <AutocompleteInput
          label="Coupon"
          optionText="name"
          alwaysOn={false}
          fullWidth
        />
      </ReferenceInput>
      <TextInput
        label="description"
        source={`prices.${subscriptionsTerm}.${type}.description`}
        disabled={disabled}
        fullWidth
        multiline
      />
    </Root>
  );
};

export default Inputs;

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
`;
