import ToggleResolveReportButton from 'components/ToggleResolveReportButton';
import { DeleteButton, TopToolbar } from 'react-admin';

const ReportActions = () => {
  return (
    <TopToolbar>
      <ToggleResolveReportButton />
      <DeleteButton />
    </TopToolbar>
  );
};

export default ReportActions;
