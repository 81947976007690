import {
  ArrayField,
  Datagrid,
  Labeled,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowContext,
} from 'react-admin';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { CouponResponseDto } from 'api/generated';

const ShowLayout = () => {
  const { record } = useShowContext<CouponResponseDto>();
  const [coupon, setCoupon] = useState<CouponResponseDto>();

  const [date, setDate] = useState('');

  useEffect(() => {
    if (record) {
      setCoupon(record);
      setDate(format(new Date(record?.redeemBy as string), 'MM-dd-yyyy'));
    }
  }, [record]);

  return (
    <TabbedShowLayout>
      <Tab label="Main info">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="promoCode" />
        <TextField source="discountType" />
        <TextField source="discountAmount" />
        <TextField source="subscriptionDurationType" emptyText="-" />
        <TextField source="subscriptionDuration" emptyText="-" />
        <TextField source="maxRedemptions" emptyText="-" />
        <TextField source="redeemBy" />

        <TextField source={coupon?.allowedPeriods[0]} />
        <Labeled>
          <TextField source={date} />
        </Labeled>
        <TextField label="Allowed subscription plans" />
        <span>{coupon?.allowedSubscriptionPlans?.[0]}</span>
      </Tab>
      <Tab label="Courses">
        <ArrayField source="allowedCourses">
          <Datagrid bulkActionButtons={false}>
            <TextField source="id" />
            <TextField source="name" />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  );
};
const CouponsShow = () => {
  return (
    <Show
      title="Coupon"
      queryOptions={{
        meta: {
          join: ['allowedCourses'],
        },
      }}
    >
      <ShowLayout />
    </Show>
  );
};

export default CouponsShow;
