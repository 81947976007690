import api from 'api';
import { ImageInput, MultipleImageInput } from 'components';
import { NestedResourcesContext } from 'context/NestedResources';
import { useOnFetchError } from 'hooks';
import { useContext } from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  maxLength,
  minLength,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useCreate,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Resources } from 'types';
import { validator } from 'utils/validator';

const TestQuestionsCreate = () => {
  const navigate = useNavigate();
  const { onError } = useOnFetchError();
  const [create] = useCreate(Resources.TEST_QUESTIONS, undefined, {
    onError,
    onSuccess() {
      navigate(`/${Resources.TEST_QUESTIONS}`);
    },
  });
  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};

  const createQuestion = async (data: FieldValues) => {
    await create(Resources.TEST_QUESTIONS, {
      data: { ...data, rightAnswerIndex: data.rightAnswerIndex - 1 },
    });
  };

  return (
    <Create>
      <SimpleForm onSubmit={createQuestion}>
        <TextInput
          label="Question"
          source="subject"
          fullWidth
          multiline
          validate={[validator.textRequired(), maxLength(500), validator.text]}
        />
        <TextInput source="hint" fullWidth multiline label="Hint" />
        <MultipleImageInput
          label="Question image"
          source="imagesUrls"
          uploadImage={({ filename }) =>
            api.AdminPanelQuestions.adminNewQuestionsControllerUploadImage({
              filename,
            })
          }
        />
        <TextInput
          source="explanationText"
          multiline
          validate={[validator.textRequired(), maxLength(700), validator.text]}
        />
        <ImageInput
          label="Explanation image"
          source="explanationImageUrl"
          uploadImage={({ filename }) =>
            api.AdminPanelQuestions.adminNewQuestionsControllerUploadImage({
              filename,
            })
          }
        />
        <ReferenceInput
          label="Domain"
          source="domainId"
          reference={Resources.TEST_TOPICS}
          sort={{ field: 'title', order: 'ASC' }}
          filter={{ courseId: courseId }}
        >
          <AutocompleteInput
            label="Domain"
            filterToQuery={(searchText) => ({ subject: searchText })}
            optionText="title"
            fullWidth
          />
        </ReferenceInput>
        <ReferenceInput
          label="Bank"
          source="chapterId"
          reference={Resources.TEST_CHAPTERS}
          sort={{ field: 'title', order: 'ASC' }}
          filter={{ courseId: courseId }}
        >
          <AutocompleteInput
            label="Bank"
            filterToQuery={(searchText) => ({ subject: searchText })}
            optionText="title"
            fullWidth
          />
        </ReferenceInput>
        <BooleanInput source="availableForFree" />
        <NumberInput
          source="rightAnswerIndex"
          label="Correct answer number"
          validate={[required()]}
          min={1}
          fullWidth
        />
        <ArrayInput
          validate={[minLength(2), maxLength(4)]}
          source="options"
          fullWidth
        >
          <SimpleFormIterator fullWidth>
            <TextInput
              source=""
              validate={[
                validator.textRequired(),
                maxLength(100),
                validator.text,
              ]}
              fullWidth
              multiline
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default TestQuestionsCreate;
