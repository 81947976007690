import {
  BooleanField,
  Datagrid,
  List,
  ListProps,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin';

import {
  BulkDeleteActionButton,
  ColorField,
  OpenButton,
  StyledImageField,
} from 'components';
import { UrlVariablesEnum } from 'types';

const Filters = [<TextInput key="name" source="name" alwaysOn />];

const DirectionsList = (props: ListProps) => {
  return (
    <List
      disableSyncWithLocation
      filters={Filters}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
      {...props}
    >
      <Datagrid
        rowClick="show"
        bulkActionButtons={
          <BulkDeleteActionButton
            mutationMode="pessimistic"
            confirmTitle="CAREER DELETE"
            confirmContent="Are you sure want to delete this career?"
          />
        }
      >
        <ColorField source="mainColorHex" />
        <StyledImageField source="logoUrl" />
        <TextField source="name" />
        <BooleanField source="isReleased" sortable={false} />
        <TextField source="siteUrl" />
        <TextField source="landingUrl" />
        <OpenButton label="Open career" variable={UrlVariablesEnum.CAREER} />
      </Datagrid>
    </List>
  );
};

export default DirectionsList;
