import {
  Button,
  DeleteButton,
  List,
  Pagination,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

import { useToggle } from 'hooks';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Resources } from 'types';
import { CreatePhaseModal, PhasesDataGrid } from './components';

const StudyPlanShow = () => {
  const { isOpen, open, close } = useToggle();
  const { id } = useParams();

  return (
    <Show title="Study plan">
      <TabbedShowLayout>
        <Tab label="About study plan">
          <TextField source="id" />
          <TextField source="name" />
          <h4>Phases</h4>
          <List
            disableSyncWithLocation
            resource={Resources.STUDY_PLANS_PHASES}
            queryOptions={{ meta: { planId: id } }}
            title=" "
            actions={false}
            perPage={100}
            pagination={<Pagination rowsPerPageOptions={[]} />}
            empty={<StyledButton onClick={open} label="Create first phase" />}
          >
            <>
              <PhasesDataGrid rowClick="show" bulkActionButtons={false}>
                <TextField sortable={false} source="name" />
                <DeleteButton
                  redirect={`/${Resources.STUDY_PLANS}/${id}/show`}
                />
              </PhasesDataGrid>
              <StyledButton onClick={open} label="Add next phase" />
            </>
          </List>
        </Tab>
      </TabbedShowLayout>
      <CreatePhaseModal open={isOpen} onClose={close} />
    </Show>
  );
};

export default StudyPlanShow;

const StyledButton = styled(Button)`
  margin: 8px 16px !important;
`;
