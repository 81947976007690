import api from 'api';
import { ColorInput, ImageInput } from 'components';
import {
  Create,
  maxLength,
  minLength,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { validator } from 'utils/validator';

const DirectionCreate = () => {
  const handleUploadImage = async (data: { filename: string }) =>
    await api.AdminPanelDirections.adminDirectionsControllerUploadImage(data);

  return (
    <Create redirect="list">
      <SimpleForm>
        <ImageInput
          uploadImage={handleUploadImage}
          isRequired
          validate={required()}
          source="logoUrl"
          label="Logo url"
        />
        <ImageInput
          uploadImage={handleUploadImage}
          isRequired
          validate={required()}
          source="darkLogoUrl"
          label="Dark logo url"
        />
        <ImageInput
          uploadImage={handleUploadImage}
          isRequired
          validate={required()}
          source="faviconUrl"
          label="favicon url"
        />
        <ImageInput
          uploadImage={handleUploadImage}
          isRequired
          validate={required()}
          source="checkoutLogoUrl"
          label="Checkout logo url"
        />
        <ImageInput
          uploadImage={handleUploadImage}
          isRequired
          validate={required()}
          source="darkCheckoutLogoUrl"
          label="Dark checkout logo url"
        />
        <ColorInput validate={required()} source="mainColorHex" />
        <ColorInput validate={required()} source="oldColorHex" />
        <TextInput
          validate={[maxLength(150), minLength(2), validator.textRequired()]}
          source="name"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.urlRequired()}
          source="siteUrl"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.urlRequired()}
          source="landingUrl"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.textRequired()}
          source="termsOfServiceUrl"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.idRequired()}
          source="signUpTemplateId"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.idRequired()}
          source="restorePasswordTemplateId"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.idRequired()}
          source="changeEmailTemplateId"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.idRequired()}
          source="subscriptionPaymentFailedTemplateId"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.idRequired()}
          source="courseUnreleasedTemplateId"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.idRequired()}
          source="freeAccessGrantedTemplateId"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.idRequired()}
          source="inviteTemplateId"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.idRequired()}
          source="paymentSuccessfulTemplateId"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.textRequired()}
          source="linkTitle"
          fullWidth
          isRequired
          multiline
        />
        <TextInput
          validate={validator.text}
          source="linkDescription"
          fullWidth
          multiline
        />
      </SimpleForm>
    </Create>
  );
};

export default DirectionCreate;
