import { addMonths, addWeeks, formatISO } from 'date-fns';

export const getRedeemBy = (option: string, customDate: Date) => {
  const date = new Date();
  const dateOneWeek = addWeeks(date, 1);
  const dateOneMonth = addMonths(date, 1);

  switch (option) {
    case 'never':
      return null;
    case 'week':
      return formatISO(dateOneWeek);
    case 'month':
      return formatISO(dateOneMonth);
    case 'custom':
      return formatISO(new Date(customDate));
    default:
      return null;
  }
};
