import { ButtonProps } from 'react-admin';
import { FC } from 'react';
import CustomButton from '../CustomButton';

type ReleaseButtonProps = {
  isReleased: boolean;
} & ButtonProps;

const ReleaseButton: FC<ReleaseButtonProps> = (props) => {
  return (
    <CustomButton
      {...props}
      label={props.isReleased ? 'Unrelease' : 'Release'}
    />
  );
};

export default ReleaseButton;
