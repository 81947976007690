import api from 'api';
import CustomButton from 'components/CustomButton';
import { useOnFetchError } from 'hooks';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useRecordContext, useRefresh } from 'react-admin';
import { ErrorResponse } from 'types';

const ToggleResolveReportButton = () => {
  const [isResolved, setIsResolver] = useState<boolean | null>(null);
  const refresh = useRefresh();
  const { onError } = useOnFetchError();
  const report = useRecordContext();

  useEffect(() => {
    if (report) {
      setIsResolver(Boolean(report?.resolvedAt));
    }
  }, [report]);

  const onResolveClick: MouseEventHandler = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();

      await api.AdminPanelReports.adminReportsControllerResolve(
        report?.id as string,
      );
      refresh();
    } catch (error) {
      onError(error as ErrorResponse);
    }
  };

  const onUnResolveClick: MouseEventHandler = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();

      await api.AdminPanelReports.adminReportsControllerMarkUnresolved(
        report?.id as string,
      );
      refresh();
    } catch (error) {
      onError(error as ErrorResponse);
    }
  };

  if (isResolved === null) {
    return null;
  }

  return isResolved ? (
    <CustomButton label="Unresolve" onClick={onUnResolveClick} />
  ) : (
    <CustomButton label="Resolve" onClick={onResolveClick} />
  );
};

export default ToggleResolveReportButton;
