import { ShowActions, StyledImageField } from 'components';
import { Show, Tab, TabbedShowLayout, TextField } from 'react-admin';

const MnemonicCardsShow = () => {
  return (
    <Show actions={<ShowActions />} title="Mnemonic card">
      <TabbedShowLayout>
        <Tab label="About mnemonic card">
          <TextField source="id" />
          <StyledImageField $size={100} source="imageUrl" />
          <TextField source="title" />
          <TextField source="answersCount" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default MnemonicCardsShow;
