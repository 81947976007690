import { ColorField, StyledImageField, DirectionActions } from 'components';
import {
  BooleanField,
  DateField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  UrlField,
} from 'react-admin';

const DirectionShow = () => {
  return (
    <Show title="Career" actions={<DirectionActions />}>
      <TabbedShowLayout>
        <Tab label="About career">
          <StyledImageField $size={100} source="logoUrl" />
          <StyledImageField $size={100} source="darkLogoUrl" />
          <StyledImageField $size={100} source="faviconUrl" />
          <StyledImageField $size={100} source="checkoutLogoUrl" />
          <StyledImageField $size={100} source="darkCheckoutLogoUrl" />
          <TextField source="id" />
          <DateField source="createdAt" />
          <ColorField source="mainColorHex" />
          <ColorField source="oldColorHex" old />
          <TextField source="name" />
          <BooleanField source="isReleased" />
          <UrlField source="siteUrl" />
          <UrlField source="landingUrl" />
          <UrlField source="termsOfServiceUrl" />
          <TextField source="signUpTemplateId" />
          <TextField source="restorePasswordTemplateId" />
          <TextField source="changeEmailTemplateId" />
          <TextField source="subscriptionPaymentFailedTemplateId" />
          <TextField source="courseUnreleasedTemplateId" />
          <TextField source="freeAccessGrantedTemplateId" />
          <TextField source="inviteTemplateId" />
          <TextField source="paymentSuccessfulTemplateId" />
          <TextField source="linkTitle" />
          <TextField source="linkDescription" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default DirectionShow;
