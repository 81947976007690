import Modal, { ModalProps } from '@mui/material/Modal';
import api from 'api';
import { FC, useState } from 'react';
import {
  Button,
  maxLength,
  SimpleForm,
  TextField,
  TextInput,
  useRecordContext,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import styled from 'styled-components';
import { validator } from 'utils/validator';
import { useAppNotify } from 'hooks';

type AppointReporterModalProps = {
  onRequestClose: () => void;
} & Omit<ModalProps, 'children'>;

export const AppointReporterModal: FC<AppointReporterModalProps> = ({
  onClose,
  onRequestClose,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { errorNotify, successNotify } = useAppNotify();
  const course = useRecordContext();

  const appointReporter = async (email: string) => {
    setIsLoading(true);

    try {
      await api.AdminPanelCourses.adminCoursesControllerSetWatcher(
        course.id as string,
        {
          email,
        },
      );

      successNotify('Reporter successfully appointed');
      onRequestClose();
    } catch (err) {
      errorNotify(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (data: FieldValues) => {
    await appointReporter(data.email);
  };

  return (
    <StyledModal
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      {...props}
    >
      <SimpleForm onSubmit={handleSubmit}>
        <TextField label="Appoint reporter" />
        <Container>
          <TextInput
            validate={[
              maxLength(155),
              validator.email,
              validator.textRequired(),
            ]}
            source="email"
            label="Responsible member email"
            fullWidth
            multiline
          />
          <ButtonContainer>
            <StyledButton
              variant="contained"
              label="Confirm"
              type="submit"
              disabled={isLoading}
            />
            <StyledButton
              variant="contained"
              label="Cancel"
              onClick={onRequestClose}
              disabled={isLoading}
            />
          </ButtonContainer>
        </Container>
      </SimpleForm>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiToolbar-root {
    display: none;
  }
`;

const Container = styled.div`
  background-color: lightgray;
  width: 500px;
  max-width: 500px;
  padding: 40px 50px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;
