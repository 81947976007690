import { AdminRoleEnum } from 'api/generated';
import {
  CreateButton,
  Datagrid,
  ExportButton,
  List,
  ListProps,
  maxLength,
  Pagination,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin';
import { StorageKeys, StorageService } from 'services';
import styled from 'styled-components';
import { validator } from 'utils/validator';

const Actions = () => {
  const role = StorageService.getItem(StorageKeys.ROLE);
  const isSuperAdmin = role === AdminRoleEnum.Superadmin;
  return (
    <TopToolbar>
      {isSuperAdmin && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};

const Filters = [
  <TextInput
    key="firstName"
    source="firstName"
    alwaysOn
    validate={[validator.text, maxLength(50)]}
  />,
  <TextInput
    key="lastName"
    source="lastName"
    alwaysOn
    validate={[validator.text, maxLength(50)]}
  />,
];

const AdminsList = (props: ListProps) => {
  return (
    <Root>
      <StyledList
        disableSyncWithLocation
        filters={Filters}
        sort={{ field: 'role', order: 'DESC' }}
        actions={<Actions />}
        perPage={100}
        pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
        {...props}
      >
        <Datagrid rowClick="show" bulkActionButtons={false}>
          <TextField source="email" />
          <TextField source="role" />
          <TextField source="nickname" />
          <TextField source="firstName" />
          <TextField source="lastName" />
        </Datagrid>
      </StyledList>
    </Root>
  );
};

export default AdminsList;

const StyledList = styled(List)`
  .MuiFormHelperText-root {
    display: block !important;
    position: relative;
  }
`;

const Root = styled.div`
  .MuiToolbar-root > form {
    align-items: flex-start;
  }
`;
