import { FC, useEffect } from 'react';
import { TextInput } from 'react-admin';
import { required, useTranslate } from 'ra-core';
import { useFormContext } from 'react-hook-form';

interface LoginFormFieldsProps {
  errorMessage?: string;
}

export const LoginFormFields: FC<LoginFormFieldsProps> = ({ errorMessage }) => {
  const { setError } = useFormContext();
  const translate = useTranslate();

  useEffect(() => {
    setError('username', { message: '' });
    setError('password', { message: '' });
  }, [errorMessage]);

  return (
    <>
      <TextInput
        autoFocus
        source="username"
        label="Email"
        validate={[required()]}
        fullWidth
      />
      <TextInput
        source="password"
        label={translate('ra.auth.password')}
        type="password"
        autoComplete="current-password"
        validate={required()}
        fullWidth
      />
    </>
  );
};
