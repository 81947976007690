import api from 'api';
import { ColorInput, ImageInput } from 'components';
import { FC, useState } from 'react';
import {
  Edit,
  maxLength,
  minLength,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
} from 'react-admin';
import { validator } from 'utils/validator';

type DirectionEditToolbarProps = {
  hasColorChanges?: boolean;
} & ToolbarProps;

const DirectionEditToolbar: FC<DirectionEditToolbarProps> = (props) => (
  <Toolbar {...props}>
    <SaveButton alwaysEnable={props.hasColorChanges} />
  </Toolbar>
);

const DirectionEdit = () => {
  /**
   * this state is necessary because of the SimpleForm's state doesn't work correctly with
   * ChromePicker (form state doesn't change when new color is picked)
   * */
  const [colorPickInputChanged, setColorPickInputChanged] = useState(false);

  const handleUploadImage = async (data: { filename: string }) =>
    await api.AdminPanelDirections.adminDirectionsControllerUploadImage(data);

  return (
    <Edit redirect="show">
      <SimpleForm
        toolbar={
          <DirectionEditToolbar hasColorChanges={colorPickInputChanged} />
        }
      >
        <ImageInput
          uploadImage={handleUploadImage}
          source="logoUrl"
          label="Logo url"
        />
        <ImageInput
          uploadImage={handleUploadImage}
          source="darkLogoUrl"
          label="Dark logo url"
        />
        <ImageInput
          uploadImage={handleUploadImage}
          source="faviconUrl"
          label="favicon url"
        />
        <ImageInput
          uploadImage={handleUploadImage}
          source="checkoutLogoUrl"
          label="Checkout logo url"
        />
        <ImageInput
          uploadImage={handleUploadImage}
          source="darkCheckoutLogoUrl"
          label="Dark checkout logo url"
        />
        <ColorInput
          source="mainColorHex"
          validate={required()}
          onColorPickInputChanged={setColorPickInputChanged}
        />
        <ColorInput
          source="oldColorHex"
          validate={required()}
          onColorPickInputChanged={setColorPickInputChanged}
        />
        <TextInput
          validate={[maxLength(500), minLength(2), validator.textRequired()]}
          source="name"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.urlRequired()}
          source="siteUrl"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.urlRequired()}
          source="landingUrl"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.textRequired()}
          source="termsOfServiceUrl"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.idRequired()}
          source="signUpTemplateId"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.idRequired()}
          source="restorePasswordTemplateId"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.idRequired()}
          source="changeEmailTemplateId"
          fullWidth
          multiline
          isRequired
        />

        <TextInput
          validate={validator.idRequired()}
          source="subscriptionPaymentFailedTemplateId"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.idRequired()}
          source="courseUnreleasedTemplateId"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.idRequired()}
          source="freeAccessGrantedTemplateId"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.idRequired()}
          source="inviteTemplateId"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.idRequired()}
          source="paymentSuccessfulTemplateId"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={validator.textRequired()}
          source="linkTitle"
          fullWidth
          isRequired
          multiline
        />
        <TextInput
          validate={validator.text}
          source="linkDescription"
          fullWidth
          multiline
        />
      </SimpleForm>
    </Edit>
  );
};

export default DirectionEdit;
