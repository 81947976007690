import { NestedResourcesContext } from 'context/NestedResources';
import { useContext } from 'react';
import {
  Datagrid,
  Identifier,
  List,
  ListProps,
  RaRecord,
  TextField,
  TextInput,
} from 'react-admin';
import { Resources } from 'types';

const Filters = [<TextInput key="name" source="name" alwaysOn />];

const ManualsList = (props: ListProps) => {
  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId } = urlVariables || {};

  const selectChapter = (
    id: Identifier,
    _resource: string,
    record: RaRecord,
  ) => {
    return record.type;
  };

  return (
    <List
      disableSyncWithLocation
      title="Manuals"
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={Filters}
      bulkActionButtons={false}
      resource={`${Resources.MANUALS}/${courseId}/manuals`}
      {...props}
    >
      <Datagrid rowClick={selectChapter} bulkActionButtons={false}>
        <TextField source="type" />
        <TextField source="id" />
      </Datagrid>
    </List>
  );
};

export default ManualsList;
