import { Switch } from '@mui/material';
import { FC, useEffect } from 'react';
import {
  AutocompleteInput,
  NumberInput,
  ReferenceInput,
  required,
  TextInput,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Resources } from 'types';
import { validator } from 'utils/validator';

type PurchaseInputsProps = {
  isActive: boolean;
  isEdit?: boolean;
  setIsActive: (value: boolean) => void;
};

const PurchaseInputs: FC<PurchaseInputsProps> = ({
  isActive,
  isEdit = false,
  setIsActive,
}) => {
  const { id } = useParams();
  const context = useFormContext();
  const isPrices = !!context.getValues()?.prices?.purchase?.amount;

  useEffect(() => {
    if (isPrices && isEdit && !isActive) {
      setIsActive(true);
    }
  }, [isPrices]);

  const enable = () => setIsActive(true);
  const disable = () => setIsActive(false);

  return (
    <>
      <Switch
        checked={isActive}
        defaultValue=""
        onChange={isActive ? disable : enable}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Container>
        <NumberInput
          label="amount"
          source="prices.purchase.amount"
          disabled={!isActive}
          validate={isActive ? [validator.notZero, required()] : undefined}
          fullWidth
        />
        <TextInput
          label="description"
          source="prices.purchase.description"
          disabled={!isActive}
          fullWidth
          multiline
        />
        <ReferenceInput
          label="Coupon"
          source={`prices.purchase.defaultCouponId`}
          queryOptions={{
            meta: {
              courseId: id,
              period: 'lifetime',
            },
          }}
          reference={Resources.SEARCH_COUPONS}
        >
          <AutocompleteInput
            label="Coupon"
            optionText="name"
            alwaysOn={false}
            fullWidth
          />
        </ReferenceInput>
      </Container>
    </>
  );
};

export default PurchaseInputs;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
`;
