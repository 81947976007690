import React, { FC } from 'react';
import {
  CLOSED_DRAWER_WIDTH,
  DashboardMenuItem,
  DRAWER_WIDTH,
  Menu as MenuRA,
  MenuClasses,
  MenuProps,
  ResourceMenuItem,
  useResourceDefinitions,
  useSidebarState,
} from 'react-admin';
import { MenuList } from '@mui/material';
import { styled } from '@mui/material/styles';
import lodashGet from 'lodash/get';
import clsx from 'clsx';
import ArrowBack from '@mui/icons-material/ArrowBack';

const INDEX_WITHOUT_SLASH = 1;
const INDEX_WITHOUT_LAST_RESOURCE_AND_SLASH = -3;

const Menu: FC<MenuProps> = (props) => {
  const resources = useResourceDefinitions();
  const {
    hasDashboard,
    children = [
      hasDashboard ? (
        <DashboardMenuItem key="default-dashboard-menu-item" />
      ) : null,
      ...Object.keys(resources)
        .filter((name) => resources[name].hasList)
        .map((name) => <ResourceMenuItem key={name} name={name} />),
    ],
    className,
    ...rest
  } = props;
  const [open] = useSidebarState();

  const isBackButton = location.pathname.length > 1;

  const goBack = () => {
    const pathname = location.pathname
      .split('/')
      .slice(INDEX_WITHOUT_SLASH, INDEX_WITHOUT_LAST_RESOURCE_AND_SLASH)
      .join('/');

    const href = `${location.origin}/${pathname ? pathname + '/' : ''}`;
    location.replace(href);
  };

  return (
    <Root {...props}>
      <StyledMenuList
        className={clsx(
          {
            [MenuClasses.open]: open,
            [MenuClasses.closed]: !open,
          },
          className,
        )}
        {...rest}
      >
        {isBackButton && (
          <DashboardMenuItem
            leftIcon={<ArrowBack />}
            primaryText="Back"
            onClick={goBack}
          />
        )}
        {children}
      </StyledMenuList>
    </Root>
  );
};

export default Menu;

const PREFIX = 'RaMenu';

const Root = styled(MenuRA)`
  padding: 0;
  margin: 0;
`;

const StyledMenuList = styled(MenuList, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  marginTop: '0.5em',
  marginBottom: '1em',
  [theme.breakpoints.only('xs')]: {
    marginTop: 0,
  },
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  [`&.${MenuClasses.open}`]: {
    width: lodashGet(theme, 'sidebar.width', DRAWER_WIDTH),
  },

  [`&.${MenuClasses.closed}`]: {
    width: lodashGet(theme, 'sidebar.closedWidth', CLOSED_DRAWER_WIDTH),
  },
}));
