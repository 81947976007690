import { Edit, SimpleForm, TextInput, maxLength } from 'react-admin';

import { validator } from 'utils/validator';

const StudyPlanPhaseEdit = () => {
  return (
    <Edit title="Edit study plan" redirect="show">
      <SimpleForm>
        <TextInput
          validate={[validator.textRequired(), maxLength(500), validator.text]}
          source="name"
          fullWidth
          multiline
        />
      </SimpleForm>
    </Edit>
  );
};

export default StudyPlanPhaseEdit;
