import { FC } from 'react';
import { Button, useRecordContext } from 'react-admin';
import styled from 'styled-components';

type ResetButtonProps = {
  label: string;
  onClick: (courseId: string) => void;
};

const ResetButton: FC<ResetButtonProps> = ({ onClick, label }) => {
  const record = useRecordContext() || {};

  return (
    <Root
      onClick={() => {
        onClick((record.courseId as string) || '');
      }}
      label={label}
    />
  );
};

export default ResetButton;

const Root = styled(Button)`
  white-space: nowrap;
  color: #eb4040 !important;
`;
