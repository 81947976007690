import {
  NumberField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import { ShowActions } from 'components';
import { DeletionType } from 'components/ShowActions/ShowActions';

const TestTopicsShow = () => {
  return (
    <Show title="Domains" actions={<ShowActions type={DeletionType.domain} />}>
      <TabbedShowLayout>
        <Tab label="About domain">
          <TextField
            source="title"
            sx={{
              whiteSpace: 'pre-wrap',
              overflowWrap: 'break-word',
              wordBreak: 'break-word',
              flexWrap: 'wrap',
            }}
          />
          <NumberField source="sortIndex" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default TestTopicsShow;
