import { FC } from 'react';
import styled, { css } from 'styled-components';
import AudioFile from '@mui/icons-material/AudioFile';
import DocumentFile from '@mui/icons-material/InsertDriveFile';
import CircularProgress from '@mui/material/CircularProgress';

export enum FileEnum {
  IMAGE,
  AUDIO,
  DOCUMENT,
}

type FileProps = {
  type?: FileEnum;
  isLoading?: boolean;
  value: string;
  size?: number;
};

const File: FC<FileProps> = ({ type, size = 150, isLoading, value }) => {
  if (isLoading) {
    return (
      <LoaderContainer $size={size}>
        <CircularProgress />
      </LoaderContainer>
    );
  }

  switch (type) {
    case FileEnum.IMAGE: {
      return <Image $size={size} src={value} />;
    }
    case FileEnum.AUDIO: {
      return (
        <Container>
          <AudioFile sx={{ fontSize: size }} />
        </Container>
      );
    }
    case FileEnum.DOCUMENT: {
      return (
        <Container>
          <DocumentFile sx={{ fontSize: size }} />
        </Container>
      );
    }
    default: {
      return null;
    }
  }
};

export default File;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Image = styled.img<{ $size: number }>`
  ${({ $size }) => css`
    width: ${$size}px;
    height: ${$size}px;
  `}

  overflow: hidden;
  object-fit: cover;
`;

const LoaderContainer = styled.div<{ $size: number }>`
  ${({ $size }) => css`
    width: ${$size}px;
    height: ${$size}px;
  `}

  display: flex;
  justify-content: center;
  align-items: center;
`;
