import { Admin } from 'api/generated';

export const useAdminActions = () => {
  const onTransform = async (values: Admin) => {
    const { directions, role, ...omittedValues } = values;
    const isSuperAdmin = role === 'superadmin';

    const normalizedDirections = directions?.map((item) => {
      return typeof item === 'string' ? { id: item } : { id: item.id };
    });

    const normalizedValues = {
      ...omittedValues,
      role,
      directions: isSuperAdmin ? [] : normalizedDirections,
    };

    return normalizedValues;
  };
  return {
    onTransform,
  };
};
