import { ReportAction } from 'components';
import { ResourceFields } from './components';

import { Show, TextField, SimpleShowLayout, DateField } from 'react-admin';
import { Resources } from 'types';

const ReportShow = () => {
  return (
    <Show actions={<ReportAction />} title="Report">
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="customerId" />
        <ResourceFields
          resource={Resources.TEST_QUESTIONS}
          showButtonLabel="Show question"
          label={false}
          source="questionId"
        />
        <ResourceFields
          resource={Resources.MNEMONIC_CARDS}
          showButtonLabel="Show mnemonic"
          label={false}
          source="mnemonicCardId"
        />
        <ResourceFields
          resource={Resources.FLASHCARDS}
          showButtonLabel="Show flashcard"
          label={false}
          source="flashcardId"
        />
        <TextField source="resource" />
        <TextField source="content" />
        <DateField source="createdAt" showTime={true} />
        <DateField source="resolvedAt" showTime={true} />
      </SimpleShowLayout>
    </Show>
  );
};

export default ReportShow;
