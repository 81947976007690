import {
  SaveButton,
  SimpleForm,
  required,
  TextInput,
  Toolbar,
  Edit,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { useNotify } from 'react-admin';
import Typography from '@mui/material/Typography';

import { validator } from 'utils/validator';
import api from 'api';
import styled from 'styled-components';

const RestorePasswordToolbar = () => (
  <Toolbar>
    <SaveButton label="Restore password" onSubmit={(e) => e.preventDefault()} />
  </Toolbar>
);

const CustomerResetPassword = () => {
  const { id } = useParams();
  const notify = useNotify();

  const validatePasswordConfirmation = (
    value: string,
    allValues: { [key: string]: string },
  ) => {
    if (value !== allValues.resetPassword) {
      return 'Passwords do not match';
    }
  };

  const restoreCustomerPassword = async (values: any) => {
    const passwordData = {
      password: values.resetPassword,
    };

    if (id) {
      try {
        await api.AdminPanelCustomers.updateOneBaseAdminCustomersControllerCustomer(
          id,
          passwordData,
        );
        notify('Password successfully updated');
      } catch (err) {
        notify('Error updating password');
      }
    }
  };

  return (
    <Edit>
      <SimpleForm
        toolbar={<RestorePasswordToolbar />}
        onSubmit={restoreCustomerPassword}
      >
        <TextInput
          label="Password"
          source="resetPassword"
          validate={[required(), validator.passwordByCustomer]}
          fullWidth
          multiline
        />
        <TextInput
          label="Confirm password"
          source="confirmPassword"
          validate={[required(), validatePasswordConfirmation]}
          fullWidth
          multiline
        />
        <Root>
          <Label>WARNING</Label>
          <Typography
            component="span"
            variant="body2"
            sx={{ color: 'red', fontSize: '16px' }}
          >
            When changing the password, the user will not be notified. You need
            to inform the user about the password change.
          </Typography>
        </Root>
      </SimpleForm>
    </Edit>
  );
};

export default CustomerResetPassword;

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.p`
  font-size: 16px !important;
  margin-bottom: 0.2em;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 700;
  font-size: 100rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgb(255, 99, 71);
`;
