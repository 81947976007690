import { NestedResourcesContext } from 'context/NestedResources';
import { useOnFetchError, useQuery } from 'hooks';
import { useContext, useState } from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  Create,
  maxLength,
  minLength,
  Pagination,
  ReferenceArrayInput,
  ReferenceInput,
  SimpleForm,
  useCreate,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Resources } from 'types';

const QuizTestsCreate = () => {
  const [testChapterId, setTestChapterId] = useState('');
  const [testDomainId, setTestDomainId] = useState('');
  const query = useQuery();
  const chapterId = query.get('chapterId');

  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};

  const navigate = useNavigate();
  const { onError } = useOnFetchError();
  const [create] = useCreate(Resources.TESTS_QUIZ, undefined, {
    onError,
    onSuccess() {
      navigate(-1);
    },
  });

  const filter = {
    ...(testChapterId && { chapterId: testChapterId }),
    ...(testDomainId && { domainId: testDomainId }),
  };

  const createTest = async (data: FieldValues) => {
    await create(Resources.TESTS_QUIZ, {
      data: { ...data, guideChapterId: chapterId },
    });
  };

  const handleSubmit = async (values: FieldValues) => {
    await createTest(values);
  };

  return (
    <Create title="Quiz test">
      <SimpleForm onSubmit={handleSubmit}>
        <ReferenceInput
          label="Domain"
          source="domainId"
          reference={Resources.TEST_TOPICS}
          sort={{ field: 'title', order: 'ASC' }}
          filter={{ courseId: courseId }}
        >
          <AutocompleteInput
            label="Domain"
            filterToQuery={(searchText) => ({ subject: searchText })}
            optionText="title"
            fullWidth
            onChange={(value) => setTestDomainId(value)}
          />
        </ReferenceInput>

        <ReferenceInput
          label="Test chapter"
          source="chapterId"
          reference={Resources.TEST_CHAPTERS}
          sort={{ field: 'title', order: 'ASC' }}
          filter={{ courseId: courseId }}
        >
          <AutocompleteInput
            label="Test chapter"
            filterToQuery={(searchText) => ({ subject: searchText })}
            optionText="title"
            fullWidth
            onChange={(value) => setTestChapterId(value)}
          />
        </ReferenceInput>

        <ReferenceArrayInput
          label="Questions"
          source="questionsIds"
          reference={Resources.TEST_QUESTIONS}
          queryOptions={{
            meta: {
              join: ['domain', 'chapter'],
              limit: 100,
            },
          }}
          perPage={1000}
          pagination={
            <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]} />
          }
          filter={filter}
        >
          <AutocompleteArrayInput
            filterToQuery={(searchText) => ({ subject: searchText })}
            validate={[maxLength(10), minLength(10)]}
            optionText="subject"
            fullWidth
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default QuizTestsCreate;
