import React, { useContext } from 'react';
import { ImageInput } from 'components';
import {
  Edit,
  maxLength,
  minLength,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';
import api from 'api';
import { validator } from 'utils/validator';
import { NestedResourcesContext } from 'context/NestedResources';

const FlashcardsEdit = () => {
  const { urlVariables } = useContext(NestedResourcesContext);
  const { flashcardsPack: packId = '' } = urlVariables || {};

  return (
    <Edit title="Flashcard edit" redirect="show">
      <SimpleForm>
        <ImageInput
          label="Question image"
          source="questionImageUrl"
          uploadImage={({ filename }) =>
            api.AdminPanelFlashcards.adminFlashcardsControllerUploadImage({
              filename,
              packId,
            })
          }
        />
        <TextInput
          validate={[minLength(2), maxLength(500), required(), validator.text]}
          source="question"
          fullWidth
          multiline
        />
        <ImageInput
          label="Answer image"
          source="answerImageUrl"
          uploadImage={({ filename }) =>
            api.AdminPanelFlashcards.adminFlashcardsControllerUploadImage({
              filename,
              packId,
            })
          }
        />
        <TextInput
          validate={[minLength(2), maxLength(500), required(), validator.text]}
          source="answer"
          fullWidth
          multiline
        />
      </SimpleForm>
    </Edit>
  );
};

export default FlashcardsEdit;
