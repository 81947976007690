import { NestedResourcesContext } from 'context/NestedResources';
import { useContext } from 'react';
import {
  Datagrid,
  DateField,
  List,
  ListProps,
  Pagination,
  TextField,
} from 'react-admin';

const TestsList = (props: ListProps) => {
  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};

  return (
    <List
      disableSyncWithLocation
      bulkActionButtons={false}
      filter={{ courseId }}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
      {...props}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="durationSec" />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default TestsList;
