import { AdminRoleEnum } from 'api/generated';

import { ChangeEvent, useState } from 'react';
import {
  AutocompleteArrayInput,
  Create,
  maxLength,
  minLength,
  RaRecord,
  ReferenceArrayInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { Resources } from 'types';
import { setStringArray } from 'utils/setStringArray';
import { validator } from 'utils/validator';
import { useAdminActions } from '../hooks';

const AdminsCreate = () => {
  const [isAdminSelected, setIsAdminSelected] = useState(false);

  const { onTransform } = useAdminActions();
  const onChangeRole = (params: ChangeEvent<HTMLInputElement> | RaRecord) => {
    setIsAdminSelected(params.target.value === 'admin');
  };

  return (
    <Create redirect="list" transform={onTransform}>
      <SimpleForm>
        <TextInput
          source="email"
          validate={[maxLength(155), validator.email, required()]}
          fullWidth
          multiline
        />
        <TextInput
          source="password"
          validate={[
            minLength(8),
            maxLength(20),
            required(),
            validator.password,
          ]}
          fullWidth
          multiline
        />
        <TextInput
          source="nickname"
          validate={[maxLength(100), required(), validator.text]}
          fullWidth
          multiline
        />
        <TextInput
          source="firstName"
          validate={[maxLength(100), required(), validator.fullName]}
          fullWidth
          multiline
        />
        <TextInput
          source="lastName"
          validate={[maxLength(100), required(), validator.fullName]}
          fullWidth
          multiline
        />
        <SelectInput
          source="role"
          choices={[
            { id: AdminRoleEnum.Admin, name: 'Admin' },
            { id: AdminRoleEnum.Superadmin, name: 'Superadmin' },
          ]}
          onChange={onChangeRole}
          validate={required()}
          fullWidth
        />

        {isAdminSelected && (
          <ReferenceArrayInput
            label="Attached careers"
            source="directions"
            reference={Resources.DIRECTIONS}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <AutocompleteArrayInput
              label="Careers"
              filterToQuery={(searchText) => ({ subject: searchText })}
              optionText="name"
              format={setStringArray}
              fullWidth
            />
          </ReferenceArrayInput>
        )}
      </SimpleForm>
    </Create>
  );
};

export default AdminsCreate;
