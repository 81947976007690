import api from 'api';
import {
  AdminUpdateManualDto,
  ManualResponseDto,
  ManualResponseDtoTypeEnum,
} from 'api/generated';
import { NestedResourcesContext } from 'context/NestedResources';
import { useContext, useEffect, useState } from 'react';
import { Loading, SimpleForm, TextInput } from 'react-admin';
import { useParams } from 'react-router-dom';

import { ManualContent, PaymentFields } from './components';
import { useAppNotify } from 'hooks';

const ManualsEdit = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [manual, setManual] = useState<ManualResponseDto | null>(null);

  const { errorNotify, successNotify } = useAppNotify();
  const { id } = useParams();
  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};

  const manualType = id as ManualResponseDtoTypeEnum | undefined;
  const content = manual?.content as AdminUpdateManualDto;

  const getManual = async (
    courseId: string,
    manualType: ManualResponseDtoTypeEnum,
  ) => {
    try {
      setIsLoading(true);
      const { data } = await api.AdminPanelCourses.manualsControllerGetOne(
        courseId,
        manualType,
      );
      setManual(data);
    } catch (err) {
      errorNotify(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values: AdminUpdateManualDto) => {
    if (manualType) {
      try {
        await api.AdminPanelCourses.manualsControllerUpdateOne(courseId, {
          ...values,
          type: manualType,
        });

        successNotify('Manual updated successfully');
      } catch (err) {
        errorNotify(err);
      }
    }
  };

  useEffect(() => {
    if (manualType) {
      getManual(courseId, manualType);
    } else {
      setIsLoading(false);
    }
  }, [courseId, manualType]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <SimpleForm
      defaultValues={content}
      onSubmit={(e) => handleSubmit(e as AdminUpdateManualDto)}
    >
      {manualType === 'payment-discounts' ? (
        <PaymentFields />
      ) : manualType === 'flashcard-descriptions' ? (
        <>
          <TextInput source="title" fullWidth multiline />
          <TextInput source="yes" fullWidth multiline />
          <TextInput source="no" fullWidth multiline />
          <TextInput source="kinda" fullWidth multiline />
        </>
      ) : (
        <>
          <TextInput source="title" fullWidth multiline />
          <ManualContent courseId={courseId} content={content} />
          {manualType !== 'instruction-manual' &&
            manualType !== 'how-progress-is-calculated' && (
              <TextInput source="buttonText" fullWidth multiline />
            )}
        </>
      )}
    </SimpleForm>
  );
};

export default ManualsEdit;
