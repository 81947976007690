import { useQuery } from 'hooks';
import { FC, forwardRef, useMemo } from 'react';
import { Datagrid, DatagridProps, useListContext } from 'react-admin';
import styled from 'styled-components';

const PhasesDataGrid: FC<DatagridProps> = forwardRef((props, ref) => {
  const { children, ...rest } = props;
  const { data: rawData } = useListContext();
  const query = useQuery();

  const page = Number(query.get('page'));
  const perPage = Number(query.get('perPage'));
  const initialIndex = (page - 1) * perPage;

  const data = useMemo(
    () =>
      rawData?.map((dt, index) => ({
        ...dt,
        number: initialIndex + index + 1,
      })) || [],
    [rawData, initialIndex],
  );

  return (
    <StyledDatagrid header={<></>} {...rest} ref={ref} data={data}>
      {children}
    </StyledDatagrid>
  );
});

export default PhasesDataGrid;

const StyledDatagrid = styled(Datagrid)`
  > button,
  > span {
    display: none !important;
  }
`;
