import { StyledImageField } from 'components';
import { NestedResourcesContext } from 'context/NestedResources';
import { useContext } from 'react';
import {
  Datagrid,
  List,
  ListProps,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin';

const Filters = [<TextInput key="title" source="title" alwaysOn />];

const MnemonicCardsList = (props: ListProps) => {
  const { urlVariables } = useContext(NestedResourcesContext);
  const { mnemonicCardsPack: packId = '' } = urlVariables || {};

  return (
    <List
      disableSyncWithLocation
      filters={Filters}
      bulkActionButtons={false}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filter={{ packId }}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
      {...props}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <StyledImageField sortable={false} source="imageUrl" />
        <TextField source="title" />
        <TextField source="answersCount" />
      </Datagrid>
    </List>
  );
};

export default MnemonicCardsList;
