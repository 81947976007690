import {
  BulkDeleteWithConfirmButton,
  BulkDeleteWithConfirmButtonProps,
} from 'react-admin';
import { FC } from 'react';

const BulkDeleteActionsButtons: FC<BulkDeleteWithConfirmButtonProps> = ({
  label = 'DELETE',
  confirmTitle = 'DELETE?',
  confirmContent = 'Are you sure want to delete?',
  ...props
}) => {
  return (
    <BulkDeleteWithConfirmButton
      label={label}
      confirmTitle={confirmTitle}
      confirmContent={confirmContent}
      {...props}
    />
  );
};

export default BulkDeleteActionsButtons;
