import { useAppNotify, useToggle } from 'hooks';
import React, { FC, useContext, useState } from 'react';
import {
  Confirm,
  NumberInput,
  SelectInput,
  ReferenceInput,
  required,
} from 'react-admin';
import styled from 'styled-components';
import { DeleteButton } from 'components';
import { Resources } from 'types';
import { NestedResourcesContext } from 'context/NestedResources';

type ChapterProps = {
  index: number;
  id?: string;
  removeField: (index: number) => void;
};

const Chapter: FC<ChapterProps> = ({ index, removeField }) => {
  const { errorNotify } = useAppNotify();
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, close, open } = useToggle();
  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};

  const deleteTopic = async () => {
    try {
      setIsLoading(true);
      close();

      removeField(index);
    } catch (err) {
      errorNotify(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Root>
      <DeleteButton
        disabled={isLoading}
        text="Delete chapter"
        startIcon={null}
        onClick={open}
      />
      <StyledReference
        filter={{ courseId }}
        source={`chapters[${index}].id`}
        reference={Resources.TEST_CHAPTERS}
      >
        <StyledSelectInput
          optionText="title"
          label="Select chapter"
          validate={required()}
        />
      </StyledReference>
      <StyledNumberInput
        source={`chapters[${index}].amountOfQuestions`}
        label="Number of questions"
        validate={required()}
      />
      <Confirm
        title="Are you sure you want to delete the chapter?"
        content=""
        isOpen={isOpen}
        onClose={close}
        onConfirm={deleteTopic}
      />
    </Root>
  );
};
export default Chapter;

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const StyledNumberInput = styled(NumberInput)`
  width: 100%;
`;

const StyledSelectInput = styled(SelectInput)`
  width: 100%;
`;

const StyledReference = styled(ReferenceInput)`
  width: 100%;
`;
