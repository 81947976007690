import api from 'api';
import CustomButton from 'components/CustomButton';
import { useOnFetchError } from 'hooks';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useRecordContext, useRefresh } from 'react-admin';
import { ErrorResponse } from 'types';

const ToggleResolveSuggestionButton = () => {
  const [isResolved, setIsResolver] = useState<boolean | null>(null);
  const refresh = useRefresh();
  const { onError } = useOnFetchError();
  const suggestion = useRecordContext();

  useEffect(() => {
    if (suggestion) {
      setIsResolver(Boolean(suggestion?.resolvedAt));
    }
  }, [suggestion]);

  const onResolveClick: MouseEventHandler = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();

      await api.AdminPanelSuggestions.adminSuggestionControllerResolve(
        suggestion?.id as string,
      );
      refresh();
    } catch (error) {
      onError(error as ErrorResponse);
    }
  };

  const onUnResolveClick: MouseEventHandler = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();

      await api.AdminPanelSuggestions.adminSuggestionControllerMarkAsUnresolved(
        suggestion?.id as string,
      );
      refresh();
    } catch (error) {
      onError(error as ErrorResponse);
    }
  };

  if (isResolved === null) {
    return null;
  }

  return (
    <CustomButton
      label={isResolved ? 'Unresolve' : 'Resolve'}
      onClick={isResolved ? onUnResolveClick : onResolveClick}
    />
  );
};

export default ToggleResolveSuggestionButton;
