import { useAppNotify, useOnFetchError } from 'hooks';
import React, { FC, MouseEventHandler, SetStateAction } from 'react';
import { useRecordContext, useRefresh } from 'react-admin';
import { ErrorResponse, SortIndexVariant } from 'types';
import ArrowDownIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpIcon from '@mui/icons-material/ArrowUpward';
import { Button } from '@mui/material';
import styled from 'styled-components';
import api from 'api';
import { AdminFlashcardsPackDto } from 'api/generated';

type SortIndexButtonProps = {
  sortIndexVariant: SortIndexVariant;
  flashcardsPack: AdminFlashcardsPackDto[];
  getAllPacks: () => Promise<void>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
};

const FlashcardsSortIndexButton: FC<SortIndexButtonProps> = ({
  sortIndexVariant,
  flashcardsPack,
  getAllPacks,
  isLoading,
  setIsLoading,
}) => {
  const refresh = useRefresh();
  const { onError } = useOnFetchError();
  const { successNotify, errorNotify } = useAppNotify();
  const record = useRecordContext();
  const isIncrease = sortIndexVariant === 'increase';

  const getCard = (before?: boolean) => {
    let currentIndex = -1;

    flashcardsPack?.forEach((card, index) => {
      if (card.id === record?.id) {
        currentIndex = index;
      }
    });

    return before
      ? flashcardsPack[currentIndex - 1]
      : flashcardsPack[currentIndex + 1];
  };

  const onIndexIncrease: MouseEventHandler = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    const card = getCard(false);

    if (card) {
      setIsLoading(true);

      try {
        await api.AdminPanelFlashcards.adminFlashcardsPacksControllerMoveFlashcardsPack(
          String(record.id),
          {
            before: card.id,
          },
        );
        await getAllPacks();
        successNotify(`OrderIndex increased to ${record.order.orderIndex + 1}`);
        refresh();
      } catch (error) {
        onError(error as ErrorResponse);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      errorNotify('OrderIndex сannot be enlarged');
    }
  };

  const onIndexDecrease: MouseEventHandler = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    const card = getCard(true);

    if (record.order.orderIndex === 0) {
      errorNotify('OrderIndex cannot be lower than 0');
      return;
    }

    try {
      setIsLoading(true);

      await api.AdminPanelFlashcards.adminFlashcardsPacksControllerMoveFlashcardsPack(
        String(record.id),
        {
          before: card.id,
        },
      );
      await getAllPacks();
      successNotify(`OrderIndex decreased to ${record.order.orderIndex - 1}`);

      refresh();
    } catch (error) {
      onError(error as ErrorResponse);
    } finally {
      setIsLoading(false);
    }
  };

  if (record.order.orderIndex === null) {
    return null;
  }

  return (
    <StyledButton
      disabled={isLoading}
      variant="text"
      startIcon={isIncrease ? <ArrowDownIcon /> : <ArrowUpIcon />}
      onClick={isIncrease ? onIndexIncrease : onIndexDecrease}
    />
  );
};

export default FlashcardsSortIndexButton;

const StyledButton = styled(Button)`
  width: 40px !important;
  min-width: 40px !important;

  & > span {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
`;
