import { NestedResourcesContext } from 'context/NestedResources';
import { useQuery } from 'hooks';
import { useContext, useState } from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  Edit,
  maxLength,
  minLength,
  Pagination,
  ReferenceArrayInput,
  ReferenceInput,
  SimpleForm,
  TextField,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Resources } from 'types';

const QuizTestsEdit = () => {
  const [testChapterId, setTestChapterId] = useState('');
  const [testDomainId, setTestDomainId] = useState('');
  const navigate = useNavigate();
  const query = useQuery();
  const chapterId = query.get('chapterId');

  const { urlVariables } = useContext(NestedResourcesContext);
  const { course: courseId = '' } = urlVariables || {};

  const filter = {
    ...(testChapterId && { chapterId: testChapterId }),
    ...(testDomainId && { domainId: testDomainId }),
  };

  const transform = (data: FieldValues) => ({
    ...data,
    guideChapterId: chapterId,
    method: 'PATCH',
  });

  const redirect = () => {
    navigate(-1);
    return '';
  };

  return (
    <Edit title="Quiz test" redirect={redirect} transform={transform}>
      <SimpleForm>
        <StyledLabel>Quiz tets id</StyledLabel>
        <TextField label="Quiz id" source="id" />

        <ReferenceInput
          label="Domain"
          source="domainId"
          reference={Resources.TEST_TOPICS}
          sort={{ field: 'title', order: 'ASC' }}
          filter={{ courseId: courseId }}
        >
          <AutocompleteInput
            label="Domain"
            filterToQuery={(searchText) => ({ subject: searchText })}
            optionText="title"
            fullWidth
            onChange={(value) => setTestDomainId(value)}
          />
        </ReferenceInput>

        <ReferenceInput
          label="Test chapter"
          source="chapterId"
          reference={Resources.TEST_CHAPTERS}
          sort={{ field: 'title', order: 'ASC' }}
          filter={{ courseId: courseId }}
        >
          <AutocompleteInput
            label="Test chapter"
            filterToQuery={(searchText) => ({ subject: searchText })}
            optionText="title"
            fullWidth
            onChange={(value) => setTestChapterId(value)}
          />
        </ReferenceInput>

        <ReferenceArrayInput
          label="Questions"
          source="questionsIds"
          reference={Resources.TEST_QUESTIONS}
          queryOptions={{
            meta: {
              join: ['domain', 'chapter'],
            },
          }}
          perPage={1000}
          pagination={
            <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]} />
          }
          filter={filter}
        >
          <AutocompleteArrayInput
            filterToQuery={(searchText) => ({ subject: searchText })}
            validate={[maxLength(10), minLength(10)]}
            optionText="subject"
            fullWidth
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default QuizTestsEdit;

const StyledLabel = styled.label`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
`;
