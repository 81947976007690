import React, {
  FC,
  useEffect,
  useState,
  ReactNode,
  createContext,
} from 'react';
import { UrlVariables } from 'types';
import { parseUrlVariables } from 'utils/textHelpers';

type NestedResourcesContextProviderProps = {
  children: ReactNode;
};

export type NestedResourcesContextType = {
  urlVariables: UrlVariables | null;
};

export const initialState: NestedResourcesContextType = {
  urlVariables: null,
};

export const NestedResourcesContext =
  createContext<NestedResourcesContextType>(initialState);

export const NestedResourcesContextProvider: FC<
  NestedResourcesContextProviderProps
> = ({ children }) => {
  const [urlVariables, setUrlVariables] = useState<UrlVariables | null>(null);

  useEffect(() => {
    const variables = parseUrlVariables(location.pathname);
    setUrlVariables(variables);
  }, [location.pathname]);

  return (
    <NestedResourcesContext.Provider value={{ urlVariables }}>
      {children}
    </NestedResourcesContext.Provider>
  );
};
