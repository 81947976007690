import { AdminRoleEnum } from 'api/generated';
import { ShowActions } from 'components';
import {
  ArrayField,
  Datagrid,
  EmailField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import { StorageKeys, StorageService } from 'services';

const AdminsShow = () => {
  const role = StorageService.getItem(StorageKeys.ROLE);
  const isSuperAdmin = role === AdminRoleEnum.Superadmin;

  return (
    <Show actions={isSuperAdmin && <ShowActions />} title="Admins">
      <TabbedShowLayout>
        <Tab label="About admin">
          <TextField source="id" />
          <EmailField source="email" />
          <TextField source="role" />
          <TextField source="nickname" />
          <TextField source="firstName" />
          <TextField source="lastName" />

          <ArrayField label="Careers" source="directions">
            <Datagrid bulkActionButtons={false}>
              <TextField source="id" />
              <TextField source="name" />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default AdminsShow;
