import { NestedResourcesContext } from 'context/NestedResources';
import { useContext } from 'react';
import {
  Datagrid,
  DateField,
  List,
  ListProps,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin';

const InviteList = (props: ListProps) => {
  const { urlVariables } = useContext(NestedResourcesContext);

  const { course: courseId = '' } = urlVariables || {};

  const Filters = [<TextInput key="email" source="email" alwaysOn />];

  return (
    <List
      disableSyncWithLocation
      bulkActionButtons={false}
      filter={{ courseId }}
      filters={Filters}
      sort={{ field: 'expireAt', order: 'DESC' }}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
      {...props}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="email" />
        <DateField source="acceptedAt" />
        <DateField source="expireAt" />
      </Datagrid>
    </List>
  );
};

export default InviteList;
