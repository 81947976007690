import {
  AutocompleteInput,
  Datagrid,
  DateField,
  List,
  ListProps,
  Pagination,
  ReferenceInput,
  TextField,
  TextInput,
} from 'react-admin';

import { NestedResourcesContext } from 'context/NestedResources';
import { useContext } from 'react';
import { Resources } from 'types';

const getFilters = (directionId: string) => [
  <TextInput key="name" source="name" alwaysOn />,
  <TextInput key="email" source="email" alwaysOn />,
  <ReferenceInput
    key="customerCourses"
    source="customerCourses.course.id"
    reference={Resources.COURSES}
    sort={{ field: 'name', order: 'ASC' }}
    filter={{ directionId }}
    alwaysOn
  >
    <AutocompleteInput
      filterToQuery={(searchValue) => ({ name: searchValue })}
      optionValue="id"
      optionText="name"
    />
  </ReferenceInput>,
];

const CustomersList = (props: ListProps) => {
  const { urlVariables } = useContext(NestedResourcesContext);
  const { career: directionId = '' } = urlVariables || {};

  return (
    <List
      disableSyncWithLocation
      filter={{ directionId }}
      filters={getFilters(directionId)}
      bulkActionButtons={false}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
      {...props}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="name" />
        <TextField source="email" />
        <DateField source="lastSessionAt" />
      </Datagrid>
    </List>
  );
};

export default CustomersList;
